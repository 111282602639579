import { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "../assets/css/custom.css";
import {
  JSPrintManager,
  InstalledPrinter,
  ClientPrintJob,
  PrintFilePDF,
  WSStatus,
  FileSourceType,
  DefaultPrinter,
} from "jsprintmanager";
import { toast } from "react-toastify";
import config from "../config";

interface PrintPdfInterFace {
  shippingLabel: any;
  labelType: string;
  printNow: boolean;
}

const PrintShippingLabelPdf: React.FC<PrintPdfInterFace> = ({
  shippingLabel,
  labelType,
  printNow,
}) => {
  const printErrorRef = useRef<any>(true);

  useEffect(() => {
    initJspm();
  }, []);

  const jspmWSStatus = () => {
    if (JSPrintManager.websocket_status == WSStatus.Open) return true;
    else if (JSPrintManager.websocket_status == WSStatus.Closed) {
      if (printErrorRef.current) {
        toast.error("JSPrintManager (JSPM) is not installed or not running!");
        printErrorRef.current = false;
      }
      return false;
    } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
      toast.error("JSPM has blocked this website!");
      return false;
    }
  };

  const initJspm = () => {
    if (config.apiUrl.includes("https://gateway-qa.nf-automation.com")) {
      JSPrintManager.license_url =
        "https://gateway-qa.nf-automation.com/fill/jspm"; //FIXME: THIS IS FOR QA ENV ONLY
    }
    if (config.apiUrl.includes("https://gateway.nf-automation.com")) {
      JSPrintManager.license_url =
        "https://gateway.nf-automation.com/fill/jspm"; // THIS IS FOR PROD ENV
    }
    // JSPrintManager.auto_reconnect = true;
    JSPrintManager.start();
    JSPrintManager.WS!.onStatusChanged = function () {
      if (jspmWSStatus()) {
        JSPrintManager.getPrintersInfo(0, "", 0).then((data: any) => {
          console.log("getPrintersInfo", data);
          let found = false,
            connected = false;
          for (let i = 0; i < data.length; i++) {
            if (data[i]?.default === true) {
              found = true;
              if (data[i].connected === true) {
                connected = true;
                break;
              }
            }
          }
          if (!found) {
            toast.error("Default printer is not set");
            return;
          }
          if (!connected) {
            toast.error("Printer is not connected");
          }
        });
      }
    };
  };

  useEffect(() => {
    if (shippingLabel) {
      if (printNow) {
        printShippingLabel();
      }
    }
  }, [shippingLabel, printNow]);

  const printShippingLabel = () => {
    let cpj = new ClientPrintJob();
    cpj.clientPrinter = new DefaultPrinter();
    let docTopLabel: any = new jsPDF("p", "pt", "a4");
    docTopLabel.html(document.querySelector(".pdfShippingLabel2"), {
      callback: async function (doc: any) {
        // await doc.autoPrint();
        doc.setProperties({ title: shippingLabel?.tracking_id });
        // await doc.output("dataurlnewwindow");
        let base = await doc.output("blob");
        var myImageFile = new PrintFilePDF(
          base,
          FileSourceType.BLOB,
          "shippinglabel.pdf",
          1
        );
        cpj.files.push(myImageFile);
        cpj.sendToClient();
      },
    });
    docTopLabel = undefined;
  };

  return (
    <div style={{ display: "none" }}>
      <div className="pdfShippingLabel2">
        <div style={{ height: "96px" }}>
          <div className="barcodeImageContainer" style={{width: "100px", height: "50%", margin: "10px auto"}}>
            <img
              className="barcodeImage"
              src={shippingLabel?.packout_barcode}
              alt="packout barcode"
            />
          </div>
          <div style={{ width: "100%", textAlign: "right", paddingRight: "5px" }}>
            Total: {shippingLabel?.total_bagout}{" "}
          </div>
        </div>
        <div style={{height: "576px", paddingTop: "10px"}}>
          <img
            style={{ maxWidth: "100%", height: "100%" }}
            src={shippingLabel?.image_label}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintShippingLabelPdf;
