import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { ViewSvg2 } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import { toast } from "react-toastify";
import moment from "moment";
import qs from "qs";
import { Clear } from "@mui/icons-material";

const { getWorkGroupHistory } = WorkGroupSelectionActions;

const WorkGroupHistory = () => {
  const workgroupsHistory = useSelector(
    (state: any) => state?.workGroupSelection?.workgroupsHistory
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const clearSearchHandler = () => {
    setSearchText("");
    setLoading(true);
    setPage(1);
    const payload = {
      search: undefined,
      page: 1,
      limit: 15,
    };
    dispatch(getWorkGroupHistory(qs.stringify(payload)));
  };

  const handleSearch = () => {
    setPage(1);
    setLoading(true);
    getWorkGroupHistoryHandler();
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    getWorkGroupHistoryHandler();
  }, [page]);

  useEffect(() => {
    if (workgroupsHistory) {
      setLoading(false);
      if (!workgroupsHistory?.success) {
        toast.error(workgroupsHistory?.message);
      }
    }
  }, [workgroupsHistory, dispatch]);

  const getWorkGroupHistoryHandler = () => {
    setLoading(true);
    const payload = {
      search: searchText.trim() !== "" ? searchText.trim() : undefined,
      page: page,
      limit: 15,
    };
    dispatch(getWorkGroupHistory(qs.stringify(payload)));
  };

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={loading} />
      <div className="page-wrap">
        <div className="breadcrumb-block">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/fill_process/work_group_selection">
                  Fill Process
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/fill_process/work_group_selection">
                  Work Group Selection
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Work Group History
              </li>
            </ol>
          </nav>
        </div>
        <div className="wgs-wrap">
          <div className="filter-wrap mr-b10 mr-t10">
            <div className="row align-items-center">
              <div className="col-sm">
                <div className="filter-inputs">
                  <input
                    type="text"
                    placeholder="Search by using the Work Group Name"
                    onChange={(event) => setSearchText(event.target.value)}
                    onKeyPress={(event) => {
                      if (event.key == "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  {searchText.trim().length ? (
                    <Clear
                      style={{ cursor: "pointer" }}
                      onClick={clearSearchHandler}
                    />
                  ) : null}

                  <button
                    type="button"
                    className="search-btn"
                    onClick={handleSearch}
                  >
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.30929 7.8174L11.5206 9.83183L10.79 10.4975L8.57925 8.4826C7.75666 9.08346 6.7335 9.41027 5.67923 9.40891C3.11429 9.40891 1.03259 7.51208 1.03259 5.17492C1.03259 2.83776 3.11429 0.940933 5.67923 0.940933C8.24417 0.940933 10.3259 2.83776 10.3259 5.17492C10.3274 6.13556 9.96871 7.06786 9.30929 7.8174ZM8.2736 7.46833C8.92884 6.85435 9.29476 6.03133 9.29328 5.17492C9.29328 3.35525 7.67574 1.88182 5.67923 1.88182C3.68221 1.88182 2.06518 3.35525 2.06518 5.17492C2.06518 6.99412 3.68221 8.46802 5.67923 8.46802C6.61911 8.46937 7.52234 8.13594 8.19616 7.53889L8.2736 7.46833Z"
                        fill="white"
                      ></path>
                    </svg>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="wgs-data">
            <div className="cus-table max-h-table">
              <TableContainer>
                <Table className="table table-bordered table-center">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="p-0">
                        Work Group Name
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        Total Quantity
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        Remaining To Fill
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        Remaining To PV2
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        Remaining To Pack
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        Work Group Status
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        Work Group Completed Date
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workgroupsHistory &&
                    workgroupsHistory?.success &&
                    workgroupsHistory?.data?.workgroupsData?.length ? (
                      workgroupsHistory?.data?.workgroupsData?.map(
                        (item: any, index: number) => {
                          return (
                            <TableRow key={`${index}`}>
                              <TableCell align="center" className="">
                                {item?.wg_name}
                              </TableCell>
                              <TableCell align="center" className="text-end">
                                {item?.total_quantity}
                              </TableCell>
                              <TableCell align="center" className="text-end">
                                {item?.wg_fill_remain}
                              </TableCell>
                              <TableCell align="center" className="text-end">
                                {item?.wg_pv2_remain}
                              </TableCell>
                              <TableCell align="center" className="text-end">
                                {item?.wg_pack_remain}
                              </TableCell>
                              <TableCell align="center" className="">
                                {item?.wg_status}
                              </TableCell>
                              <TableCell align="center" className="">
                                {item?.wg_initiated_at &&
                                  moment(
                                    item?.wg_initiated_at?.slice(0, -1)
                                  ).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell align="center">
                                <div className="action-btn">
                                  <button
                                    type="button"
                                    className="border-black-btn pd-l10 pd-r10"
                                    onClick={() =>
                                      navigate(
                                        "/fill_process/work_group_selection/view_detail",
                                        {
                                          state: {
                                            workGroupId: item.wg_id,
                                            isHistory: true,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <img src={ViewSvg2} alt="view" />
                                    View
                                  </button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={13} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <nav
              aria-label="Page navigation example"
              className="cus-pagination mr-t10"
            >
              <ul className="pagination justify-content-center">
                <Pagination
                  count={workgroupsHistory?.data?.total_pages}
                  shape="rounded"
                  boundaryCount={1}
                  color="primary"
                  renderItem={(item) => (
                    <PaginationItem className="pagination-item" {...item} />
                  )}
                  onChange={handlePageChange}
                  page={page}
                />
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkGroupHistory;
