import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import PackoutActions from "../../../redux/fill_process/packout_station/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import { toast } from "react-toastify";

const { getPackoutRxs, clearPackoutRxs } = PackoutActions;

const PackoutStationViewRxDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const packoutRxs = useSelector(
		(state: any) => state?.packoutStation?.packoutRxs
	);

	const [spinnerFlag, setSpinnerFlag] = useState<boolean>(false);

	useEffect(() => {
		if (id) {
			dispatch(getPackoutRxs(id));
			setSpinnerFlag(true);
		}
	}, [dispatch, id]);

	useEffect(() => {
		if (packoutRxs) {
			setSpinnerFlag(false);
			if (!packoutRxs?.success) {
				toast.error(packoutRxs?.message);
			}
		}
	}, [packoutRxs]);

	useEffect(() => {
		return () => {
			dispatch(clearPackoutRxs());
		};
	}, [dispatch]);

	return (
		<section className="body-routing">
			<SimpleBackdropSpinner open={spinnerFlag} />
			<div className="page-wrap">
				<div className="breadcrumb-block">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/fill_process/packout_station">Fill Process</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="/fill_process/packout_station">Packout Station</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								View RX Details
							</li>
						</ol>
					</nav>
				</div>
				<div className="detailswgs-wrap">
					<div className="blue-card-panel mat-data-card">
						<div className="b-card-body pd10">
							<div className="blue-card-panel">
								<div className="b-card-head">
									<h5>RX Details for Packout</h5>
								</div>
								<div className="b-card-body pd10">
									<TableContainer>
										<Table className="table small-table cus-gray-table table-center table-bordered mr0">
											<TableHead>
												<TableRow>
													<TableCell align="center">Seq</TableCell>
													<TableCell align="center">Ship To Name</TableCell>
													<TableCell align="center">Service Type</TableCell>
													<TableCell align="center">St</TableCell>
													<TableCell align="center">Fill by Date</TableCell>
													<TableCell align="center">
														Fill Product Description
													</TableCell>
													<TableCell align="center">Last Name</TableCell>
													<TableCell align="center">DOB</TableCell>
													<TableCell align="center">Group ID</TableCell>
													<TableCell align="center">Packout ID</TableCell>
													<TableCell align="center">Tracking ID</TableCell>
													<TableCell align="center">Status</TableCell>
													<TableCell align="center">Details</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{packoutRxs?.data?.response?.length ? (
													packoutRxs?.data?.response?.map((item: any) => {
														return (
															<TableRow>
																<TableCell align="center">
																	{item?.seq}
																</TableCell>
																<TableCell align="center">
																	{item?.ship_to_name}
																</TableCell>
																<TableCell align="center">
																	{item?.service_type}
																</TableCell>
																<TableCell align="center">
																	{item?.state}
																</TableCell>
																<TableCell align="center">
																	{moment(item?.fill_by_date?.slice(0, -1)).format(
																		"MM/DD/YYYY"
																	)}
																</TableCell>
																<TableCell align="center">
																	{item?.fill_product_description}
																</TableCell>
																<TableCell align="center">
																	{item?.last_name}
																</TableCell>
																<TableCell align="center">
																	{moment(item?.dob?.slice(0, -1)).format("MM/DD/YYYY")}
																</TableCell>
																<TableCell align="center">
																	{item?.group_id}
																</TableCell>
																<TableCell align="center">
																	{item?.packout_id}
																</TableCell>
																<TableCell align="center">
																	{item?.tracking_id ? item?.tracking_id : "-"}
																</TableCell>
																<TableCell align="center">
																	{item?.status}
																</TableCell>
																<TableCell align="center">
																	<div className="action-btn">
																		<button
																			type="button"
																			className="border-black-btn pd-l10 pd-r10"
																			onClick={() =>
																				navigate(
																					`/fill_process/packout_station/view_rx_details/view_details/${item?.rx_id}`
																				)
																			}
																		>
																			<svg
																				width="11"
																				height="10"
																				viewBox="0 0 11 10"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<g clip-path="url(#clip0_2_382)">
																					<path
																						d="M5.23839 1.25C7.48505 1.25 9.35422 2.86667 9.7463 5C9.35464 7.13333 7.48505 8.75 5.23839 8.75C2.99172 8.75 1.12255 7.13333 0.730469 5C1.12214 2.86667 2.99172 1.25 5.23839 1.25ZM5.23839 7.91667C6.08816 7.91648 6.91271 7.62784 7.57708 7.09799C8.24144 6.56813 8.70626 5.82845 8.89547 5C8.70557 4.17221 8.24044 3.43334 7.57615 2.90418C6.91185 2.37502 6.08768 2.08689 5.23839 2.08689C4.38909 2.08689 3.56492 2.37502 2.90063 2.90418C2.23633 3.43334 1.7712 4.17221 1.5813 5C1.77051 5.82845 2.23533 6.56813 2.8997 7.09799C3.56406 7.62784 4.38861 7.91648 5.23839 7.91667ZM5.23839 6.875C4.7411 6.875 4.26419 6.67746 3.91256 6.32582C3.56093 5.97419 3.36339 5.49728 3.36339 5C3.36339 4.50272 3.56093 4.02581 3.91256 3.67417C4.26419 3.32254 4.7411 3.125 5.23839 3.125C5.73567 3.125 6.21258 3.32254 6.56421 3.67417C6.91584 4.02581 7.11338 4.50272 7.11338 5C7.11338 5.49728 6.91584 5.97419 6.56421 6.32582C6.21258 6.67746 5.73567 6.875 5.23839 6.875ZM5.23839 6.04167C5.51465 6.04167 5.7796 5.93192 5.97495 5.73657C6.1703 5.54122 6.28005 5.27627 6.28005 5C6.28005 4.72373 6.1703 4.45878 5.97495 4.26343C5.7796 4.06808 5.51465 3.95833 5.23839 3.95833C4.96212 3.95833 4.69717 4.06808 4.50182 4.26343C4.30647 4.45878 4.19672 4.72373 4.19672 5C4.19672 5.27627 4.30647 5.54122 4.50182 5.73657C4.69717 5.93192 4.96212 6.04167 5.23839 6.04167Z"
																						fill="#080C27"
																					/>
																				</g>
																				<defs>
																					<clipPath id="clip0_2_382">
																						<rect
																							width="10"
																							height="10"
																							fill="white"
																							transform="translate(0.238281)"
																						/>
																					</clipPath>
																				</defs>
																			</svg>
																			View
																		</button>
																	</div>
																</TableCell>
															</TableRow>
														);
													})
												) : (
													<TableRow>
														<TableCell colSpan={13} align="center">No records found</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PackoutStationViewRxDetails;
