import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const rxProductImageSlice = createSlice({
	name: "rxProductImage",
	initialState: initState,
	reducers: {
		setRxProductList: (state, action) => {
			return { ...state, productList: action.payload };
		},
		setRxProductDetail: (state, action) => {
			return { ...state, productDetail: action.payload };
		},
		setDeleteProductResponse: (state, action) => {
			return { ...state, deleteProductResponse: action.payload };
		},
		setDeleteProductImageResponse: (state, action) => {
			return { ...state, deleteProductImageResponse: action.payload };
		},
		setAddProductResponse: (state, action) => {
			return { ...state, addProductResponse: action.payload };
		},
		setInitiateTestResponse: (state, action) => {
			return { ...state, initiateTestResponse: action.payload };
		},
		clearInitiateTestResponse: (state) => {
			return { ...state, initiateTestResponse: undefined };
		},
		clearDeleteProductResponse: (state) => {
			return { ...state, deleteProductResponse: undefined };
		},
		clearDeleteProductImageResponse: (state) => {
			return { ...state, deleteProductImageResponse: undefined };
		},
		setUploadProductImageResponse: (state, action) => {
			return { ...state, uploadProductImageRespone: action.payload };
		},
		clearUploadProductImageResponse: (state) => {
			return { ...state, uploadProductImageRespone: undefined };
		},
		clearAddProductResponse: (state) => {
			return { ...state, addProductResponse: undefined };
		},
		setTestNames: (state, action) => {
			return { ...state, testNames: action.payload };
		},
		setTestImageList: (state, action) => {
			return { ...state, testImageList: action.payload };
		},
    setModelRetrainResponse: (state, action) => {
      return {...state, modelTrainingResponse: action.payload}
    },
    setLastRetrainDetails: (state, action) => {
      return {...state, modelLastRetrained: action.payload}
    },
    clearModelRetrainResponse:(state) => {
      return {...state, modelTrainingResponse: undefined}
    }
	},
});

export const {
	setRxProductList,
	setRxProductDetail,
	setDeleteProductResponse,
	setDeleteProductImageResponse,
	setUploadProductImageResponse,
	setAddProductResponse,
	clearDeleteProductImageResponse,
	clearDeleteProductResponse,
	clearUploadProductImageResponse,
	clearAddProductResponse,
	setInitiateTestResponse,
	clearInitiateTestResponse,
	setTestNames,
	setTestImageList,
  setModelRetrainResponse,
  setLastRetrainDetails,
  clearModelRetrainResponse
} = rxProductImageSlice.actions;

export default rxProductImageSlice.reducer;
