import { RPH_ASSIGNMENT } from "../constants/action";
import { clearRphAssignmentResponse } from "./reducer";

const rphAssignmentActions = {
	assignRph: (payload: string) => ({
		type: RPH_ASSIGNMENT.ASSIGN_RPH,
    payload
	}),
	clearAssignment: () => clearRphAssignmentResponse(),
};

export default rphAssignmentActions;
