import { all } from "redux-saga/effects";
import rxProductImageSaga from "./rx_product_image/saga";
import userWorkStationRefSaga from "./user_work_station_ref/saga";
import authSaga from "./auth/saga";
import workGroupSelectionSaga from "./fill_process/work_group_selection/saga";
import FillStationSaga from "./fill_process/fill_station/saga";
import Pv2StationSaga from "./fill_process/pv2_station/saga";
import PackoutStationSaga from "./fill_process/packout_station/saga";
import RphAssignmentSaga from "./rph_assignment/saga";
import ErrorCorrectionSaga from "./fill_process/error_correction/saga";

export default function* rootSaga() {
  yield all([
    rxProductImageSaga(),
    userWorkStationRefSaga(),
    authSaga(),
    workGroupSelectionSaga(),
    FillStationSaga(),
    Pv2StationSaga(),
    PackoutStationSaga(),
    RphAssignmentSaga(),
    ErrorCorrectionSaga(),
  ]);
}
