import { FILL_PROCESS } from "../../constants/action";
import {
  clearSetAssignFillStationToWorkgroup,
  clearSetRxDetailsViewDetails,
  clearSetPrintWorkgroupsMaterial,
  clearSetWorkgroupsData,
  clearSetReprintRxLabels,
} from "./reducer";

const rxPrductImageActions = {
  getWorkStationStatusList: () => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_FILL_STATION_STATUS,
  }),
  getWorkGroupList: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_LIST,
    payload,
  }),
  getWorkGroupHistory: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_HISTORY,
    payload,
  }),
  getRxDetailsViewDetails: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_RX_DETAILS_VIEW_DETAILS,
    payload,
  }),
  getRxDetails: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_RX_DETAILS,
    payload,
  }),
  assignFillStationToWorkgroup: (payload: any) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.ASSIGN_FILL_STATION_TO_WORK_GROUP,
    payload,
  }),
  getWorkGroupData: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_DATA,
    payload,
  }),
  getWorkGroupMaterial: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_MATERIAL,
    payload,
  }),
  printWorkgroupMaterial: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.PRINT_WORK_GROUP_MATERIAL,
    payload,
  }),
  getRemainingWorkGroups: () => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.GET_REMAINING_WORK_GROUPS,
  }),
  reprintRxLabels: (payload: string) => ({
    type: FILL_PROCESS.WORK_GROUP_SELECTION.REPRINT_RX_LABELS,
    payload,
  }),
  clearSetAssignFillStationToWorkgroup: () =>
    clearSetAssignFillStationToWorkgroup(),
  clearSetRxDetailsViewDetails: () => clearSetRxDetailsViewDetails(),
  clearSetPrintWorkgroupsMaterial: () => clearSetPrintWorkgroupsMaterial(),
  clearSetWorkgroupsData: () => clearSetWorkgroupsData(),
  clearSetReprintRxLabels: () => clearSetReprintRxLabels(),
};

export default rxPrductImageActions;
