import { all, takeLatest, put } from "redux-saga/effects";
import { FILL_PROCESS } from "../../constants/action";
import { SagaInterface, YieldInterface } from "../../constants/interface";
import {
  setPackoutMaterialData,
  setPackoutRxsData,
  setPackoutRxsDetails,
  setPackoutWorkgroupData,
  setShippingLabelData,
  setUpdatePackoutStatus,
} from "./reducer";
import {
  getPackoutMaterialData_api,
  getPackoutRxs_api,
  getPackoutWorkgroupData_api,
  printShippingLabel_api,
  rxDetailsViewDetails_api,
  updatePackoutStatus_api,
} from "../../../services";

function* getPackoutStationData_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let packoutScanData: YieldInterface = yield getPackoutMaterialData_api(
      payload
    );

    yield put(
      setPackoutMaterialData({
        success: true,
        ...packoutScanData,
      })
    );
  } catch (error: any) {
    yield put(
      setPackoutMaterialData({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getShippingLabel_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let shippingLabel: YieldInterface = yield printShippingLabel_api(payload);

    yield put(
      setShippingLabelData({
        success: true,
        ...shippingLabel,
      })
    );
  } catch (error: any) {
    yield put(
      setShippingLabelData({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getPackoutRxs_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let packoutRxs: YieldInterface = yield getPackoutRxs_api(payload);

    yield put(
      setPackoutRxsData({
        success: true,
        ...packoutRxs,
      })
    );
  } catch (error: any) {
    yield put(
      setPackoutRxsData({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getPackoutRxsDetails_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let packoutRxsDetails: YieldInterface = yield rxDetailsViewDetails_api(
      payload
    );

    yield put(
      setPackoutRxsDetails({
        success: true,
        ...packoutRxsDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setPackoutRxsDetails({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* updatePackoutStatus_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let updatePackoutStatus: YieldInterface = yield updatePackoutStatus_api(
      payload
    );

    yield put(
      setUpdatePackoutStatus({
        success: true,
        ...updatePackoutStatus,
      })
    );
  } catch (error: any) {
    yield put(
      setUpdatePackoutStatus({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getPackoutWorkgroupData_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let packoutWorkgroupData: YieldInterface = yield getPackoutWorkgroupData_api(
      payload
    );

    yield put(
      setPackoutWorkgroupData({
        success: true,
        ...packoutWorkgroupData,
      })
    );
  } catch (error: any) {
    yield put(
      setPackoutWorkgroupData({
        success: false,
        ...error.response.data,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_MATERIAL_DATA,
      getPackoutStationData_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.PACKOUT_STATION.PRINT_SHIPPING_LABEL,
      getShippingLabel_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_RXS,
      getPackoutRxs_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_RXS_DETAILS,
      getPackoutRxsDetails_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.PACKOUT_STATION.UPDATE_PACKOUT_STATUS,
      updatePackoutStatus_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_WORKGROUP_DATA,
      getPackoutWorkgroupData_saga
    ),
  ]);
}
