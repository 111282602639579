import { Modal } from "@mui/material";
import { useState, useEffect } from "react";
import PrintShippingLabelPdf from "./printShippingLabelPdf";

interface PrintShippingLabelModalInterface {
  open: boolean;
  closeModal: () => void;
  shippingLabel: any;
  setPrintNowProp: () => void;
  printNowProp: boolean;
}

const PrintShippingLabelModal: React.FC<PrintShippingLabelModalInterface> = ({
  open,
  closeModal,
  shippingLabel,
  setPrintNowProp,
  printNowProp,
}) => {
  useEffect(() => {
    if (printNowProp) {
      closeModal();
    }
  }, [printNowProp]);

  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="pfrFormModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal500 modal-dialog-centered modal-dialog-scrollable">
          <PrintShippingLabelPdf
            shippingLabel={shippingLabel}
            labelType="top"
            printNow={printNowProp}
          />
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Print Shipping Label
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body print-data-body">
              <div className="s-list-data">
                <ul>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>Pharmacy Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>{shippingLabel?.pharmacy_name}</h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>Ship to Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>{`${shippingLabel?.name?.first_name || ""} ${
                        shippingLabel?.name?.last_name || ""
                      }`}</h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>Ship to Address</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {`${shippingLabel?.address?.address_1 || ""} ${
                          shippingLabel?.address?.address_2 || ""
                        } ${shippingLabel?.address?.city || ""} ${
                          shippingLabel?.name?.state || ""
                        } ${shippingLabel?.address?.zip || ""}`}
                      </h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>Ship to Phone Number</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>{shippingLabel?.phone_number}</h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>Packout Tracking ID</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>{shippingLabel?.tracking_id}</h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>Priority</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>{shippingLabel?.priority}</h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>FedEx Code</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>{shippingLabel?.fedex_code}</h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-sm-6">
                      <label>Total Bagout</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>{shippingLabel?.total_bagout}</h5>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-6 col-sm-6">
                      <label>Courier's Barcode</label>
                    </div>
                    <div className="col-6 col-sm-6">
                      <div className="barcode-img">
                        <img
                          src={shippingLabel?.courier_barcode}
                          alt="courier barcode"
                          title=""
                        />
                      </div>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-6 col-sm-6">
                      <label>Packout's Barcode</label>
                    </div>
                    <div className="col-6 col-sm-6">
                      <div className="barcode-img">
                        <img
                          src={shippingLabel?.packout_barcode}
                          alt="Packout Barcode"
                          title=""
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-end mr-t30">
                <button
                  type="button"
                  className="blue-btn pd-l10 pd-r10"
                  onClick={() => {
                    setPrintNowProp();
                  }}
                >
                  Print Shipping Label
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PrintShippingLabelModal;
