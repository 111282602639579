import { all, takeLatest, put } from "redux-saga/effects";
import { FILL_PROCESS } from "../../constants/action";
import { SagaInterface, YieldInterface } from "../../constants/interface";
import { setAddOtherDetails, setInitErrorCorrectionData, setManuallyAssociateResponse, setOtherDetails, setUpdateOtherDetails } from "./reducer";
import { addOtherDetails_api, getOtherDetails_api, initErrorCorrection_api, updateOtherDetails_api, manuallyAssociate_api } from "../../../services";

function* initErrorCorrection_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let response: YieldInterface = yield initErrorCorrection_api(payload);

    yield put(
      setInitErrorCorrectionData({
        success: true,
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      setInitErrorCorrectionData({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* manuallyAssociate_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let response: YieldInterface = yield manuallyAssociate_api(payload);

    yield put(
      setManuallyAssociateResponse({
        success: true,
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      setManuallyAssociateResponse({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getOtherDetails_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let otherDetails: YieldInterface = yield getOtherDetails_api(payload);

    yield put(
      setOtherDetails({
        success: true,
        ...otherDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setOtherDetails({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* updateOtherDetails_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let updatedDetails: YieldInterface = yield updateOtherDetails_api(payload);

    yield put(
      setUpdateOtherDetails({
        success: true,
        ...updatedDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setUpdateOtherDetails({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* addOtherDetails_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let addedDetails: YieldInterface = yield addOtherDetails_api(payload);

    yield put(
      setAddOtherDetails({
        success: true,
        ...addedDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setAddOtherDetails({
        success: false,
        ...error.response.data,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      FILL_PROCESS.ERROR_CORRECTION.INIT_ERROR_CORRECTION,
      initErrorCorrection_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.ERROR_CORRECTION.MANUALLY_ASSOCIATE,
      manuallyAssociate_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.ERROR_CORRECTION.GET_PRODUCT_LIST,
      getOtherDetails_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.ERROR_CORRECTION.UPDATE_OTHER_DETAILS,
      updateOtherDetails_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.ERROR_CORRECTION.ADD_OTHER_DETAILS,
      addOtherDetails_saga
    ),
  ]);
}
