import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const pv2StationSlice = createSlice({
  name: "pv2Station",
  initialState: initState,
  reducers: {
    setFailReasons: (state, action) => {
      return { ...state, failReasons: action.payload };
    },
    setPv2StationStatus: (state, action) => {
      return {...state, pv2StationStatus: action.payload}
    },
    setInitialPv2Verification: (state, action) => {
      return {...state, pv2Data: action.payload}
    },
    setOtherMaterialsDetails: (state, action) => {
      return {...state, remainingMaterials: action.payload}
    },
    setUpdatePv2VerificationResponse: (state, action) => {
      return {...state, pv2VerificationUpdateResponse: action.payload}
    },
    setUpdateMaterialDetailsResponse: (state, action) => {
      return {...state, updateMaterialDetailResponse: action.payload}
    },
    clearPv2Data: (state) => {
      return {...state, pv2Data: undefined}
    },
    clearUpdateResponse: (state) => {
      return {...state, pv2VerificationUpdateResponse: undefined}
    },
    clearUpdateMaterialDetailResponse: (state) => {
      return {...state, updateMaterialDetailResponse: undefined}
    },
    clearPv2StationStatus: (state) => {
      return {...state, pv2StationStatus: undefined}
    },
    clearOtherMaterialsDetails: (state) => {
      return {...state, remainingMaterials: undefined}
    },
  },
});

export const {
  setFailReasons,
  setInitialPv2Verification,
  setUpdatePv2VerificationResponse,
  clearPv2Data,
  clearUpdateResponse,
  setUpdateMaterialDetailsResponse,
  clearUpdateMaterialDetailResponse,
  setOtherMaterialsDetails,
  setPv2StationStatus,
  clearPv2StationStatus,
  clearOtherMaterialsDetails
} = pv2StationSlice.actions;

export default pv2StationSlice.reducer;
