import authReducer from "./auth/reducer";
import rxProductImageReducer from "./rx_product_image/reducer";
import userWorkStationRefReducer from "./user_work_station_ref/reducer";
import workGroupSelectionReducer from "./fill_process/work_group_selection/reducer";
import fillStationReducer from "./fill_process/fill_station/reducer";
import pv2StationReducer from "./fill_process/pv2_station/reducer";
import packoutStationReducer from "./fill_process/packout_station/reducer";
import rphAssignmentReducer from "./rph_assignment/reducer";
import errorCorrectionReducer from "./fill_process/error_correction/reducer";

const reducers = {
  auth: authReducer,
  rxProductImage: rxProductImageReducer,
  userWorkStationRef: userWorkStationRefReducer,
  workGroupSelection: workGroupSelectionReducer,
  fillStation: fillStationReducer,
  pv2Station: pv2StationReducer,
  packoutStation: packoutStationReducer,
  rphAssignment: rphAssignmentReducer,
  errorCorrection: errorCorrectionReducer,
};

export default reducers;
