import { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import moment from "moment";
import * as html2pdf from "html2pdf.js";
import "../assets/css/custom.css";
import {
  JSPrintManager,
  InstalledPrinter,
  ClientPrintJob,
  PrintFilePDF,
  WSStatus,
  FileSourceType,
  DefaultPrinter,
} from "jsprintmanager";
import { toast } from "react-toastify";
import config from "../config";
import {PHONE_YCANTH, PHONE_DEFAULT, isProductYcanth} from '../common/helperFunctions';

interface PrintPdfInterFace {
  printRxLabelsResponse: any;
  labelType: string;
  printNow: boolean;
}

const PrintPdf: React.FC<PrintPdfInterFace> = ({
  printRxLabelsResponse,
  labelType,
  printNow,
}) => {
  const printErrorRef = useRef<any>(true);

  const jspmWSStatus = () => {
    if (JSPrintManager.websocket_status == WSStatus.Open) return true;
    else if (JSPrintManager.websocket_status == WSStatus.Closed) {
      if (printErrorRef.current) {
        toast.error("JSPrintManager (JSPM) is not installed or not running!");
        printErrorRef.current = false;
      }
      return false;
    } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
      toast.error("JSPM has blocked this website!");
      return false;
    }
  };

  const initJspm = () => {
    if (config.apiUrl.includes("https://gateway-qa.nf-automation.com")) {
      JSPrintManager.license_url =
        "https://gateway-qa.nf-automation.com/fill/jspm"; // THIS IS FOR QA ENV
    }
    if (config.apiUrl.includes("https://gateway.nf-automation.com")) {
      JSPrintManager.license_url =
        "https://gateway.nf-automation.com/fill/jspm"; // THIS IS FOR PROD ENV
    }
    // JSPrintManager.auto_reconnect = true;
    JSPrintManager.start();
    JSPrintManager.WS!.onStatusChanged = function () {
      if (jspmWSStatus()) {
        JSPrintManager.getPrintersInfo(0, "", 0).then((data: any) => {
          console.log("getPrintersInfo", data);
          let found = false,
            connected = false;
          for (let i = 0; i < data.length; i++) {
            if (data[i]?.default === true) {
              found = true;
              if (data[i].connected === true) {
                connected = true;
                break;
              }
            }
          }
          if (!found) {
            toast.error("Default printer is not set");
            return;
          }
          if (!connected) {
            toast.error("Printer is not connected");
          }
        });
      }
    };
  };

  useEffect(() => {
    if (printRxLabelsResponse && printRxLabelsResponse?.success) {
      if (labelType == "both") {
        printTopLabel();
        printPatientLabel();
      }
      if (printNow && printNow == true) {
        if (labelType == "top") {
          printTopLabel();
        } else if (labelType == "patient") {
          printPatientLabel();
        }
      }
    }
  }, [printRxLabelsResponse, printNow]);

  useEffect(() => {
    initJspm();
  }, []);

  const printTopLabel = () => {
    let cpj1 = new ClientPrintJob();
    cpj1.clientPrinter = new DefaultPrinter();
    var element = document.querySelector(".pdfTopLabel2");
    html2pdf(element, {
      margin: 0,
      filename: "toplabel.pdf",
      image: { type: "jpeg", quality: 2 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      return: true,
    })
      .get("pdf")
      .then(function (pdf: any) {
        // pdf.autoPrint();
        // window.open(pdf.output("bloburl"), "_blank");
        let base = pdf.output("blob");
        var myImageFile = new PrintFilePDF(
          base,
          FileSourceType.BLOB,
          "toplabel.pdf",
          1
        );
        myImageFile.printAsGrayscale = true;
        cpj1.files.push(myImageFile);
        cpj1.sendToClient();
      });
  };

  const printPatientLabel = () => {
    let cpj = new ClientPrintJob();
    cpj.clientPrinter = new DefaultPrinter();
    var element2 = document.querySelector(".pdfRxLabel2");
    html2pdf(element2, {
      margin: 0,
      filename: "patientlabel.pdf",
      image: { type: "jpeg", quality: 2 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      return: true,
    })
      .get("pdf")
      .then(function (pdf: any) {
        // pdf.autoPrint();
        // window.open(pdf.output("bloburl"), "_blank");
        let base = pdf.output("blob");
        var myImageFile = new PrintFilePDF(
          base,
          FileSourceType.BLOB,
          "patientlabel.pdf",
          1
        );
        myImageFile.printAsGrayscale = true;
        cpj.files.push(myImageFile);
        cpj.sendToClient();
      });
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <div className="pdfTopLabel2">
          <div>
            {printRxLabelsResponse?.data?.response?.topLabel?.patient_name}
          </div>
          <div>
            {printRxLabelsResponse?.data?.response?.topLabel?.rx_drugname}
          </div>
          <div className="justifySpaceBetween">
            <div>
              {printRxLabelsResponse?.data?.response?.topLabel?.shipto_name}
            </div>
            <div>
              {printRxLabelsResponse?.data?.response?.topLabel?.rx_number}
            </div>
          </div>
          <div className="justifySpaceBetween">
            <div>
              {printRxLabelsResponse?.data?.response?.topLabel?.quantity}
            </div>
            <div>
              {printRxLabelsResponse?.data?.response?.topLabel?.packout_id}
            </div>
          </div>
          <div>
            {printRxLabelsResponse?.data?.response?.topLabel?.pack_details}
          </div>
          <div className="justifyCenter">
            <img
              className="base64QRCodeImg"
              src={
                printRxLabelsResponse?.data?.response?.topLabel?.base64QRCode
              }
            />
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </div>
      <div style={{ display: "none" }}>
        <div className="pdfRxLabel2">
          <div className="boldSans font12">NuFACTOR Inc.</div>
          <div className="regularArial" style={{ fontSize: "11px" }}>
            1601 Old Greensboro Kernersville, NC 27284-6855 / {isProductYcanth(printRxLabelsResponse?.data?.response?.rxLabel?.product_id) ? PHONE_YCANTH : PHONE_DEFAULT}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="pdfRxLabelContainer" style={{ flex: 1 }}>
              <div className="justifySpaceBetween">
                <div className="regularArial font10">
                  RX#{" "}
                  {printRxLabelsResponse?.data?.response?.rxLabel?.rx_number}
                </div>
                <div className="regularArial font10">
                  {printRxLabelsResponse?.data?.response?.rxLabel
                    ?.rx_fill_date &&
                    moment(
                      printRxLabelsResponse?.data?.response?.rxLabel?.rx_fill_date?.slice(
                        0,
                        -1
                      )
                    ).format("MM/DD/YYYY")}
                </div>
              </div>
              <div className="justifySpaceBetween">
                <div className="boldSans font12">
                  {printRxLabelsResponse?.data?.response?.rxLabel?.patient_name}
                </div>
                <div className="regularArial font10">
                  {
                    printRxLabelsResponse?.data?.response?.rxLabel
                      ?.rx_provider_name
                  }
                </div>
              </div>
              <div className="boldSans font12">
                {printRxLabelsResponse?.data?.response?.rxLabel?.rx_drugname}
              </div>
              <div className="boldSans font12">
                {
                  printRxLabelsResponse?.data?.response?.rxLabel
                    ?.rx_use_instructions
                }
              </div>
              <div className="regurlarSans font12">
                Mfg:{" "}
                {
                  printRxLabelsResponse?.data?.response?.rxLabel
                    ?.rx_manufacturer
                }
              </div>
              <div className="justifySpaceBetween">
                <div className="regularArial font10">
                  Refills:{" "}
                  {printRxLabelsResponse?.data?.response?.rxLabel
                    ?.rx_refill_number || 0}
                </div>
                <div className="regularArial font10">
                  Qty:{" "}
                  {printRxLabelsResponse?.data?.response?.rxLabel?.quantity}
                </div>
              </div>
              <div className="regularArial font10">
                {
                  printRxLabelsResponse?.data?.response?.rxLabel
                    ?.rx_productdescription
                }
              </div>
              <div className="regularArial font10">
                Storage:{" "}
                {
                  printRxLabelsResponse?.data?.response?.rxLabel
                    ?.storage_description
                }
              </div>
              <div className="justifySpaceBetween">
                <div className="regularArial font10">
                  Discard after:{" "}
                  {printRxLabelsResponse?.data?.response?.rxLabel
                    ?.discard_after &&
                    moment(
                      printRxLabelsResponse?.data?.response?.rxLabel?.discard_after?.slice(
                        0,
                        -1
                      )
                    ).format("MM/DD/YYYY")}
                </div>
                <div className="regularArial font10">
                  RPh: {printRxLabelsResponse?.data?.response?.rxLabel?.rph}
                </div>
              </div>
            </div>
            {/* <div
                              className="barcodeImageContainerNew"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                className="barcodeImageNew"
                                src={printRxLabelsResponse?.data?.response?.rxLabel?.base64BarCode}
                              />
                            </div> */}
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </div>
    </>
  );
};

export default PrintPdf;
