import { RX_PRODUCT_IMAGE } from "../constants/action";
import {
	clearDeleteProductImageResponse,
	clearDeleteProductResponse,
  clearUploadProductImageResponse,
  clearAddProductResponse,
  clearInitiateTestResponse,
  clearModelRetrainResponse,
} from "./reducer";

const rxPrductImageActions = {
	getProductList: (payload: string) => ({
		type: RX_PRODUCT_IMAGE.GET_PRODUCT_LIST,
		payload,
	}),

	getProductDetail: (payload: string) => ({
		type: RX_PRODUCT_IMAGE.GET_PRODUCT_DETAIL,
		payload,
	}),

  addProduct: (payload: any) => ({
		type: RX_PRODUCT_IMAGE.ADD_PRODUCT,
		payload,
	}),

	uploadProductImage: (payload: any) => ({
		type: RX_PRODUCT_IMAGE.UPLOAD_PRODUCT_IMAGE_REQUEST,
		payload,
	}),

	deleteProduct: (payload: string) => ({
		type: RX_PRODUCT_IMAGE.DELETE_PRODUCT,
		payload,
	}),

	deleteProductImage: (payload: string) => ({
		type: RX_PRODUCT_IMAGE.DELETE_PRODUCT_IMAGE,
		payload,
	}),

  initiateImageTest: (payload: any) => ({
    type: RX_PRODUCT_IMAGE.INITIATE_TEST,
    payload
  }),

  getTestNames: () => ({
    type: RX_PRODUCT_IMAGE.GET_TEST_NAMES
  }),

  getTestImageList: () => ({
    type: RX_PRODUCT_IMAGE.GET_TEST_IMAGE_LIST
  }),

  retrainModel: () => ({
    type: RX_PRODUCT_IMAGE.RETRAIN_AVSV_MODEL
  }),

  getLastRetrainedDetails: () => ({
    type: RX_PRODUCT_IMAGE.GET_LAST_TRAINED_DATE
  }),

	clearDeleteProductResponse: () => clearDeleteProductResponse(),
	clearDeleteProductImageResponse: () => clearDeleteProductImageResponse(),
  clearUploadProductImageResponse: () => clearUploadProductImageResponse(),
  clearAddProductResponse: () => clearAddProductResponse(),
  clearInitiateTestResponse: () => clearInitiateTestResponse(),
  clearModelRetrainResponse: () => clearModelRetrainResponse()
};

export default rxPrductImageActions;
