import api from "../api";
import { getToken } from "./firebase";

export const gateway_login = async (payload: any) => {
  try {
    let response = await api.post(`/user/login`, payload);
    const customToken = response.data.customToken;
    localStorage.setItem("user", JSON.stringify(response.data || {}));
    return await getToken(customToken);
  } catch (error) {
    throw error;
  }
};

export const fill_api_login = async (payload: any) => {
  try {
    let response = await api.post(`/fill/login`, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_ipdata_api = async (payload: any) => {
  try {
    let response = await api.post(`/fill/validate-workstation`, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_workstation_with_ip_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/mst-workstation-details?role=${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/logout?user_id=${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const login_api_fff = async (payload: any) => {
// 	try {
// 		let response = await axios.post(
// 			`https://gateway-dev.nf-automation.com/user/login`,
// 			payload
// 		);
// 		localStorage.setItem("userDetails", JSON.stringify(response.data));
// 		const finalResponse = await axios.post(
// 			`https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyCustomToken?key=${process.env.REACT_APP_GOOGLE_KEY}`,
// 			{ returnSecureToken: true, token: response.data.customToken }
// 		);

// 		return finalResponse.data;
// 	} catch (error) {
// 		console.log(error);
// 		throw error;
// 	}
// };

// //used earlier for WFS login flow (not used anymore)
// export const login_api = async (payload: any) => {
// 	try {
// 		let response = await api.post(`/fill/login`, payload);
// 		return response.data;
// 	} catch (error) {
// 		console.log(error);
// 		throw error;
// 	}
// };

// //used earlier for WFS login flow (not used anymore)
// export const changePassword_api = async (payload: any) => {
// 	try {
// 		let response = await api.post(`/forgot_password`, payload);
// 		return response.data;
// 	} catch (error) {
// 		console.log(error);
// 		throw error;
// 	}
// };
