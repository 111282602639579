import firebase from '../../../lib/firebase'
import config from '../../../config'
import axios from 'axios';

export const getToken = async (customToken: string): Promise<any> => {
    await firebase.auth().signInWithCustomToken(customToken);
    const token = await firebase.auth().currentUser?.getIdToken();
    const refreshToken = firebase.auth().currentUser?.refreshToken
    return { token: token, refreshToken: refreshToken };
  }

  export const refreshToken = async (refreshToken: string): Promise<any> => {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    return await axios.post(`https://securetoken.googleapis.com/v1/token?key=${config.firebaseConfig.apiKey}`, params)
  }