import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import RxProductImageActions from "../../../redux/rx_product_image/actions";

const { getTestNames, getTestImageList, getLastRetrainedDetails } =
  RxProductImageActions;

const TestResult = () => {
  const dispatch = useDispatch();
  const testNames = useSelector(
    (state: any) => state?.rxProductImage?.testNames
  );
  const testImagesList = useSelector(
    (state: any) => state?.rxProductImage?.testImageList
  );
  const lastRetrainedDetails = useSelector(
    (state: any) => state?.rxProductImage?.modelLastRetrained
  );

  const [selectedTestName, setSelectedTestName] = useState("");
  const [selectedImage, setSelectedImage] = useState<any>("");

  const [spinnerFlag, setSpinnerFlag] = useState<boolean>(false);

  useEffect(() => {
    setSpinnerFlag(true);
    dispatch(getTestNames());
    dispatch(getTestImageList());
    dispatch(getLastRetrainedDetails());
  }, [dispatch]);

  useEffect(() => {
    if (testNames?.success) {
      setSpinnerFlag(false);
    }
  }, [testNames]);

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={spinnerFlag} />
      <div className="page-wrap">
        <div className="page-head-title d-flex align-items-center justify-content-between mr-b20">
          <div className="breadcrumb-block">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mr-b0">
                <li className="breadcrumb-item">
                  <Link to="/rx_product_image/config">Rx Product Image</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Test Results
                </li>
              </ol>
            </nav>
          </div>

          <h5>
            <button
              type="button"
              className="trans-btn"
              data-bs-toggle="modal"
              data-bs-target="#sucmsgModal"
              style={{ cursor: "default" }}
            >
              Model Last Trained :{" "}
              {lastRetrainedDetails?.data?.updated_at
                ? moment(
                    lastRetrainedDetails?.data?.updated_at
                  ).tz(moment.tz.guess()).format("MMMM DD, YYYY | hh:mm a z")
                : "NA"}
            </button>
          </h5>
        </div>
      </div>
      <div className="pro-tote-wrap">
        <h3 className="large-title ">Product AVSV Test Results</h3>
        <div className="all-pro-group mr-t20">
          <div className="blue-card-panel">
            <div className="b-card-body1">
              <div className="group-img-card1">
                <form action="" className="row">
                  <div className="col-lg-2 col-sm-3">
                    <div className="form-group cus-group mr-b15">
                      <label htmlFor="" className="inp-lable-title">
                        Test Name
                      </label>
                      <select
                        name=""
                        className="form-control cus-control test-select"
                        id=""
                        onChange={(e) => setSelectedTestName(e.target.value)}
                      >
                        <option value="">Select</option>
                        {testNames?.data?.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item?.test_image_name}>
                              {item?.test_image_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-3">
                    <div className="form-group cus-group mr-b15">
                      <label htmlFor="" className="inp-lable-title">
                        Image Number
                      </label>
                      <select
                        name=""
                        className="form-control cus-control test-select"
                        id=""
                        onChange={(e) => setSelectedImage(e.target.value)}
                      >
                        <option value="">Select</option>
                        {testImagesList?.data
                          ?.filter(
                            (x: any) => x.test_image_name === selectedTestName
                          )[0]
                          ?.image_list?.map((item: any, index: number) => {
                            return (
                              <option key={index} value={JSON.stringify(item)}>
                                Image {index + 1}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {selectedTestName !== "" && selectedImage !== "" ? (
            <div className="blue-card-panel">
              <div className="b-card-head">
                <h5>Tote Preview</h5>
              </div>
              <div className="b-card-body pd20">
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-5">
                    <div className="blue-card-panel">
                      <div className="vision-test-imges">
                        <div className="singal-img-wrap">
                          <div className="col">
                            <div className="m-img text-center">
                              <img
                                src={
                                  JSON.parse(selectedImage)
                                    ?.predicted_image_path
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vision-sys">
                <div className="test-resuls-h">
                  <h5>Vision System Information</h5>
                  <div className="pro-d-card">
                    <div className="static-info inline-info vision-sys-list">
                      <ul>
                        <li>
                          <span>Test Name :</span>
                          <span>{selectedTestName}</span>
                        </li>
                        <li>
                          <span>Test User :</span>
                          <span>{JSON.parse(selectedImage)?.user_name}</span>
                        </li>
                        <li>
                          <span>Size:</span>
                          <span>30 KB</span>
                        </li>
                        <li>
                          <span>Test Date/Time :</span>
                          <span>
                            {JSON.parse(selectedImage)
                              ?.test_image_induct_timestamp
                              ? moment(
                                  JSON.parse(
                                    selectedImage
                                  )?.test_image_induct_timestamp
                                ).tz(moment.tz.guess()).format("MM/DD/YYYY | hh:mm A z")
                              : "NA"}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default TestResult;
