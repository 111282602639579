import { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
// import { ViewSvg2 } from "../../../assets/images";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import { toast } from "react-toastify";

const {
	getRxDetailsViewDetails,
	clearSetRxDetailsViewDetails,
} = WorkGroupSelectionActions;

const ViewRxViewDetails = () => {
	const rxDetailsViewDetails = useSelector(
		(state: any) => state?.workGroupSelection?.rxDetailsViewDetails
	);

	const location: any = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (location && location?.state?.rxNumber) {
			setLoading(true);
			dispatch(getRxDetailsViewDetails(location.state.rxNumber));
		}
	}, [location, dispatch]);

	useEffect(() => {
		if (rxDetailsViewDetails) {
			setLoading(false);
			if (!rxDetailsViewDetails?.success) {
				toast.error(rxDetailsViewDetails?.message);
			}
		}
	}, [rxDetailsViewDetails]);

	useEffect(() => {
		return () => {
			dispatch(clearSetRxDetailsViewDetails());
		};
	}, [dispatch]);

  const handleBackNavigate = () => {
    dispatch(clearSetRxDetailsViewDetails());
    navigate(-1);
  }

	return (
		<section className="body-routing">
			<SimpleBackdropSpinner open={loading} />
			<div className="page-wrap">
				<div className="breadcrumb-block">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/fill_process/work_group_selection">
									Fill Process
								</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="/fill_process/work_group_selection">
									Work Group Selection
								</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="" onClick={() => navigate(-2)}>
									View Detail
								</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="" onClick={handleBackNavigate}>
									View RX Details
								</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								View Details
							</li>
						</ol>
					</nav>
				</div>
				<div className="wgs-view-wrap">
					<div className="static-info-card mr-b20">
						<div className="row">
							<div className="col-sm">
								<div className="static-info">
									<h5>Patient Name</h5>
									<span>
										{
											rxDetailsViewDetails?.data?.response?.RxDetails
												?.patient_name
										}
									</span>
								</div>
							</div>
							<div className="col-sm">
								<div className="static-info">
									<h5>Fill RX ID</h5>
									<span>
										{rxDetailsViewDetails?.data?.response?.RxDetails?.rx_id}
									</span>
								</div>
							</div>
							<div className="col-sm">
								<div className="static-info">
									<h5>Product</h5>
									<span>
										{rxDetailsViewDetails?.data?.response?.RxDetails?.product}
									</span>
								</div>
							</div>
							<div className="col-sm">
								<div className="static-info">
									<h5>Quantity</h5>
									<span>
										{
											rxDetailsViewDetails?.data?.response?.RxDetails
												?.dispense_qty
										}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="blue-card-panel mat-data-card">
						<div className="b-card-body pd10">
							<div className="row">
								<div className="col">
									<div className="blue-card-panel">
										<div className="b-card-head">
											<h5>RX Details in the Work Group</h5>
										</div>
										<div className="b-card-body pd10">
											<div className="table-responsive">
												<TableContainer>
													<Table className="table small-table cus-gray-table table-center table-bordered mr0">
														<TableHead>
															<TableRow>
																<TableCell align="center" className="p-0">
																	Seq
																</TableCell>
																<TableCell align="center" className="p-0">
																	RX STATE ID
																</TableCell>
																<TableCell align="center" className="p-0">
																	RX Fill Description
																</TableCell>
																<TableCell align="center" className="p-0">
																	RX Fill Value
																</TableCell>
																<TableCell align="center" className="p-0">
																	User ID
																</TableCell>
																<TableCell align="center" className="p-0">
																	Date/Timestamp
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{rxDetailsViewDetails?.data?.response
																?.RxTrxDetailsList?.length ? (
																rxDetailsViewDetails?.data?.response?.RxTrxDetailsList?.map(
																	(value: any, index: number) => {
																		return (
																			<TableRow>
																				<TableCell align="center">
																					{index + 1}
																				</TableCell>
																				<TableCell align="center">
																					{value.rx_state_id}
																				</TableCell>
																				<TableCell align="center">
																					{value.state}
																				</TableCell>
																				<TableCell align="center">
																					{value.rx_fill_value}
																				</TableCell>
																				<TableCell align="center">{value.user_name}</TableCell>
																				<TableCell align="center">
																					{value.created_at &&
																					
																						moment(value.created_at).tz(moment.tz.guess()).format(
																							"MM/DD/YYYY hh:mm A z"
																						)}
																				</TableCell>
																			</TableRow>
																		);
																	}
																)
															) : (
																<TableRow>
																	<TableCell align="center" colSpan={7}>
																		No records found
																	</TableCell>
																</TableRow>
															)}
														</TableBody>
													</Table>
												</TableContainer>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ViewRxViewDetails;
