import api from "../api";

export const rphAssignment_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/rph_assignment?${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}