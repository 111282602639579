import { FILL_PROCESS } from "../../constants/action";
import {
  clearPv2Data,
  clearUpdateMaterialDetailResponse,
  clearUpdateResponse,
  clearPv2StationStatus,
  clearOtherMaterialsDetails,
} from "./reducer";

const pv2StationActions = {
  getFailReasons: () => ({
    type: FILL_PROCESS.PV2_STATION.GET_FAIL_REASONS,
  }),
  initPv2Verification: (payload: string) => ({
    type: FILL_PROCESS.PV2_STATION.INITIATE_PV2_VERIFICATION,
    payload,
  }),
  getPv2StationStatus: (payload: string) => ({
    type: FILL_PROCESS.PV2_STATION.GET_PV2_STAION_STATUS,
    payload,
  }),
  updatePv2Verification: (payload: any) => ({
    type: FILL_PROCESS.PV2_STATION.UPDATE_PV2_VERIFICATION,
    payload,
  }),
  getRemainingMaterialDetails: (payload: string) => ({
    type: FILL_PROCESS.PV2_STATION.GET_REMAINING_MATERIAL_DETAILS,
    payload,
  }),
  updateMaterialDetails: (payload: any) => ({
    type: FILL_PROCESS.PV2_STATION.UPDATE_REMAINING_MATERIAL_DETAILS,
    payload,
  }),
  clearPv2Data: () => clearPv2Data(),
  clearVerificationUpdateResponse: () => clearUpdateResponse(),
  clearUpdateMaterialDetailResponse: () => clearUpdateMaterialDetailResponse(),
  clearPv2StationStatus: () => clearPv2StationStatus(),
  clearOtherMaterialsDetails: () => clearOtherMaterialsDetails(),
};

export default pv2StationActions;
