import { Modal } from "@mui/material";
import { useState } from "react";
import PrintPdf from "../components/printPdf";

interface TopLabelModalInterface {
  open: boolean;
  closeModal: () => void;
  openModal: () => void;
  printRxLabelsResponse: any;
}

const FillStationTopLabelModal: React.FC<TopLabelModalInterface> = ({
  open,
  closeModal,
  openModal,
  printRxLabelsResponse,
}) => {
  const [printNow, setPrintNow] = useState(false);

  const handlePrint = async () => {
    await setPrintNow(true);
    closeModal();
    openModal();
  };

  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="printSModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal500 modal-dialog-centered modal-dialog-scrollable">
          <PrintPdf
            printRxLabelsResponse={printRxLabelsResponse}
            labelType="top"
            printNow={printNow}
          />
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Print Top Label
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body print-data-body">
              <div className="s-list-data">
                <ul>
                  <li>
                    <div className="col-sm-6">
                      <label>Patient First & Last Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.topLabel
                            ?.patient_name
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Drug Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.topLabel
                            ?.rx_drugname
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Ship to Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.topLabel
                            ?.shipto_name
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Total RX Quantity</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.topLabel
                            ?.quantity
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Total Bagout</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.topLabel
                            ?.pack_details
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Fill RX ID</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {printRxLabelsResponse?.data?.response?.topLabel?.rx_number}{" "}
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Packout ID</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.topLabel
                            ?.packout_id
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Barcode Label</label>
                    </div>
                    <div className="col-sm-6">
                      <div className="barcode-img">
                        <img
                          src={
                            printRxLabelsResponse?.data?.response?.topLabel
                              ?.base64QRCode
                          }
                          alt=""
                          title=""
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-end mr-t30">
                <button
                  type="button"
                  className="blue-btn pd-l10 pd-r10"
                  data-bs-toggle="modal"
                  data-bs-target="#printPatientModal"
                  data-bs-dismiss="modal"
                  onClick={() => handlePrint()}
                >
                  Print Top Label
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FillStationTopLabelModal;
