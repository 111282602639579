import { all, takeLatest, put } from "redux-saga/effects";
import { deleteWorkStation_api, getWorkStationList_api, updateWorkStation_api } from "../../services";
import { USER_WORK_STATION_REF } from "../constants/action";
import { SagaInterface, YieldInterface } from "../constants/interface";
import { setDeleteWorkstationResponse, setUpdateWorkstationResponse, setWorkStationList } from "./reducer";

function* getWorkStationList_saga(params: SagaInterface) {
	try {
		let { payload } = params;

		let workStationList: YieldInterface = yield getWorkStationList_api(payload);

		yield put(
			setWorkStationList({
				success: true,
				...workStationList,
			})
		);
	} catch (error: any) {
		console.log(error);

		yield put(
			setWorkStationList({
				success: false,
				...error.response.data,
			})
		);
	}
}

function* updateWorkStation_saga(params: SagaInterface) {
	try {
		let { payload } = params;

		let updateResponse: YieldInterface = yield updateWorkStation_api(payload);

		yield put(
			setUpdateWorkstationResponse({
				success: true,
				...updateResponse,
			})
		);
	} catch (error: any) {
		console.log(error);

		yield put(
			setUpdateWorkstationResponse({
				success: false,
				...error.response.data,
			})
		);
	}
}

function* deleteWorkStation_saga(params: SagaInterface) {
	try {
		let { payload } = params;

		let deleteResponse: YieldInterface = yield deleteWorkStation_api(payload);

		yield put(
			setDeleteWorkstationResponse({
				success: true,
				...deleteResponse,
			})
		);
	} catch (error: any) {
		console.log(error);

		yield put(
			setDeleteWorkstationResponse({
				success: false,
				...error.response.data,
			})
		);
	}
}
export default function* rootSaga() {
	yield all([
		takeLatest(
			USER_WORK_STATION_REF.GET_USER_WORK_STATION_LIST,
			getWorkStationList_saga
		),
	]);
  yield all([
		takeLatest(
			USER_WORK_STATION_REF.UPDATE_USER_WORK_STATION_DETAIL,
			updateWorkStation_saga
		),
	]);
  yield all([
		takeLatest(
			USER_WORK_STATION_REF.DELETE_USER_WORK_STATION,
			deleteWorkStation_saga
		),
	]);
}
