import { Modal } from "@mui/material";

interface IdleAlertModalInterFace {
  open: boolean;
  closeModal: () => void;
  handleLogout: () => void;
}

const IdleAlertModal: React.FC<IdleAlertModalInterFace> = ({
  open,
  closeModal,
  handleLogout,
}) => {
  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="printfillModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal552 modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Alert!
              </h5>
            </div>
            <div className="modal-body printf-m-body">
              <h6>
                The session is expiring. You will be logged out due to
                inactivity.
              </h6>
              <div className="print-btn mr-t30 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="border-blue-btn pd-l30 pd-r30"
                  onClick={() => handleLogout()}
                >
                  Logout
                </button>
                <button
                  type="button"
                  className="blue-btn pd-l20 pd-r20 mr-l10"
                  onClick={() => closeModal()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IdleAlertModal;
