import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const rphAssignmentSlice = createSlice({
	name: "rphAssignment",
	initialState: initState,
	reducers: {
		setRphAssignmentResponse: (state, action) => {
			return { ...state, rphAssignmentResponse: action.payload };
		},
		clearRphAssignmentResponse: (state) => {
			return { ...state, rphAssignmentResponse: undefined };
		},
	},
});

export const {
	setRphAssignmentResponse,
	clearRphAssignmentResponse,
} = rphAssignmentSlice.actions;

export default rphAssignmentSlice.reducer;
