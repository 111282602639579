import { useState, useEffect, useCallback } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { DeleteSvg } from "../../../assets/images";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import RxProductImageActions from "../../../redux/rx_product_image/actions";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import DeleteModal from "../../../components/deleteModal";
import moment from "moment";
import { setRxProductDetail } from "../../../redux/rx_product_image/reducer";

const {
	getProductDetail,
	deleteProductImage,
	clearDeleteProductImageResponse,
} = RxProductImageActions;

interface ProductImage {
	product_image_id: number;
	image_path: string;
	created_at: any;
	image_size: number | string;
	product_image_name: string;
}

const ProductImageDetail = () => {
	const productDetail: any = useSelector(
		(state: any) => state?.rxProductImage?.productDetail
	);
	const deleteProductImageResponse = useSelector(
		(state: any) => state?.rxProductImage?.deleteProductImageResponse
	);

	const urlParams = useParams();
  const navigate = useNavigate();
	const dispatch = useDispatch();

	const [selectedImage, setSelectedImage] = useState<ProductImage | null>(null);
	const [deleteImageId, setDeleteImageId] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [deleteModalFlag, setDeleteModalFlag] = useState<boolean>(false);

	const deleteHandler = () => {
		setLoading(true);
		setDeleteModalFlag(false);
		dispatch(deleteProductImage(deleteImageId));
	};

	const getProductDetailHandler = useCallback(() => {
		if (urlParams?.product_id) {
			setLoading(true);
			dispatch(getProductDetail(urlParams.product_id));
		}
	}, [urlParams, dispatch]);

	const checkSelectedImage = (index: number) => {
		return productDetail?.data?.response?.findIndex(
			(x: any) => x.product_image_id === selectedImage?.product_image_id
		) === index
			? "selected-img"
			: "";
	};

	useEffect(() => {
		if (urlParams?.product_id) {
			getProductDetailHandler();
		}
	}, [urlParams, getProductDetailHandler]);

	useEffect(() => {
		if (productDetail) {
			setLoading(false);
			if (!productDetail.success) {
				toast.error(productDetail?.message);
			} else if (productDetail.success) {
        if(productDetail?.data?.response?.length <= 0){
          navigate(-1);
        }
				setSelectedImage(productDetail?.data?.response[0]);
			}
		}
	}, [productDetail, navigate]);

	useEffect(() => {
		if (deleteProductImageResponse) {
			if (deleteProductImageResponse?.success) {
				toast.success(deleteProductImageResponse?.data?.message);
				dispatch(clearDeleteProductImageResponse());
				getProductDetailHandler();
				setLoading(false);
			} else if (!deleteProductImageResponse?.success) {
				toast.error(deleteProductImageResponse?.message);
				dispatch(clearDeleteProductImageResponse());
				getProductDetailHandler();
				setLoading(false);
			}
		}
	}, [deleteProductImageResponse, dispatch, getProductDetailHandler]);

	useEffect(() => {
		return () => {
			dispatch(setRxProductDetail(undefined));
		};
	}, [dispatch]);

	return (
		<section className="body-routing">
			<SimpleBackdropSpinner open={loading} />
			<div className="page-wrap h-100">
				<div className="breadcrumb-block">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/rx_product_image/config">Rx Product Image</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Product Image
							</li>
						</ol>
					</nav>
				</div>
				<div className="inn-blue-title">
					<h3>Product Images</h3>
				</div>
				<div className="pro-img-board mr-t10 flex1">
					<div className="row h-100">
						<div className="col pro-img-list">
							<div className="img-list-table flex1">
								<div className="table-data-scroll cus-scroll">
									<TableContainer>
										<Table className="cus-gray-table">
											<TableHead>
												<TableRow>
													<TableCell className="w-60"></TableCell>
													<TableCell className="text-center">
														Product Image Name
													</TableCell>
													<TableCell className="text-center">
														Product Image ID
													</TableCell>
													<TableCell className="text-center">Size</TableCell>
													<TableCell className="text-center">
														Date & Time Uploaded{" "}
													</TableCell>
													<TableCell className="text-center w-60">
														Actions
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{productDetail?.data?.response?.length ? (
													productDetail?.data?.response?.map(
														(det: any, index: number) => {
															return (
																<TableRow
																	key={`"${det.product_image_id}"`}
																	onClick={() => setSelectedImage(det)}
																	className={checkSelectedImage(index)}
                                  sx={{cursor: "pointer"}}
																>
																	<TableCell className="l-img-box table-cell-nb">
																		<div className="l-img-box">
																			<img src={det.image_path} alt="" />
																		</div>
																	</TableCell>
																	<TableCell className="table-cell-nb text-center">
																		{det.product_image_name}
																	</TableCell>
																	<TableCell className="table-cell-nb text-center">
																		{det.product_image_id}
																	</TableCell>
																	<TableCell className="table-cell-nb text-center">
																		{det?.image_size
																			? `${Math.ceil(
																					Number(det?.image_size) / 1024
																			  )} Kb`
																			: ""}
																	</TableCell>
																	<TableCell className="table-cell-nb text-center">
																		{det.created_at
																			? moment(det.created_at).tz(moment.tz.guess()).format(
																					"MM/DD/YYYY hh:mm A z"
																			  )
																			: "NA"}
																	</TableCell>
																	<TableCell className="table-cell-nb text-center">
																		<div className="blank-btn text-center">
																			<button
																				type="button"
																				className="blank-btn"
																				data-bs-toggle="modal"
																				data-bs-target="#deletemsgModal"
																				onClick={() => {
																					setDeleteModalFlag(true);
																					setDeleteImageId(
																						det.product_image_id
																					);
																				}}
																			>
																				<img src={DeleteSvg} alt="" title="" />
																			</button>
																		</div>
																	</TableCell>
																</TableRow>
															);
														}
													)
												) : (
													<TableRow>
														<TableCell align="center" colSpan={7}>
															No images found
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</div>
							<h5 className="img-status text-end mr-t10">
								No. of Images : {productDetail?.data?.response?.length}
							</h5>
						</div>
						<div className="col product-pre-wrap">
							<h5 style={{marginBottom: "0px", padding: "20px 0px"}}>Preview</h5>
							<div className="preview-block mr-b20 flex1">
								<div className="preview-inn-img">
									<img src={selectedImage?.image_path} alt="" title="" />
								</div>
							</div>
							<div className="pro-details">
								<h5>Product Image Details</h5>
								<div className="pro-d-card">
									<div className="static-info inline-info">
										<ul>
											<li>
												<span>Product Image ID :</span>
												<span>{selectedImage?.product_image_id}</span>
											</li>
											<li>
												<span>Product Image Name:</span>
												<span>{selectedImage?.product_image_name}</span>
											</li>
											<li>
												<span>Size:</span>
												<span>
													{selectedImage?.image_size
														? `${Math.ceil(
																Number(selectedImage?.image_size) / 1023
														  )} Kb`
														: ""}
												</span>
											</li>
											<li>
												<span>Date & Time Uploaded:</span>
												<span>
													{selectedImage?.created_at
														? moment(selectedImage?.created_at).tz(moment.tz.guess()).format(
																"MM/DD/YYYY hh:mm A z"
														  )
														: "NA"}
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DeleteModal
				open={deleteModalFlag}
				closeModal={() => setDeleteModalFlag(false)}
				title="Delete Rx Product Image"
				message="Are you sure you want to delete this Rx Product Image?"
				confirmDelete={deleteHandler}
			/>
		</section>
	);
};

export default ProductImageDetail;
