import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PrintShippingLabelModal from "../../../components/printShippingLabelModal";
import { useDispatch, useSelector } from "react-redux";
import PackoutStationActions from "../../../redux/fill_process/packout_station/actions";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import BarcodeReader from "react-barcode-reader";
import FillStationActions from "../../../redux/fill_process/fill_station/actions";
import AlertModal from "../../../components/alertModal";
import qs from "qs";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";

const { getFillStationDetailsById, clearFillStationDetailsById } =
  FillStationActions;
const {
  printShippingLabel,
  getPackoutMaterialData,
  clearPackoutMaterialData,
  updatePackoutStatus,
  clearUpdatePackoutStatus,
  clearShippingLabelResponse,
  getPackoutWorkgroupData
} = PackoutStationActions;

const PackoutStation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const printShippingLabelResponse = useSelector(
    (state: any) => state?.packoutStation?.printShippingLabelResponse
  );
  const packoutMaterialData = useSelector(
    (state: any) => state?.packoutStation?.packoutMaterialData
  );
  const fillStationDetailsById = useSelector(
    (state: any) => state?.fillStation?.fillStationDetailsById
  );
  const updatePackoutStatusResponse = useSelector(
    (state: any) => state?.packoutStation?.updatePackoutStatusResponse
  );
  const rxWorkgroupData = useSelector(
    (state: any) => state?.packoutStation?.rxWorkgroupsData
  );
  const [spinnerFlag, setSpinnerFlag] = useState<boolean>(false);
  const [isAutomode, setIsAutoMode] = useState<boolean>(false);
  const [alertModalFlag, setAlertModalFlag] = useState<boolean>(false);
  const [packoutId, setPackoutId] = useState<any>();
  const [rxId, setRxId] = useState();
  const [packoutIdFromShippingLabel, setPackoutIdFromShippingLabel] =
    useState<any>(null);
  const [printShippingDetailsModalFlag, setPrintShippingDetailsModalFlag] =
    useState<boolean>(false);
  const [printNowProp, setPrintNowProp] = useState(false);
  const [shippingLabelAccidentalScanned, setShippingLabelAccidentalScanned] =
    useState("");

  const getShippingLabelHandler = () => {
    if (!rxId) {
      toast.info("Please scan Rx Label first");
      return;
    }
    setPrintNowProp(false);
    setPrintShippingDetailsModalFlag(true);
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user") || "");
    const payload = {
      user_id: userDetails?.id || "",
    };
    dispatch(getFillStationDetailsById(qs.stringify(payload)));
    setSpinnerFlag(true);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearFillStationDetailsById());
      dispatch(clearPackoutMaterialData());
      dispatch(clearUpdatePackoutStatus());
      dispatch(clearShippingLabelResponse());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updatePackoutStatusResponse) {
      if (!updatePackoutStatusResponse?.success) {
        setShippingLabelAccidentalScanned("");
        toast.error(updatePackoutStatusResponse?.message);
      } else if (updatePackoutStatusResponse?.success) {
        checkValidBarcode(packoutId) && dispatch(getPackoutMaterialData(packoutId));
      }
      dispatch(clearUpdatePackoutStatus());
    }
  }, [updatePackoutStatusResponse]);

  useEffect(() => {
    if (fillStationDetailsById) {
      setSpinnerFlag(false);
      if (!fillStationDetailsById?.success) {
        toast.error(fillStationDetailsById?.message);
        dispatch(clearFillStationDetailsById());
      } else if (fillStationDetailsById?.success) {
        if (fillStationDetailsById?.data?.response?.auto) {
          setIsAutoMode(true);
        } else if (!fillStationDetailsById?.data?.response?.auto) {
          setIsAutoMode(false);
        }
      }
    }
  }, [fillStationDetailsById]);

  useEffect(() => {
    if (printShippingLabelResponse) {
      setSpinnerFlag(false);
      dispatch(clearPackoutMaterialData());
      setPackoutIdFromShippingLabel(null);
      if (!printShippingLabelResponse?.success) {
        toast.error(printShippingLabelResponse?.message);
      } else if (printShippingLabelResponse?.success) {
        dispatch(getPackoutWorkgroupData(printShippingLabelResponse?.data?.response?.rx_id));
        if (!isAutomode) {
          dispatch(getPackoutMaterialData(printShippingLabelResponse?.data?.response?.packout_id));
          const userDetails = JSON.parse(localStorage.getItem("user") || "");
          const payload = {
            rx_id: printShippingLabelResponse?.data?.response?.rx_id,
            data: {
              user_id: userDetails?.id || "",
            },
          };
          dispatch(updatePackoutStatus(payload));
        }
      }
    }
  }, [printShippingLabelResponse, dispatch, isAutomode]);

  useEffect(() => {
    if (fillStationDetailsById) {
      setSpinnerFlag(false);
      if (!fillStationDetailsById?.success) {
        toast.error(fillStationDetailsById?.message);
        dispatch(clearFillStationDetailsById());
      } else if (fillStationDetailsById?.success) {
        if (fillStationDetailsById?.data?.response?.auto) {
          setIsAutoMode(true);
        } else if (!fillStationDetailsById?.data?.response?.auto) {
          setIsAutoMode(false);
        }
      }
    }
  }, [fillStationDetailsById]);

  const calculateTotal = () => {
    if (packoutMaterialData?.data?.response) {
      let sum = 0;
      packoutMaterialData?.data?.response?.map((item: any) => {
        sum = sum + item.qty;
        return item;
      });
      return sum;
    }
    return 0;
  };

  const checkValidBarcode = (code: any) => {
    var pattern = new RegExp(/^[0-9]*$/);
    return pattern.test(code);
  };

  const onScanning = async (data: any) => {
    console.log("i am scanned wow", data);

    setAlertModalFlag(false);
    if (!isAutomode) {
      fetchTopLabelDetails(data);
    } else {
      let res = await fetchTopLabelDetails(data);
      const userDetails = JSON.parse(localStorage.getItem("user") || "");
      if (res === 0) {
        if (!packoutId) {
        } else if (data == packoutId) {
          if (shippingLabelAccidentalScanned == data) {
            return;
          }
          setPackoutIdFromShippingLabel(data);
          const payload = {
            rx_id: printShippingLabelResponse?.data?.response?.rx_id,
            data: {
              user_id: userDetails?.id || "",
            },
          };
          setShippingLabelAccidentalScanned(data);
          setTimeout(() => setShippingLabelAccidentalScanned(""), 2000)
          dispatch(updatePackoutStatus(payload));
          // checkValidBarcode(data) && dispatch(getPackoutMaterialData(data));
        } else {
          setAlertModalFlag(true);
        }
      }
    }
  };

  const fetchTopLabelDetails = (data: any) => {
    if (data?.toString()?.includes(";")) {
      setPackoutId(data.toString().split(";")[1]);
      setRxId(data.toString().split(";")[0]);
      dispatch(printShippingLabel(data.toString().split(";")[0]));
      // dispatch(getPackoutWorkgroupData(data.toString().split(";")[0]));
      setSpinnerFlag(true);
      return 1;
    }
    return 0;
  };

  const renderVerification = (id: any) => {
    if (isAutomode) {
      if (packoutIdFromShippingLabel) {
        if (packoutIdFromShippingLabel == id) {
          return "Correct";
        } else {
          return "Incorrect";
        }
      } else {
        return "Pending";
      }
    } else {
      return "";
    }
  };

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={spinnerFlag} />
      <div className="page-wrap">
        <div className="breadcrumb-block mode-row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="fill_process/packout_station">Fill Process</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Packout Station
              </li>
            </ol>
          </nav>
          <div className="mode-block text-center">
            <h4>Packout Station Mode</h4>
            <span>
              {fillStationDetailsById?.success
                ? isAutomode
                  ? "Auto"
                  : "Manual"
                : ""}
            </span>
          </div>
        </div>
        <div className="ps-wrap">
          <div className="ps-code-head mr-b20">
            <h5>
              After scanning Top Label Barcode, please print Shipping Label
              Barcode
              {isAutomode ? (
                <span> and then scan the same Shipping Label Barcode.</span>
              ) : (
                <span>.</span>
              )}
            </h5>
            {/* {printShippingLabelResponse?.data?.response?.bagout_index == 1 ? ( */}
            <button
              type="button"
              className="border-blue-btn pd-l10 pd-r10"
              data-bs-toggle="modal"
              data-bs-target="#printSModal"
              onClick={getShippingLabelHandler}
            >
              Print Shipping Label
            </button>
            {/* ) : null} */}
          </div>
          <div className="static-info-card mr-b20">
              <div className="blue-card-panel">
                <div className="b-card-body pd10">
                  <div className="row">
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Work Group Name</h5>
                        <span>{rxWorkgroupData?.name}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Total</h5>
                        <span>{rxWorkgroupData?.quantity}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Remaining to Fill</h5>
                        <span>{rxWorkgroupData?.fill_remaining}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Remaining to PV2</h5>
                        <span>{rxWorkgroupData?.pv2_remaining}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Remaining to Pack</h5>
                        <span>{rxWorkgroupData?.pack_remaining}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="static-info-card mr-b20">
            <div className="row row-cols-sm-5">
              <div className="col-sm">
                <div className="static-info">
                  <h5>Packout ID</h5>
                  <span>
                    {printShippingLabelResponse?.data?.response?.packout_id}
                  </span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Rx ID</h5>
                  <span>
                  {rxWorkgroupData?.rx_id}
                  </span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Patient Name</h5>
                  <span>
                    {printShippingLabelResponse?.data?.response?.rxPatient || ''}
                  </span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Ship To Name/Ship To Address</h5>
                  <span>
                    {`${printShippingLabelResponse?.data?.response?.name
                      ?.first_name || ""
                      } ${printShippingLabelResponse?.data?.response?.name
                        ?.last_name || ""
                      }`}{" "}
                    <br />
                    {`${printShippingLabelResponse?.data?.response?.address
                      ?.address_1 || ""
                      } ${printShippingLabelResponse?.data?.response?.address
                        ?.address_2 || ""
                      } ${printShippingLabelResponse?.data?.response?.address
                        ?.city || ""
                      } ${printShippingLabelResponse?.data?.response?.name?.state ||
                      ""
                      } ${printShippingLabelResponse?.data?.response?.address
                        ?.zip || ""
                      }`}
                  </span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Bag Details</h5>
                  <span>
                    {printShippingLabelResponse?.data?.response?.bagout_details}
                  </span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Verification</h5>
                  <button
                    type="button"
                    className="trans-btn green-status"
                    data-bs-toggle="modal"
                    data-bs-target="#pVarificationgModal"
                  >
                    {renderVerification(
                      printShippingLabelResponse?.data?.response?.packout_id
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="blue-card-panel mat-data-card">
            <div className="b-card-body pd10">
              <div className="b-head-btn text-end mr-b10">
                <button
                  type="button"
                  className="blue-btn pd-l30 pd-r30"
                  onClick={() =>
                    packoutId
                      ? navigate(
                        "/fill_process/packout_station/view_rx_details/" +
                        packoutId
                      )
                      : toast.warn("Please scan Rx Label first")
                  }
                >
                  View RX Details
                </button>
              </div>
              <div className="row">
                <div className="col">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>Remaining Fill Materials</h5>
                    </div>
                    <div className="b-card-body table-responsive pd10">
                      <TableContainer>
                        <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Seq</TableCell>
                              <TableCell align="center">
                                Pack Product No.
                              </TableCell>
                              <TableCell align="center">
                                Fill Product Description
                              </TableCell>
                              <TableCell align="center">Quantity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {packoutMaterialData?.data?.response?.map(
                              (item: any, index: number) => {
                                return (
                                  <TableRow key={`${index}`}>
                                    <TableCell align="center">
                                      {item?.seq}
                                    </TableCell>
                                    <TableCell align="center">
                                      {item?.packing_item_id}
                                    </TableCell>
                                    <TableCell align="center">
                                      {item?.description}
                                    </TableCell>
                                    <TableCell className="text-end">
                                      {item?.qty}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                          <TableHead>
                            <TableCell colSpan={3} className="text-start">
                              Total
                            </TableCell>
                            <TableCell className="text-end">
                              {calculateTotal()}
                            </TableCell>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="note-list mr-t30">
              <h5>Note: </h5>
              <ul>
                {printShippingLabelResponse?.data?.response
                  ?.bagout_instructions && (
                    <li>
                      {`Bagout Instruction - ${printShippingLabelResponse.data.response.bagout_instructions}`}
                    </li>
                  )}
                {printShippingLabelResponse?.data?.response
                  ?.packout_instructions && (
                    <li>
                      {`Packout Instruction - ${printShippingLabelResponse.data.response.packout_instructions}`}
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PrintShippingLabelModal
        open={printShippingDetailsModalFlag}
        closeModal={() => {
          setPrintShippingDetailsModalFlag(false);
        }}
        shippingLabel={printShippingLabelResponse?.data?.response}
        setPrintNowProp={() => setPrintNowProp(true)}
        printNowProp={printNowProp}
      />
      <BarcodeReader
        onScan={(data: any) => {
          onScanning(data);
        }}
        onError={(error: any) => {
          onScanning(error);
          console.log("barcode error it is", error);
        }}
      />
      <AlertModal
        open={alertModalFlag}
        title="Packout Verification"
        message="The scanned Shipping Label and Top Label are not associated to same PackOut Transaction ID. Please scan associated Shipping Label and Top Label to that PackOut Transaction ID."
        closeModal={() => setAlertModalFlag(false)}
      />
    </section>
  );
};

export default PackoutStation;