import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const workGroupSelectionSlice = createSlice({
  name: "workGroupSelection",
  initialState: initState,
  reducers: {
    setFillStationStatus: (state, action) => {
      return { ...state, fillStationStatus: action.payload };
    },
    setWorkgroupsList: (state, action) => {
      return { ...state, workgroupsList: action.payload };
    },
    setWorkgroupsHistory: (state, action) => {
      return { ...state, workgroupsHistory: action.payload };
    },
    setRxDetailsViewDetails: (state, action) => {
      return { ...state, rxDetailsViewDetails: action.payload };
    },
    setRxDetails: (state, action) => {
      return { ...state, rxDetails: action.payload };
    },
    setAssignFillStationToWorkgroup: (state, action) => {
      return { ...state, assignFillStationToWorkgroupResponse: action.payload };
    },
    setWorkgroupsData: (state, action) => {
      return { ...state, workgroupsData: action.payload };
    },
    setWorkgroupsMaterial: (state, action) => {
      return { ...state, workgroupsMaterial: action.payload };
    },
    setPrintWorkgroupsMaterial: (state, action) => {
      return { ...state, printWorkgroupsMaterialResponse: action.payload };
    },
    setRemainingWorkGroups: (state, action) => {
      return { ...state, remainingWorkGroups: action.payload };
    },
    clearSetAssignFillStationToWorkgroup: (state) => {
      return { ...state, assignFillStationToWorkgroupResponse: undefined };
    },
    clearSetPrintWorkgroupsMaterial: (state) => {
      return { ...state, printWorkgroupsMaterialResponse: undefined };
    },
    clearSetRxDetailsViewDetails: (state) => {
      return { ...state, rxDetailsViewDetails: undefined };
    },
    clearSetWorkgroupsData: (state) => {
      return { ...state, workgroupsData: undefined };
    },
    setReprintRxLabels: (state, action) => {
      return { ...state, reprintRxLabelsResponse: action.payload };
    },
    clearSetReprintRxLabels: (state) => {
      return { ...state, reprintRxLabelsResponse: undefined };
    },
  },
});

export const {
  setFillStationStatus,
  setWorkgroupsList,
  setWorkgroupsHistory,
  setRxDetailsViewDetails,
  setRxDetails,
  setAssignFillStationToWorkgroup,
  setWorkgroupsData,
  setWorkgroupsMaterial,
  setPrintWorkgroupsMaterial,
  clearSetAssignFillStationToWorkgroup,
  clearSetRxDetailsViewDetails,
  clearSetPrintWorkgroupsMaterial,
  clearSetWorkgroupsData,
  setRemainingWorkGroups,
  setReprintRxLabels,
  clearSetReprintRxLabels,
} = workGroupSelectionSlice.actions;

export default workGroupSelectionSlice.reducer;
