import { Modal } from "@mui/material";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "./successModal";
import WorkGroupSelectionActions from "../redux/fill_process/work_group_selection/actions";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../components/loaders";

const {
	getWorkStationStatusList,
	assignFillStationToWorkgroup,
	clearSetAssignFillStationToWorkgroup,
} = WorkGroupSelectionActions;

interface WGSModalInterFace {
	open: boolean;
	closeModal: () => void;
	workGroupId: string;
}

const WorkGroupSelectionModal: React.FC<WGSModalInterFace> = ({
	open,
	closeModal,
	workGroupId,
}) => {
	const fillStationStatus = useSelector(
		(state: any) => state?.workGroupSelection?.fillStationStatus
	);
	const assignFillStationToWorkgroupResponse = useSelector(
		(state: any) =>
			state?.workGroupSelection?.assignFillStationToWorkgroupResponse
	);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [successModalFlag, setSuccessModalFlag] = useState<boolean>(false);
	const [fillStationNumber, setFillStationNumber] = useState<string>("");

	useEffect(() => {
		setLoading(true);
		dispatch(getWorkStationStatusList());
	}, [dispatch]);

	useEffect(() => {
		if (fillStationStatus) {
			setLoading(false);
			if (!fillStationStatus.success) {
				toast.error(fillStationStatus?.message);
			}
		}
	}, [fillStationStatus, dispatch]);

	useEffect(() => {
		if (assignFillStationToWorkgroupResponse) {
			setLoading(false);
			if (!assignFillStationToWorkgroupResponse.success) {
				toast.error(assignFillStationToWorkgroupResponse?.message);
				dispatch(clearSetAssignFillStationToWorkgroup());
			} else if (assignFillStationToWorkgroupResponse.success) {
				dispatch(clearSetAssignFillStationToWorkgroup());
				dispatch(dispatch(getWorkStationStatusList()));
				setSuccessModalFlag(true);
			}
		}
	}, [assignFillStationToWorkgroupResponse, dispatch]);

	const handleOnClickAssign = (fillStationId: string) => {
		const userDetails = JSON.parse(localStorage.getItem("user") || "");
		if (workGroupId?.length && fillStationId?.length) {
			setFillStationNumber(fillStationId);
			dispatch(
				assignFillStationToWorkgroup({
					workstation_id: fillStationId,
					workgroup_id: workGroupId,
					user_id: userDetails?.id || "",
				})
			);
		}
	};

	return (
		<>
			<Modal open={open}>
				<div
					className="modal cus-modal fade show"
					id="worksModal"
					tabIndex={-1}
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal552 modal-dialog-centered modal-dialog-scrollable">
						<SimpleBackdropSpinner open={loading} />
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">
									Work Group Selection
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								>
									<svg
										width="8"
										height="8"
										viewBox="0 0 8 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
											fill="white"
										/>
									</svg>
								</button>
							</div>
							<div className="modal-body printf-m-body text-center">
								<div className="blue-card-panel">
									<div className="b-card-head">
										<h5>Fill Station Status</h5>
									</div>
									<div className="b-card-body pd10">
										<div className="fs-table-data table-responsive cus-scroll">
											<TableContainer>
												<Table className="table cus-gray-table table-center table-bordered">
													<TableHead>
														<TableRow>
															<TableCell align="center">Station No.</TableCell>
															<TableCell align="center">Station User</TableCell>
															<TableCell align="center">
																Availability Status
															</TableCell>
															<TableCell align="center">Action</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{fillStationStatus &&
															fillStationStatus?.data?.response?.map(
																(item: any, index: number) => {
																	return (
																		<TableRow key={`"${index}`}>
																			<TableCell align="center">
																				{item.id}
																			</TableCell>
																			<TableCell align="center">
																				{item?.user}
																			</TableCell>
																			<TableCell align="center">
																				<span
																					className={
																						item?.status === "Occupied"
																							? "red-text"
																							: "green-text"
																					}
																				>
																					{item?.status}
																				</span>
																			</TableCell>
																			<TableCell align="center">
																				<div className="active-btn">
																					<button
																						disabled={
																							item?.status === "Occupied"
																						}
																						type="button"
																						className={
																							item?.status === "Occupied"
																								? "border-black-btn-disabled pd-l20 pd-r20"
																								: "border-black-btn pd-l20 pd-r20"
																						}
																						onClick={() =>
																							handleOnClickAssign(item.id)
																						}
																					>
																						Assign
																					</button>
																				</div>
																			</TableCell>
																		</TableRow>
																	);
																}
															)}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<SuccessModal
				open={successModalFlag}
				closeModal={() => setSuccessModalFlag(false)}
				title="Success"
				message={`The workgroup is assigned to Fill Station – ${fillStationNumber} successfully. Please proceed to assign another workgroup to Fill Station`}
			/>
		</>
	);
};

export default WorkGroupSelectionModal;
