import api from "../../api";

export const initPv2_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/init-pv2/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPv2StationStatus_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/workstation/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFailReasons_api = async () => {
  try {
    let response = await api.get(`/fill/get-fail-reasons`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const updatePv2Verification_api = async (payload: any) => {
  try {
    let response = await api.post(`/fill/pv2-verification`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMaterialDetails_api = async (payload: any) => {
  try {
    let response = await api.patch(`/fill/remainingMaterialOtherDetails`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRemainingMaterialDetails_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/remainingMaterialOtherDetails?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};