import { all, takeLatest, put} from "redux-saga/effects";
import { addProduct_api, deleteProductImage_api, deleteProduct_api, getProductDetail_api, getProductList_api, getTestImageList_api, getTestNames_api, initiateTest_api, modelLastTrained_api, postProductImage_api, retrainModel_api } from "../../services";
import { RX_PRODUCT_IMAGE } from "../constants/action";
import { SagaInterface, YieldInterface } from "../constants/interface";
import { setRxProductList, setRxProductDetail, setDeleteProductResponse, setDeleteProductImageResponse, setUploadProductImageResponse, setAddProductResponse, setInitiateTestResponse, setTestNames, setTestImageList, setModelRetrainResponse, setLastRetrainDetails } from "./reducer";

function* getProductList_saga(params: SagaInterface) {
  try {
    let { payload} = params;
    
    let productList: YieldInterface = yield getProductList_api(payload);

    yield put(setRxProductList({
        success: true,
        ...productList
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setRxProductList({
        success: false,
        ...error.response.data
    }))
  }
}

function* getProductDetail_saga(params: SagaInterface) {
  try {
    let { payload} = params;
    
    let productDetail: YieldInterface = yield getProductDetail_api(payload);

    yield put(setRxProductDetail({
        success: true,
        ...productDetail
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setRxProductDetail({
        success: false,
        ...error.response.data
    }))
  }
}

function* addProduct_saga(params: SagaInterface) {
  try {
    let { payload} = params;
    
    let addProductResponse: YieldInterface = yield addProduct_api(payload);

    yield put(setAddProductResponse({
        success: true,
        ...addProductResponse
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setAddProductResponse({
        success: false,
        ...error.response.data
    }))
  }
}

function* deleteProduct_saga(params: SagaInterface) {
  try {
    let { payload} = params;
    
    let deleteProductResponse: YieldInterface = yield deleteProduct_api(payload);

    yield put(setDeleteProductResponse({
        success: true,
        ...deleteProductResponse
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setDeleteProductResponse({
        success: false,
        ...error.response.data
    }))
  }
}

function* deleteProductImage_saga(params: SagaInterface) {
  try {
    let { payload} = params;
    
    let deleteProductImageResponse: YieldInterface = yield deleteProductImage_api(payload);

    yield put(setDeleteProductImageResponse({
        success: true,
        ...deleteProductImageResponse
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setDeleteProductImageResponse({
        success: false,
        ...error.response.data
    }))
  }
}

function* uploadProductImage_saga(params: SagaInterface) {
  try {
    let { payload} = params;
    
    let uploadProductImageResponse: YieldInterface = yield postProductImage_api(payload);

    yield put(setUploadProductImageResponse({
        success: true,
        ...uploadProductImageResponse
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setUploadProductImageResponse({
        success: false,
        ...error.response.data
    }))
  }
}

function* initiateTest_saga(params: SagaInterface) {
  try {
    let { payload} = params;
    
    let initiateTestResponse: YieldInterface = yield initiateTest_api(payload);

    yield put(setInitiateTestResponse({
        success: true,
        ...initiateTestResponse
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setInitiateTestResponse({
        success: false,
        ...error.response.data
    }))
  }
}

function* getTestNames_saga(params: SagaInterface) {
  try {
    
    let response: YieldInterface = yield getTestNames_api();

    yield put(setTestNames({
        success: true,
        ...response
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setTestNames({
        success: false,
        ...error.response.data
    }))
  }
}

function* getTestImageList_saga(params: SagaInterface) {
  try {
    
    let response: YieldInterface = yield getTestImageList_api();

    yield put(setTestImageList({
        success: true,
        ...response
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setTestImageList({
        success: false,
        ...error.response.data
    }))
  }
}

function* retrainModel_saga(params: SagaInterface) {
  try {
    
    let response: YieldInterface = yield retrainModel_api();

    yield put(setModelRetrainResponse({
        success: true,
        ...response
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setModelRetrainResponse({
        success: false,
        ...error.response.data
    }))
  }
}

function* modelLastRetrained_saga(params: SagaInterface) {
  try {
    
    let response: YieldInterface = yield modelLastTrained_api();

    yield put(setLastRetrainDetails({
        success: true,
        ...response
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setLastRetrainDetails({
        success: false,
        ...error.response.data
    }))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(RX_PRODUCT_IMAGE.GET_PRODUCT_LIST, getProductList_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.GET_PRODUCT_DETAIL, getProductDetail_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.DELETE_PRODUCT, deleteProduct_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.DELETE_PRODUCT_IMAGE, deleteProductImage_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.UPLOAD_PRODUCT_IMAGE_REQUEST, uploadProductImage_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.ADD_PRODUCT, addProduct_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.INITIATE_TEST, initiateTest_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.GET_TEST_NAMES, getTestNames_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.GET_TEST_IMAGE_LIST, getTestImageList_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.RETRAIN_AVSV_MODEL, retrainModel_saga)]);
  yield all([takeLatest(RX_PRODUCT_IMAGE.GET_LAST_TRAINED_DATE, modelLastRetrained_saga)]);
}