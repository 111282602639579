import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AlertModal from "../../../components/alertModal";
import InitiateTestModal from "../../../components/initiateTestModal";
import SuccessModal from "../../../components/successModal";
import RxProductImageActions from "../../../redux/rx_product_image/actions";
import BarcodeReader from "react-barcode-reader";

const { getTestNames, clearInitiateTestResponse } = RxProductImageActions;

const InitiateTest = () => {
  const initiateTestResponse = useSelector(
    (state: any) => state?.rxProductImage?.initiateTestResponse
  );
  const testNames = useSelector(
    (state: any) => state?.rxProductImage?.testNames
  );

  const dispatch = useDispatch();

  const [initiateAlertModalFlag, setInitiateAlertModalFlag] =
    useState<boolean>(false);
  const [initiateTestModalFlag, setInitiateTestModalFlag] =
    useState<boolean>(false);
  const [successModalFlag, setSuccessModalFlag] = useState<boolean>(false);

  const [lpn, setLpn] = useState("1234");

  useEffect(() => {
    dispatch(getTestNames());
  }, [dispatch]);

  useEffect(() => {
    if (initiateTestResponse?.success) {
      setInitiateTestModalFlag(false);
      setInitiateAlertModalFlag(false);
      setSuccessModalFlag(true);
      dispatch(clearInitiateTestResponse());
    }
  }, [initiateTestResponse, dispatch]);

  const checkValidBarcode = (code: any) => {
    var pattern = new RegExp(/^[0-9]*$/);
    return pattern.test(code);
  };

  const onScanning = (data: any) => {
    if (!initiateAlertModalFlag) {
      return;
    }
    console.log("i am scanned wow", data);

    if (data?.toString()?.includes(";")) {
    } else {
      checkValidBarcode(data) && setLpn(data);
      setInitiateAlertModalFlag(false);
      setInitiateTestModalFlag(true);
    }
  };

  return (
    <section className="body-routing">
      <div className="page-wrap">
        <div className="breadcrumb-block">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/rx_product_image/config">Rx Product Image</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Initiate Test
              </li>
            </ol>
          </nav>
        </div>
        <div className="invite-card d-flex align-items-center justify-content-between">
          <h5>Please Click on “Initiate” button to start Test Tote Induction</h5>
          <button
            type="button"
            className="blue-btn pd-l40 pd-r40"
            data-bs-toggle="modal"
            data-bs-target="#lpnAlertgModal"
            onClick={() => setInitiateAlertModalFlag(true)}
          >
            Initiate
          </button>
        </div>
      </div>
      <AlertModal
        open={initiateAlertModalFlag}
        closeModal={() => {
          setInitiateAlertModalFlag(false);
          setInitiateTestModalFlag(true);
        }}
        title="Scan Tote’s LPN"
        message="Please scan the Tote’s LPN# to proceed with Predict Test of the product"
      />
      <SuccessModal
        open={successModalFlag}
        closeModal={() => setSuccessModalFlag(false)}
        title="Success"
        message="Please place the Test Tote on the conveyor and proceed to the Test
        Results"
      />
      {initiateTestModalFlag ? (
        <InitiateTestModal
          open={initiateTestModalFlag}
          closeModal={() => setInitiateTestModalFlag(false)}
          lpn={lpn}
          testNames={testNames?.data}
        />
      ) : null}
      <BarcodeReader
        onScan={(data: any) => {
          onScanning(data);
        }}
        onError={(error: any) => {
          console.log("barcode error it is", error);
          onScanning(error);
        }}
      />
    </section>
  );
};

export default InitiateTest;
