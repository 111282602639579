import { Modal } from "@mui/material";

interface DeleteModalInterFace {
  open: boolean;
  closeModal: ()=>void;
  title: string;
  message: string;
  confirmDelete: ()=>void;
}

const DeleteModal: React.FC<DeleteModalInterFace> = (props) => {
  return(
    <Modal open={props.open}>
      <div className="modal cus-modal fade show" id="deletemsgModal" tabIndex={-1} aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal360 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                                fill="white" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body msg-body mr-b60">
                    <h4>
                        {props.message}
                    </h4>
                </div>
                <div className="modal-footer bottom-btn border-0">
                    <button type="button" className="border-blue-btn pd-l40 pd-r40" data-bs-dismiss="modal" onClick={props.closeModal}>
                        <svg className="mr-r10" width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.21331 2.90996L7.10081 0.0224609L7.92564 0.847294L5.03814 3.73479L7.92564 6.62229L7.10081 7.44713L4.21331 4.55963L1.32581 7.44713L0.500977 6.62229L3.38848 3.73479L0.500977 0.847294L1.32581 0.0224609L4.21331 2.90996Z"
                                fill="#46AEFD" />
                        </svg>
                        Cancel
                    </button>
                    <button type="button" className="blue-btn pd-l40 pd-r40 mr-l10" data-bs-dismiss="modal" onClick={props.confirmDelete}>
                        <svg className="mr-r10" width="11" height="11" viewBox="0 0 11 11" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.83887 2.05469H10.3389V3.05469H9.33887V9.55469C9.33887 9.6873 9.28619 9.81447 9.19242 9.90824C9.09865 10.002 8.97148 10.0547 8.83887 10.0547H1.83887C1.70626 10.0547 1.57908 10.002 1.48531 9.90824C1.39155 9.81447 1.33887 9.6873 1.33887 9.55469V3.05469H0.338867V2.05469H2.83887V0.554688C2.83887 0.422079 2.89155 0.294902 2.98531 0.201134C3.07908 0.107366 3.20626 0.0546875 3.33887 0.0546875H7.33887C7.47148 0.0546875 7.59865 0.107366 7.69242 0.201134C7.78619 0.294902 7.83887 0.422079 7.83887 0.554688V2.05469ZM8.33887 3.05469H2.33887V9.05469H8.33887V3.05469ZM3.83887 4.55469H4.83887V7.55469H3.83887V4.55469ZM5.83887 4.55469H6.83887V7.55469H5.83887V4.55469ZM3.83887 1.05469V2.05469H6.83887V1.05469H3.83887Z"
                                fill="white" />
                        </svg>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
    </Modal>
  )
}

export default DeleteModal;