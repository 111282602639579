import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PackoutActions from "../../../redux/fill_process/packout_station/actions";
import { useDispatch, useSelector } from "react-redux";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import { toast } from "react-toastify";
import moment from "moment";
const { getPackoutRxsDetails } = PackoutActions;

const PackoutStationViewRxDetailsViewDetails = () => {
  const dispatch = useDispatch();
  const packoutRxsDetails = useSelector(
    (state: any) => state?.packoutStation?.packoutRxsDetails
  );
  const [spinnerFlag, setSpinnerFlag] = useState<boolean>(false);

  const urlParam = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (urlParam && urlParam?.id) {
      setSpinnerFlag(true);
      dispatch(getPackoutRxsDetails(urlParam.id));
    }
  }, [dispatch, urlParam]);

  useEffect(() => {
    if (packoutRxsDetails) {
      setSpinnerFlag(false);
      if (!packoutRxsDetails?.success) {
        toast.error(
          packoutRxsDetails?.data?.message || packoutRxsDetails?.message
        );
      }
    }
  }, [packoutRxsDetails]);
  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={spinnerFlag} />
      <div className="page-wrap">
        <div className="breadcrumb-block">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/fill_process/packout_station">Fill Process</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/fill_process/packout_station">Packout Station</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="" onClick={() => navigate(-1)}>
                  View RX Details
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                View Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="detailswgs-wrap">
          <div className="blue-card-panel mat-data-card">
            <div className="b-card-body pd10">
              <div className="blue-card-panel">
                <div className="b-card-head">
                  <h5>RX Details in the Work Group</h5>
                </div>
                <div className="b-card-body pd10">
                  <TableContainer>
                    <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Seq</TableCell>
                          <TableCell align="center">RX TRX ID</TableCell>
                          <TableCell align="center">
                            RX Fill Description
                          </TableCell>
                          <TableCell align="center">RX Fill Value</TableCell>
                          <TableCell align="center">User ID</TableCell>
                          <TableCell align="center">Date/Timestamp</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {packoutRxsDetails?.data?.response?.RxTrxDetailsList?.map(
                          (item: any, index: number) => {
                            return (
                              <TableRow key={`${index}`}>
                                <TableCell align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  {item?.rx_trx_id}
                                </TableCell>
                                <TableCell align="center">
                                  {item?.state}
                                </TableCell>
                                <TableCell align="center">
                                  {item?.rx_fill_value}
                                </TableCell>
                                <TableCell align="center">
                                  {item?.user_name}
                                </TableCell>
                                <TableCell align="center">
                                  {item?.created_at &&
                                    moment(
                                      item?.created_at
                                    ).tz(moment.tz.guess()).format("MM/DD/YYYY hh:mm A z")}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PackoutStationViewRxDetailsViewDetails;
