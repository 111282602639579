import { Modal } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import RxProductImageActions from "../redux/rx_product_image/actions";
import { Spinner } from "./loaders";
interface AddProductModalInterFace {
	open: boolean;
	closeModal: () => void;
}

const { addProduct } = RxProductImageActions;

const validRegex = RegExp("^\\w+$")

const AddProductModal: React.FC<AddProductModalInterFace> = (props) => {
	const dispatch = useDispatch();

	const [productId, setProductId] = useState<string>("");
	const [productName, setProductName] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");

	const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === "prod-name") {
			setProductName(value);
      setFormError("");
		}
		if (name === "prod-id") {
			setProductId(value);
      setFormError("");
		}
	};

	const addProductHandler = () => {
    if(productId === "" || !validRegex.test(productId) || productName === ""){
      if(productId === ""){
        setFormError("Please enter a Product Id");
      }
      else if(!validRegex.test(productId)){
        setFormError("Only alphabets and numbers are allowed in Product Id");
      }
      else if(productName === ""){
        setFormError("Please provide a Product name");
      }
      return;
    }
    setLoader(true);
		const payload = {
			product_id: productId,
			product_name: productName,
		};
		dispatch(addProduct(payload));
	};

	return (
		<Modal open={props.open}>
			<div
				className="modal cus-modal fade show"
				id="addproductModal"
				tabIndex={-1}
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal360 modal-dialog-centered modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								Add Product
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={props.closeModal}
                disabled={loader}
							>
								<svg
									width="8"
									height="8"
									viewBox="0 0 8 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
										fill="white"
									/>
								</svg>
							</button>
						</div>
						<div className="modal-body eu-reference-body">
              {formError !== "" ? <div className="form-error-msg">{formError}</div> : null}
							<form action="" className="row">
								<div className="col-sm-6">
									<div className="form-group cus-group mr-b15">
										<label htmlFor="" className="inp-lable-title">
											Product ID
										</label>
										<input
											type="text"
											className="form-control cus-control"
											name="prod-id"
											id=""
											value={productId}
											onChange={textChange}
										/>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-group cus-group mr-b15">
										<label htmlFor="" className="inp-lable-title">
											Product Name
										</label>
										<input
											type="text"
											className="form-control cus-control"
											name="prod-name"
											id=""
											value={productName}
											onChange={textChange}
										/>
									</div>
								</div>
							</form>
						</div>
						<div className="modal-footer border-0">
							{loader ? (
                <button type="button"
								className="blue-btn pd-l20 pd-r20"
								data-bs-dismiss="modal">
                  <Spinner />
                </button>
              ) : (<button
								type="button"
								className="blue-btn pd-l20 pd-r20"
								data-bs-dismiss="modal"
								onClick={addProductHandler}
							>
								<svg
									className="mr-r10"
									width="10"
									height="10"
									viewBox="0 0 10 10"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M4.36209 0H5.19542C5.2695 0 5.30653 0.037037 5.30653 0.111111V9.88889C5.30653 9.96296 5.2695 10 5.19542 10H4.36209C4.28801 10 4.25098 9.96296 4.25098 9.88889V0.111111C4.25098 0.037037 4.28801 0 4.36209 0Z"
										fill="white"
									/>
									<path
										d="M0.111111 4.47266H9.44444C9.51852 4.47266 9.55556 4.50969 9.55556 4.58377V5.4171C9.55556 5.49117 9.51852 5.52821 9.44444 5.52821H0.111111C0.037037 5.52821 0 5.49117 0 5.4171V4.58377C0 4.50969 0.037037 4.47266 0.111111 4.47266Z"
										fill="white"
									/>
								</svg>
								Add Product
							</button>)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default AddProductModal;
