import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UserImage, Logout, DefaultUser } from "../assets/images/index";
import Styles from "./styles-topbar";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";
import IdleAlertModal from "./idleAlertModal";
import AuthActions from "../redux/auth/actions";
import FillStationActions from "../redux/fill_process/fill_station/actions";
import WorkGroupSelectionActions from "../redux/fill_process/work_group_selection/actions";
import qs from "qs";
const {
  getWorkstationsWithIp,
  validateWorkstation,
  logout,
  clearValidateWorkstationResponse,
  clearLogoutResponse,
} = AuthActions;

const { getWorkStationStatusList } = WorkGroupSelectionActions;
const { getFillStationDetailsById } = FillStationActions;
const TopBar = () => {
  const dispatch = useDispatch();
  const workstationsWithIpResponse: any = useSelector(
    (state: any) => state?.auth?.workstationsWithIpResponse
  );
  const validateWorkStationResponse: any = useSelector(
    (state: any) => state?.auth?.validateWorkStationResponse
  );
  const logoutResponse: any = useSelector(
    (state: any) => state?.auth?.logoutResponse
  );
  const fillStationStatus = useSelector(
    (state: any) => state?.workGroupSelection?.fillStationStatus
  );
  const [isUserProfileOpened, setIsUserProfileOpened] = useState<any>(null);
  const [userDetailsState, setUserDetailsState] = useState<any>();
  const [timedout, setTimedout] = useState<number>(600000);
  const [idleAlertModalFlag, setIdleAlertModalFlag] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [justToRerender, setJustToRerender] = useState<number>(0);
  const [fillDescription, setFillDescription] = useState<string>("");
  const [toBeTimedout, setToBeTimedout] = useState<boolean>(true);
  const timerRef = useRef<any>(null);
  const navigate = useNavigate();

  const [previousStation, setPreviousStation] = useState<any>("");

  const handleLogout = () => {
    dispatch(logout(userDetailsState?.id));
  };

  useEffect(() => {
    if (logoutResponse) {
      if (!logoutResponse?.success) {
        dispatch(clearLogoutResponse());
      } else {
        localStorage.clear();
        window.location.reload();
      }
    }
  }, [logoutResponse]);

  const closeModal = () => {
    setIdleAlertModalFlag(false);
    clearInterval(timerRef.current);
    setCounter(0);
  };

  const startTimer = () => {
    if (toBeTimedout) {
      //CHECK IF THIS WORKSTATION SHOULD BE TIMEDOUT OR NOT
      setIdleAlertModalFlag(true);
      timerRef.current = setInterval(handleTime, 1000);
    }
  };

  useIdleTimer({
    timeout: timedout,
    onIdle: startTimer,
  });

  function handleTime() {
    setCounter((counter) => counter + 1);
  }

  useEffect(() => {
    if (counter >= 60) {
      clearInterval(timerRef.current);
      setCounter(0);
      handleLogout();
    }
  }, [counter]);

  function getMstWorkstations() {
    let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
    dispatch(getWorkstationsWithIp(userInfo?.role));
  }

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
    setUserDetailsState(userInfo);
    dispatch(getWorkStationStatusList());
    dispatch(getWorkstationsWithIp(userInfo?.role));
    const timer = setInterval(getMstWorkstations, 20000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (fillStationStatus) {
      if (fillStationStatus?.success === true) {
        let checkAsssigned = false;
        for (let i = 0; i < fillStationStatus?.data?.response?.length; i++) {
          if (
            fillStationStatus?.data?.response[i].user ===
            userDetailsState?.firstName + " " + userDetailsState?.lastName
          ) {
            localStorage.setItem(
              "workstation",
              fillStationStatus?.data?.response[i].id
            );
            setJustToRerender(1);
            checkAsssigned = true;
            break;
          }
        }
        if (!checkAsssigned) {
          // localStorage.removeItem("workstation");
          // setJustToRerender(2);
        }
      }
    }
  }, [fillStationStatus]);

  useEffect(() => {
    if (validateWorkStationResponse) {
      let temp: any = document.getElementById("fillDropSelect");
      if (validateWorkStationResponse?.success === true) {
        toast.success(validateWorkStationResponse?.data?.message);
        setPreviousStation(temp.selectedIndex);
        temp.options[0].innerText === "Select Fill Station" && temp.remove(0);
        dispatch(getWorkStationStatusList());
        dispatch(
          getFillStationDetailsById(
            qs.stringify({
              user_id: userDetailsState?.id || "",
            })
          )
        );
        routeToSelectedWorkstationModule();
      } else if (validateWorkStationResponse?.success === false) {
        toast.error(validateWorkStationResponse?.message);
        if (temp.selectedIndex) {
          temp.selectedIndex = previousStation;
        }
      }
      dispatch(clearValidateWorkstationResponse());
    }
  }, [validateWorkStationResponse]);

  useEffect(() => {
    if (
      workstationsWithIpResponse &&
      workstationsWithIpResponse?.success === true
    ) {
      for (let i = 0; i < workstationsWithIpResponse?.data?.length; i++) {
        if (
          workstationsWithIpResponse?.data[i].id === "RPh" &&
          workstationsWithIpResponse?.data[i].user_id === userDetailsState?.id
        ) {
          if (workstationsWithIpResponse?.data[i].timeout === false) {
            setToBeTimedout(false);
          } else {
            setToBeTimedout(true);
            setTimedout(workstationsWithIpResponse?.data[i].idle_time * 1000);
          }
          break;
        }
        if (
          localStorage.getItem("workstation") &&
          workstationsWithIpResponse?.data[i].id ===
            localStorage.getItem("workstation")
        ) {
          setTimedout(workstationsWithIpResponse?.data[i].idle_time * 1000);
          break;
        }
      }
    }
  }, [workstationsWithIpResponse]);

  const routeToSelectedWorkstationModule = () => {
    if (fillDescription?.length) {
      if (
        fillDescription.includes("Fill") ||
        fillDescription.includes("fill") ||
        fillDescription.includes("FILL")
      ) {
        navigate("/fill_process/fill_station");
      } else if (
        fillDescription.includes("PV2") ||
        fillDescription.includes("pv2") ||
        fillDescription.includes("Pv2")
      ) {
        navigate("/fill_process/pv2_station");
      } else if (
        fillDescription.includes("Pack") ||
        fillDescription.includes("pack") ||
        fillDescription.includes("PACK")
      ) {
        navigate("/fill_process/packout_station");
      } else if (
        fillDescription.includes("Error") ||
        fillDescription.includes("error") ||
        fillDescription.includes("ERROR")
      ) {
        navigate("/fill_process/error_correction");
      }
    }
  };
  const closeUserProfile = () => {
    setIsUserProfileOpened(null);
  };

  const handleAssignWorkstation = (ip_address: any) => {
    if (!ip_address) {
      return;
    }
    for (let i = 0; i < workstationsWithIpResponse?.data?.length; i++) {
      if (workstationsWithIpResponse?.data[i].ip_address === ip_address) {
        setFillDescription(workstationsWithIpResponse?.data[i].description);
        const payload = {
          ip_address,
          user_id: userDetailsState?.id,
          first_name: userDetailsState?.firstName,
          last_name: userDetailsState?.lastName,
        };
        dispatch(validateWorkstation(payload));
        setTimedout(workstationsWithIpResponse?.data[i].idle_time * 1000);
        setToBeTimedout(workstationsWithIpResponse?.data[i].timeout);
        localStorage.setItem(
          "workstation",
          workstationsWithIpResponse?.data[i].id
        );
        break;
      }
    }
  };

  const renderUserProfile = () => {
    return (
      <Menu
        style={{ top: "17px" }}
        open={isUserProfileOpened}
        onClose={closeUserProfile}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={Styles.userProfileMetaData}>
                <img src={DefaultUser} alt="profile"></img>
              </span>
              <div
                style={Styles.userProfileName}
              >{`${userDetailsState?.firstName} ${userDetailsState?.lastName}`}</div>
            </div>
          </div>
        </MenuItem>
        <MenuItem style={Styles.width160} onClick={() => handleLogout()}>
          <img src={Logout} alt="logout"></img>
          <span style={Styles.userProfileOptions}>Log Out</span>
        </MenuItem>
      </Menu>
    );
  };

  const renderWorkStationDropdown = () => {
    return (
      <select
        id="fillDropSelect"
        className="fillDropSelect"
        onChange={(e) => handleAssignWorkstation(e.target.value)}
      >
        <option value="">Select Fill Station</option>
        {workstationsWithIpResponse?.data?.map((i: any) => {
          return (
            <>
              {i.id !== "RPh" && (
                <option
                  value={i.ip_address}
                  selected={localStorage.getItem("workstation") === i.id}
                >
                  {i.description}
                </option>
              )}
            </>
          );
        })}
      </select>
    );
  };

  return (
    <div>
      <header className="head-wrap">
        {renderWorkStationDropdown()}
        <div className="head-u-info">
          <div className="xs-logo-head">
            <button type="button" className="toggle-btn">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7ZM4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12ZM4 17C4 16.4477 4.44772 16 5 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17Z"
                  fill="#ffffff"
                />
              </svg>
            </button>
            <div className="xs-h-logo">
              <img src={UserImage} alt="" title="" />
            </div>
          </div>
          <div className="u-block">
            <div className="u-info">
              <h5>{`${userDetailsState?.firstName} ${userDetailsState?.lastName}`}</h5>
              <span>{userDetailsState?.role}</span>
            </div>
            <div
              className="user-pro-img"
              onClick={(event) => setIsUserProfileOpened(event?.currentTarget)}
              style={{ cursor: "pointer" }}
            >
              <div className="u-p-inn">
                <img src={UserImage} alt="" title="" />
              </div>
            </div>
          </div>
        </div>
        {renderUserProfile()}
      </header>
      <IdleAlertModal
        open={idleAlertModalFlag}
        closeModal={closeModal}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default TopBar;
