import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import WorkGroupSelectionActions from "../redux/fill_process/work_group_selection/actions";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../components/loaders";

const { printWorkgroupMaterial, clearSetPrintWorkgroupsMaterial } =
  WorkGroupSelectionActions;

interface PrintFillListModalInterFace {
  open: boolean;
  closeModal: () => void;
  workGroupId: string;
}

const PrintFillListModal: React.FC<PrintFillListModalInterFace> = ({
  open,
  closeModal,
  workGroupId,
}) => {
  const workgroupsMaterial = useSelector(
    (state: any) => state?.workGroupSelection?.workgroupsMaterial
  );
  const printWorkgroupsMaterialResponse = useSelector(
    (state: any) => state?.workGroupSelection?.printWorkgroupsMaterialResponse
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClickPrint = () => {
    if (workGroupId?.length) {
      dispatch(printWorkgroupMaterial(workGroupId));
      setLoading(true);
    }
  };

  useEffect(() => {
    if (printWorkgroupsMaterialResponse) {
      setLoading(false);
      if (!printWorkgroupsMaterialResponse.success) {
        toast.error(printWorkgroupsMaterialResponse?.message);
        dispatch(clearSetPrintWorkgroupsMaterial());
      } else if (printWorkgroupsMaterialResponse.success) {
        downloadFile(printWorkgroupsMaterialResponse?.data);
        dispatch(clearSetPrintWorkgroupsMaterial());
      }
    }
  }, [printWorkgroupsMaterialResponse]);

  const downloadFile = (url: string) => {
    window.open(url, "_blank", "popup=true, toolbar=no");
  };

  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="printfillModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal552 modal-dialog-centered modal-dialog-scrollable">
          <SimpleBackdropSpinner open={loading} />
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Print Fill List
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body printf-m-body text-center">
              <div className="blue-card-panel">
                <div className="b-card-head">
                  <h5>Fill Material Details</h5>
                </div>
                <div className="b-card-body pd10 table-responsive">
                  <TableContainer>
                    <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className="p-0">
                            Seq
                          </TableCell>
                          <TableCell align="center" className="p-0">
                            Fill Product No.
                          </TableCell>
                          <TableCell align="center" className="p-0">
                            Fill Product Description
                          </TableCell>
                          <TableCell align="center" className="p-0">
                            Quantity
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <tbody>
                        {workgroupsMaterial &&
                          workgroupsMaterial?.data?.workgroupFill?.map(
                            (item: any, index: number) => {
                              return (
                                <TableRow>
                                  <TableCell align="center">
                                    {index+1}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.productId}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.productDescription}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.qty}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="blue-card-panel mr-t20">
                <div className="b-card-head">
                  <h5>Pack Material Details</h5>
                </div>
                <div className="b-card-body table-responsive pd10">
                  <TableContainer>
                    <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className="p-0">
                            Seq
                          </TableCell>
                          <TableCell align="center" className="p-0">
                            Fill Product No.
                          </TableCell>
                          <TableCell align="center" className="p-0">
                            Fill Product Description
                          </TableCell>
                          <TableCell align="center" className="p-0">
                            Quantity
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <tbody>
                        {workgroupsMaterial &&
                          workgroupsMaterial?.data?.workgroupPack?.map(
                            (item: any, index: number) => {
                              return (
                                <TableRow>
                                  <TableCell align="center">
                                    {index+1}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.packingItemId}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.productDescription}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.qty}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="m-bottom-btn text-end mr-t30">
                <button
                  type="button"
                  className="blue-btn pd-l30 pd-r30"
                  onClick={() => handleOnClickPrint()}
                >
                  Print Fill List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PrintFillListModal;
