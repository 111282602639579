import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const errorCorrectionSlice = createSlice({
  name: "errorCorrection",
  initialState: initState,
  reducers: {
    setInitErrorCorrectionData: (state, action) => {
      return { ...state, initErrorCorrectionData: action.payload };
    },
    setManuallyAssociateResponse: (state, action) => {
      return { ...state, manuallyAssociateResponse: action.payload };
    },
    clearManuallyAssociateResponse: (state) => {
      return { ...state, manuallyAssociateResponse: undefined };
    },
    setOtherDetails: (state, action) => {
      return { ...state, otherDetails: action.payload };
    },
    setUpdateOtherDetails: (state, action) => {
      return { ...state, updatedDetails: action.payload };
    },
    setAddOtherDetails: (state, action) => {
      return { ...state, addedDetails: action.payload };
    },
    clearUpdatedDetails: (state) => {
      return { ...state, updatedDetails: undefined };
    },
    clearAddedDetails: (state) => {
      return { ...state, addedDetails: undefined };
    },
    clearInitErrorCorrectionData: (state) => {
      return { ...state, initErrorCorrectionData: undefined };
    },
    clearOtherDetails: (state) => {
      return { ...state, otherDetails: undefined };
    },
  },
});

export const {
  setInitErrorCorrectionData,
  setManuallyAssociateResponse,
  clearManuallyAssociateResponse,
  setOtherDetails,
  setAddOtherDetails,
  setUpdateOtherDetails,
  clearAddedDetails,
  clearUpdatedDetails,
  clearInitErrorCorrectionData,
  clearOtherDetails,
} = errorCorrectionSlice.actions;

export default errorCorrectionSlice.reducer;
