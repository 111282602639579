import api from "../../api";

export const getPackoutMaterialData_api = async (payload: string) => {
	try {
		let response = await api.get(`/fill/packout_materials/${payload}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const printShippingLabel_api = async (payload: string) => {
	try {
		let response = await api.get(`/fill/pack-shipping/${payload}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getPackoutRxs_api = async (payload: string) => {
	try {
		let response = await api.get(`/fill/packout_rxs/${payload}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updatePackoutStatus_api = async (payload: any) => {
	try {
		let response = await api.post(`/fill/pack-shipping/${payload.rx_id}`, payload.data);
		return response.data;
	} catch (error) {
		throw error;
	}
};