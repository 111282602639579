import { all, takeLatest, put} from "redux-saga/effects";
import { rphAssignment_api } from "../../services";
import { RPH_ASSIGNMENT } from "../constants/action";
import { SagaInterface, YieldInterface } from "../constants/interface";
import { setRphAssignmentResponse } from "./reducer";

function* assignRph_saga(params: SagaInterface) {
  try {
    const {payload} = params;
    let response: YieldInterface = yield rphAssignment_api(payload);

    yield put(setRphAssignmentResponse({
        success: true,
        ...response
    }))
  } catch (error: any) {
    console.log(error);

    yield put(setRphAssignmentResponse({
        success: false,
        ...error.response.data
    }))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(RPH_ASSIGNMENT.ASSIGN_RPH, assignRph_saga)]);
}