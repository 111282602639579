import api from "../../api";

export const getFillStationStatus_api = async () => {
  try {
    let response = await api.get(`/fill/fill-station-status`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWorkgroupsList_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/workgroup?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWorkgroupsHistory_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/workgroup_history?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rxDetailsViewDetails_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/rx/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rxDetails_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/workgroup_rx/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignFillStationToWorkgroup_api = async (payload: any) => {
  try {
    let response = await api.post(
      `/fill/workgroup-assign-fill-station`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWorkgroupData_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/workgroup/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWorkgroupMaterial_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/workgroup_materials/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const printWorkgroupMaterial_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/print_workgroup_materials/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRemainingWorkgroups_api = async () => {
  try {
    let response = await api.get(`/fill/remaining-to-fill-workgroups`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reprintRxLabels_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/reprint-rx-labels?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPackoutWorkgroupData_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/packout_workgroup_data/${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
