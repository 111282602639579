import { Backdrop, CircularProgress } from "@mui/material";

export function SimpleBackdropSpinner(props: {open: boolean}) {
  return(
    <div>
      <Backdrop open={props.open} sx={{zIndex: "9999"}}>
        <CircularProgress sx={{color: "#fff"}}/>
      </Backdrop>
    </div>
  )
}

export function Spinner() {
  return(
    <div style={{display: "flex", width: "100%"}}>
        <CircularProgress sx={{color: "#fff"}} size={14}/>
    </div>
  )
}