export const AUTH = {
  LOGIN: "LOGIN",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  VALIDATE_WORKSTATION: "VALIDATE_WORKSTATION",
  GET_WORKSTATIONS_WITH_IP: "GET_WORKSTATIONS_WITH_IP",
  LOGOUT: "LOGOUT",
};

export const RX_PRODUCT_IMAGE = {
  GET_PRODUCT_LIST: "GET_RX_PRODUCT_IMAGE_LIST",
  SET_PRODUCT_LIST: "SET_RX_PRODUCT_IMAGE_LIST",
  GET_PRODUCT_DETAIL: "GET_SPECIFICT_RX_PRODUCT_DETAIL",
  SET_PRODUCT_DETAIL: "SET_SPECIFICT_RX_PRODUCT_DETAIL",
  UPLOAD_PRODUCT_IMAGE_REQUEST: "UPLOAD_RX_PRODUCT_IMAGE_REQUEST",
  UPLOAD_PRODUCT_IMAGE_RESPONSE: "UPLOAD_RX_PRODUCT_IMAGE_RESPONSE",
  DELETE_PRODUCT: "DELETE_RX_PRODUCT",
  DELETE_PRODUCT_IMAGE: "DELETE_RX_PRODUCT_IMAGE",
  ADD_PRODUCT: "ADD_RX_PRODUCT",
  INITIATE_TEST: "INITIATE_TEST",
  GET_TEST_NAMES: "GET_TEST_NAMES",
  GET_TEST_IMAGE_LIST: "GET_TEST_IMAGE_LIST",
  RETRAIN_AVSV_MODEL: "RETRAIN_AVSV_MODEL",
  GET_LAST_TRAINED_DATE: "GET_LAST_TRAINED_DATE",
};

export const USER_WORK_STATION_REF = {
  GET_USER_WORK_STATION_LIST: "GET_uSER_WORK_STATION_LIST",
  UPDATE_USER_WORK_STATION_DETAIL: "UPDATE_USER_WORK_STATION_DETAIL",
  DELETE_USER_WORK_STATION: "DELETE_USER_WORK_STATION",
};

export const FILL_PROCESS = {
  WORK_GROUP_SELECTION: {
    GET_FILL_STATION_STATUS: "GET_FILL_STATION_STATUS",
    GET_WORK_GROUP_LIST: "GET_WORK_GROUP_LIST",
    GET_WORK_GROUP_HISTORY: "GET_WORK_GROUP_HISTORY",
    GET_RX_DETAILS_VIEW_DETAILS: "GET_RX_DETAILS_VIEW_DETAILS",
    GET_RX_DETAILS: "GET_RX_DETAILS",
    ASSIGN_FILL_STATION_TO_WORK_GROUP: "ASSIGN_FILL_STATION_TO_WORK_GROUP",
    GET_WORK_GROUP_DATA: "GET_WORK_GROUP_DATA",
    GET_WORK_GROUP_MATERIAL: "GET_WORK_GROUP_MATERIAL",
    PRINT_WORK_GROUP_MATERIAL: "PRINT_WORK_GROUP_MATERIAL",
    GET_REMAINING_WORK_GROUPS: "GET_REMAINING_WORK_GROUPS",
    REPRINT_RX_LABELS: "REPRINT_RX_LABELS",
  },
  FILL_STATION: {
    GET_FILL_STATION_DETAILS_BY_ID: "GET_FILL_STATION_DETAILS_BY_ID",
    GET_OTHER_DETAILS: "GET_OTHER_DETAILS",
    UPDATE_OTHER_DETAILS_FILL: "UPDATE_OTHER_DETAILS_FILL",
    PRINT_RX_LABELS: "PRINT_RX_LABELS",
    ADD_OTHER_DETAILS_FILL: "ADD_OTHER_DETAILS_FILL",
    INIT_FILL_PROCESS: "INIT_FILL_PROCESS",
    GET_RX_DETAILS_BY_ID: "GET_RX_DETAILS_BY_ID",
  },
  PV2_STATION: {
    GET_FAIL_REASONS: "GET_FAIL_REASONS",
    UPDATE_PV2_VERIFICATION: "UPDATE_PV2_VERIFICATION",
    INITIATE_PV2_VERIFICATION: "INITIATE_PV2_VERIFICATION",
    GET_REMAINING_MATERIAL_DETAILS: "GET_REMAINING_MATERIAL_DETAILS",
    UPDATE_REMAINING_MATERIAL_DETAILS: "UPDATE_REMAINING_MATERIAL_DETAILS",
    GET_PV2_STAION_STATUS: "GET_PV2_STAION_STATUS",
  },
  PACKOUT_STATION: {
    GET_PACKOUT_MATERIAL_DATA: "GET_PACKOUT_MATERIAL_DATA",
    PRINT_SHIPPING_LABEL: "PRINT_SHIPPING_LABEL",
    GET_PACKOUT_RXS: "GET_PACKOUT_RXS",
    GET_PACKOUT_RXS_DETAILS: "GET_PACKOUT_RXS_DETAILS",
    UPDATE_PACKOUT_STATUS: "UPDATE_PACKOUT_STATUS",
    GET_PACKOUT_WORKGROUP_DATA: "GET_PACKOUT_WORKGROUP_DATA",
  },
  ERROR_CORRECTION: {
    INIT_ERROR_CORRECTION: "INIT_ERROR_CORRECTION",
    MANUALLY_ASSOCIATE: "MANUALLY_ASSOCIATE",
    GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
    UPDATE_OTHER_DETAILS: "UPDATE_OTHER_DETAILS",
    ADD_OTHER_DETAILS: "ADD_OTHER_DETAILS",
  },
};

export const RPH_ASSIGNMENT = {
  ASSIGN_RPH: "ASSIGN_RPH",
};

export const SOCKET = {
  INIT_WORKGROUP_SOCKET: "INIT_WORKGROUP_SOCKET",
};
