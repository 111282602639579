import { all, takeLatest, put } from "redux-saga/effects";
import { FILL_PROCESS } from "../../constants/action";
import { SagaInterface, YieldInterface } from "../../constants/interface";
import {
  setFillStationDetailsById,
  setOtherDetails,
  setUpdateOtherDetailsFill,
  setPrintRxLabels,
  setAddOtherDetailsFill,
  setFillStationData,
  setRxDetailsById
} from "./reducer";
import {
  getFillStationDetailsById_api,
  getOtherDetails_api,
  updateOtherDetails_api,
  printRxLabels_api,
  addOtherDetails_api,
  initFillStation_api,
  getRxDetailsById_api
} from "../../../services";

function* getFillStationDetailsById_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let fillStationDetailsById: YieldInterface =
      yield getFillStationDetailsById_api(payload);

    yield put(
      setFillStationDetailsById({
        success: true,
        ...fillStationDetailsById,
      })
    );
  } catch (error: any) {
    yield put(
      setFillStationDetailsById({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* initFillStation_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let fillStation: YieldInterface = yield initFillStation_api(payload);

    yield put(
      setFillStationData({
        success: true,
        ...fillStation,
      })
    );
  } catch (error: any) {
    yield put(
      setFillStationData({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getOtherDetails_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let otherDetails: YieldInterface = yield getOtherDetails_api(payload);

    yield put(
      setOtherDetails({
        success: true,
        ...otherDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setOtherDetails({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* updateOtherDetailsFill_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let updatedDetails: YieldInterface = yield updateOtherDetails_api(payload);

    yield put(
      setUpdateOtherDetailsFill({
        success: true,
        ...updatedDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setUpdateOtherDetailsFill({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* printRxLabels_saga(params: SagaInterface) {
  try {
    const { payload } = params;
    let updatedDetails: YieldInterface = yield printRxLabels_api(payload);

    yield put(
      setPrintRxLabels({
        success: true,
        ...updatedDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setPrintRxLabels({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* addOtherDetailsFill_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let addedDetailsFill: YieldInterface = yield addOtherDetails_api(payload);

    yield put(
      setAddOtherDetailsFill({
        success: true,
        ...addedDetailsFill,
      })
    );
  } catch (error: any) {
    yield put(
      setAddOtherDetailsFill({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getRxDetailsById_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let rxDetailsById: YieldInterface = yield getRxDetailsById_api(payload);

    yield put(
      setRxDetailsById({
        success: true,
        ...rxDetailsById,
      })
    );
  } catch (error: any) {
    yield put(
      setRxDetailsById({
        success: false,
        ...error.response.data,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      FILL_PROCESS.FILL_STATION.GET_FILL_STATION_DETAILS_BY_ID,
      getFillStationDetailsById_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.FILL_STATION.INIT_FILL_PROCESS,
      initFillStation_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.FILL_STATION.GET_OTHER_DETAILS,
      getOtherDetails_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.FILL_STATION.UPDATE_OTHER_DETAILS_FILL,
      updateOtherDetailsFill_saga
    ),
  ]);
  yield all([
    takeLatest(FILL_PROCESS.FILL_STATION.PRINT_RX_LABELS, printRxLabels_saga),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.FILL_STATION.ADD_OTHER_DETAILS_FILL,
      addOtherDetailsFill_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.FILL_STATION.GET_RX_DETAILS_BY_ID,
      getRxDetailsById_saga
    ),
  ]);
}
