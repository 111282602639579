import { Modal } from "@mui/material";

interface TopLabelMissingModalInterFace {
  open: boolean;
  closeModal: () => void;
}

const TopLabelMissingModal: React.FC<TopLabelMissingModalInterFace> = ({
  open,
  closeModal,
}) => {
  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="lpnAlertgModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal552 modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Scan Tote's LPN#
              </h5>
            </div>
            <div className="modal-body suc-msg-body text-center">
              <svg
                width="52"
                height="45"
                viewBox="0 0 52 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M50.6396 34.0329L32.7429 4.35294C32.0192 3.22085 31.0222 2.28917 29.8437 1.6438C28.6652 0.998434 27.3432 0.660156 25.9996 0.660156C24.656 0.660156 23.3339 0.998434 22.1555 1.6438C20.977 2.28917 19.9799 3.22085 19.2563 4.35294L1.35958 34.0329C0.727374 35.0868 0.383757 36.2885 0.363277 37.5173C0.342797 38.7461 0.646178 39.9586 1.24292 41.0329C1.93283 42.2422 2.93145 43.2467 4.13672 43.9436C5.34198 44.6405 6.71068 45.0049 8.10292 44.9996H43.8963C45.2793 45.0144 46.6419 44.664 47.8463 43.984C49.0507 43.3039 50.0545 42.3182 50.7563 41.1263C51.3705 40.0408 51.6836 38.8108 51.6631 37.5637C51.6426 36.3166 51.2892 35.0977 50.6396 34.0329ZM25.9996 35.6663C25.5381 35.6663 25.087 35.5294 24.7033 35.273C24.3195 35.0167 24.0205 34.6522 23.8439 34.2259C23.6673 33.7995 23.6211 33.3304 23.7111 32.8777C23.8011 32.4251 24.0233 32.0093 24.3497 31.683C24.676 31.3567 25.0918 31.1345 25.5444 31.0444C25.997 30.9544 26.4662 31.0006 26.8925 31.1772C27.3189 31.3538 27.6833 31.6529 27.9397 32.0366C28.1961 32.4203 28.3329 32.8715 28.3329 33.3329C28.3329 33.9518 28.0871 34.5453 27.6495 34.9829C27.2119 35.4204 26.6184 35.6663 25.9996 35.6663ZM28.3329 26.3329C28.3329 26.9518 28.0871 27.5453 27.6495 27.9829C27.2119 28.4204 26.6184 28.6663 25.9996 28.6663C25.3807 28.6663 24.7873 28.4204 24.3497 27.9829C23.9121 27.5453 23.6663 26.9518 23.6663 26.3329V16.9996C23.6663 16.3808 23.9121 15.7873 24.3497 15.3497C24.7873 14.9121 25.3807 14.6663 25.9996 14.6663C26.6184 14.6663 27.2119 14.9121 27.6495 15.3497C28.0871 15.7873 28.3329 16.3808 28.3329 16.9996V26.3329Z"
                  fill="#46AEFD"
                />
              </svg>
              <h3>Alert</h3>
              <h5>The scanned Top Label is not found in the system. Please scan
                the Tote's LPN# to proceed with Error Correction.</h5>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TopLabelMissingModal;
