import { AUTH } from "../constants/action";
import {
  clearValidateWorkstationResponse,
  clearLoginResponse,
  clearLogoutResponse,
} from "./reducer";

const authActions = {
  login: (payload: { username: string; password: string }) => ({
    type: AUTH.LOGIN,
    payload,
  }),

  validateWorkstation: (payload: any) => ({
    type: AUTH.VALIDATE_WORKSTATION,
    payload,
  }),

  getWorkstationsWithIp: (payload: any) => ({
    type: AUTH.GET_WORKSTATIONS_WITH_IP,
    payload,
  }),

  logout: (payload: any) => ({
    type: AUTH.LOGOUT,
    payload,
  }),
  clearValidateWorkstationResponse: () => clearValidateWorkstationResponse(),
  clearLoginResponse: () => clearLoginResponse(),
  clearLogoutResponse: () => clearLogoutResponse(),
};

export default authActions;
