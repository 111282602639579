import { FILL_PROCESS } from "../../constants/action";
import {
  clearSetPrintRxLabels,
  clearUpdatedDetails,
  clearAddedDetails,
  clearFillStationData,
  clearRxDetailsById,
  clearFillStationDetailsById
} from "./reducer";

const fillStationActions = {
  getFillStationDetailsById: (payload: string) => ({
    type: FILL_PROCESS.FILL_STATION.GET_FILL_STATION_DETAILS_BY_ID,
    payload,
  }),
  getOtherDetails: (payload: any) => ({
    type: FILL_PROCESS.FILL_STATION.GET_OTHER_DETAILS,
    payload,
  }),
  updateOtherDetailsFill: (payload: any) => ({
    type: FILL_PROCESS.FILL_STATION.UPDATE_OTHER_DETAILS_FILL,
    payload,
  }),
  printRxLabels: (payload: string) => ({
    type: FILL_PROCESS.FILL_STATION.PRINT_RX_LABELS,
    payload,
  }),
  addOtherDetailsFill: (payload: any) => ({
    type: FILL_PROCESS.FILL_STATION.ADD_OTHER_DETAILS_FILL,
    payload,
  }),
  initFillStation: (payload: any) => ({
    type: FILL_PROCESS.FILL_STATION.INIT_FILL_PROCESS,
    payload
  }),
  getRxDetailsById: (payload: any) => ({
    type: FILL_PROCESS.FILL_STATION.GET_RX_DETAILS_BY_ID,
    payload
  }),
  clearSetPrintRxLabels: () => clearSetPrintRxLabels(),
  clearUpdatedDetails: () => clearUpdatedDetails(),
  clearAddedDetails: () => clearAddedDetails(),
  clearInitFillStationData: () => clearFillStationData(),
  clearRxDetailsById: () => clearRxDetailsById(),
  clearFillStationDetailsById: () => clearFillStationDetailsById()
};

export default fillStationActions;
