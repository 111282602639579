import { FILL_PROCESS } from "../../constants/action";
import {
  clearUpdatedDetails,
  clearAddedDetails,
  clearManuallyAssociateResponse,
  clearInitErrorCorrectionData,
  clearOtherDetails,
} from "./reducer";

const errorCorrectionActions = {
  initErrorCorrection: (payload: string) => {
    return {
      type: FILL_PROCESS.ERROR_CORRECTION.INIT_ERROR_CORRECTION,
      payload,
    };
  },
  manuallyAssociate: (payload: any) => {
    return {
      type: FILL_PROCESS.ERROR_CORRECTION.MANUALLY_ASSOCIATE,
      payload,
    };
  },
  getOtherDetails: (payload: any) => ({
    type: FILL_PROCESS.ERROR_CORRECTION.GET_PRODUCT_LIST,
    payload,
  }),
  updateOtherDetails: (payload: any) => ({
    type: FILL_PROCESS.ERROR_CORRECTION.UPDATE_OTHER_DETAILS,
    payload,
  }),
  addOtherDetails: (payload: any) => ({
    type: FILL_PROCESS.ERROR_CORRECTION.ADD_OTHER_DETAILS,
    payload,
  }),
  clearUpdatedDetails: () => clearUpdatedDetails(),
  clearAddedDetails: () => clearAddedDetails(),
  clearManuallyAssociateResponse: () => clearManuallyAssociateResponse(),
  clearInitErrorCorrectionData: () => clearInitErrorCorrectionData(),
  clearOtherDetails: () => clearOtherDetails(),
};

export default errorCorrectionActions;
