import { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Pagination,
	PaginationItem,
} from "@mui/material";
import { ViewSvg2 } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import { toast } from "react-toastify";
import moment from "moment";
import qs from "qs";
import {Clear} from '@mui/icons-material';

const {
	getWorkStationStatusList,
	getWorkGroupList,
	getRemainingWorkGroups,
	assignFillStationToWorkgroup,
	clearSetAssignFillStationToWorkgroup,
} = WorkGroupSelectionActions;

const WorkGroupSelection = () => {
	const fillStationStatus = useSelector(
		(state: any) => state?.workGroupSelection?.fillStationStatus
	);
	const workgroupsList = useSelector(
		(state: any) => state?.workGroupSelection?.workgroupsList
	);
	const remainingWorkGroups = useSelector(
		(state: any) => state?.workGroupSelection?.remainingWorkGroups
	);
	const assignFillStationToWorkgroupResponse = useSelector(
		(state: any) =>
			state?.workGroupSelection?.assignFillStationToWorkgroupResponse
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>("");
	const [page, setPage] = useState<number>(1);

	useEffect(() => {
		setLoading(true);
		dispatch(getWorkStationStatusList());
		dispatch(getRemainingWorkGroups());
	}, []);

	useEffect(() => {
		getWorkGroupListHandler();
	}, [page]);

	useEffect(() => {
		if (fillStationStatus) {
			if (!fillStationStatus?.success) {
				toast.error(fillStationStatus?.message);
			}
		}
	}, [fillStationStatus, dispatch]);

	useEffect(() => {
		if (workgroupsList) {
			setLoading(false);
			if (!workgroupsList?.success) {
				toast.error(workgroupsList?.message);
			}
		}
	}, [workgroupsList]);

	const handleSearch = () => {
		setPage(1);
		setLoading(true);
		getWorkGroupListHandler();
	};

  const clearSearchHandler = () => {
    setSearchText("");
    setLoading(true);
    setPage(1);
    const payload = {
			search: undefined,
			page: 1,
			limit: 15,
		};
		dispatch(getWorkGroupList(qs.stringify(payload)));
  }

	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	const getWorkGroupListHandler = () => {
		const payload = {
			search: searchText.trim() !== "" ? searchText.trim() : undefined,
			page: page,
			limit: 15,
		};
		dispatch(getWorkGroupList(qs.stringify(payload)));
	};

	const assignWorkGroupHandler = (
		workgroupId: React.ChangeEvent<HTMLSelectElement>,
		fillStationId: string
	) => {
		const userDetails = JSON.parse(localStorage.getItem("user") || "");
		setLoading(true);
		dispatch(
			assignFillStationToWorkgroup({
				workstation_id: fillStationId,
				workgroup_id: workgroupId.target.value,
				user_id: userDetails?.id || "",
			})
		);
	};

	useEffect(() => {
		if (assignFillStationToWorkgroupResponse) {
			setLoading(false);
			if (!assignFillStationToWorkgroupResponse?.success) {
				toast.error(assignFillStationToWorkgroupResponse?.message);
				dispatch(clearSetAssignFillStationToWorkgroup());
			} else if (assignFillStationToWorkgroupResponse?.success) {
				toast.success(assignFillStationToWorkgroupResponse?.data?.message);
				dispatch(clearSetAssignFillStationToWorkgroup());
				dispatch(getWorkStationStatusList());
				dispatch(getRemainingWorkGroups());
			}
		}
	}, [assignFillStationToWorkgroupResponse, dispatch]);

  useEffect(() => {
    const timer = setInterval(getWorkGroupListHandler, 20000);
    return () => clearInterval(timer);
  }, [searchText]);

	return (
		<section className="body-routing">
			<SimpleBackdropSpinner open={loading} />
			<div className="page-wrap">
				<div className="breadcrumb-block">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/fill_process/work_group_selection">
									Fill Process
								</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								Work Group Selection
							</li>
						</ol>
					</nav>
				</div>
				<div className="wgs-wrap">
					<div className="blue-card-panel">
						<div className="b-card-head">
							<h5>Fill Station Status</h5>
						</div>
						<div className="b-card-body pd10">
							<div className="fs-table-data cus-scroll table-responsive">
								<TableContainer>
									<Table className="table cus-gray-table table-center table-bordered">
										<TableHead>
											<TableRow>
												<TableCell align="center" className="p-0">
													Fill Station No.{" "}
												</TableCell>
												<TableCell align="center" className="p-0">
													Fill Station User
												</TableCell>
												<TableCell align="center" className="p-0">
													Pack Lane
												</TableCell>
												<TableCell align="center" className="p-0">
													Work Group Name
												</TableCell>
												<TableCell align="center" className="p-0">
													Availability Status
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{fillStationStatus &&
												fillStationStatus?.success &&
												fillStationStatus?.data?.response?.map(
													(item: any, index: number) => {
														return (
															<TableRow key={`"${index}`}>
																<TableCell align="center">{item?.id}</TableCell>
																<TableCell align="center">
																	{item.user}
																</TableCell>
																<TableCell align="center" className="text-end">
																	{item?.pack_lane}
																</TableCell>
																<TableCell align="center">
																	<select
																		value={item?.workgroup_id}
																		style={{ width: "50%" }}
																		onChange={(event) =>
																			assignWorkGroupHandler(event, item.id)
																		}
																	>
																		<option value="">Select</option>
																		{remainingWorkGroups && remainingWorkGroups?.data?.length && remainingWorkGroups?.data?.map(
																			(item: any) => {
																				return (
																					<option
																						key={item?.id}
																						value={item?.id}
																					>
																						{item?.name}
																					</option>
																				);
																			}
																		)}
																	</select>
																</TableCell>
																<TableCell align="center">
																	<span
																		className={
																			item?.status === "Occupied"
																				? "red-text"
																				: "green-text"
																		}
																	>
																		{item?.status}
																	</span>
																</TableCell>
															</TableRow>
														);
													}
												)}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</div>
					</div>
					<div className="filter-wrap mr-b10 mr-t10">
						<div className="row align-items-center">
							<div className="col-sm">
								<div className="filter-inputs">
									<input
										type="text"
										placeholder="Search by using the Work Group Name"
										value={searchText}
										onChange={(event) => setSearchText(event.target.value)}
										onKeyPress={(event) => {
											if (event.key === "Enter") {
												handleSearch();
											}
										}}
									/>
                  {searchText.trim().length ? <Clear style={{cursor: "pointer"}} onClick={clearSearchHandler}/> : null}
									<button
										type="button"
										className="search-btn"
										onClick={handleSearch}
									>
										<svg
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.30929 7.8174L11.5206 9.83183L10.79 10.4975L8.57925 8.4826C7.75666 9.08346 6.7335 9.41027 5.67923 9.40891C3.11429 9.40891 1.03259 7.51208 1.03259 5.17492C1.03259 2.83776 3.11429 0.940933 5.67923 0.940933C8.24417 0.940933 10.3259 2.83776 10.3259 5.17492C10.3274 6.13556 9.96871 7.06786 9.30929 7.8174ZM8.2736 7.46833C8.92884 6.85435 9.29476 6.03133 9.29328 5.17492C9.29328 3.35525 7.67574 1.88182 5.67923 1.88182C3.68221 1.88182 2.06518 3.35525 2.06518 5.17492C2.06518 6.99412 3.68221 8.46802 5.67923 8.46802C6.61911 8.46937 7.52234 8.13594 8.19616 7.53889L8.2736 7.46833Z"
												fill="white"
											></path>
										</svg>
										Search
									</button>
								</div>
							</div>
							<div className="col-sm">
								<div className="creat-btn text-end">
									<button
										type="button"
										className="border-blue-btn ml-auto pd-l20 pd-r20"
										data-bs-toggle="modal"
										data-bs-target="#addproductModal"
										onClick={() =>
											navigate(
												"/fill_process/work_group_selection/work_group_history"
											)
										}
									>
										Work Group History
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="wgs-data">
						<div className="cus-table max-h-table">
							<TableContainer>
								<Table className="table table-bordered table-center">
									<TableHead>
										<TableRow>
											<TableCell align="center" className="p-0">
												Work Group Name
											</TableCell>
											<TableCell align="center" className="p-0">
												Total Quantity
											</TableCell>
											<TableCell align="center" className="p-0">
												Remaining To Fill
											</TableCell>
											<TableCell align="center" className="p-0">
												Remaining To PV2
											</TableCell>
											<TableCell align="center" className="p-0">
												Remaining To Pack
											</TableCell>
											<TableCell align="center" className="p-0">
												Work Group Status
											</TableCell>
											<TableCell align="center" className="p-0">
												Work Group Initiated Date
											</TableCell>
											<TableCell align="center" className="p-0">
												Details
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{workgroupsList?.success &&
											workgroupsList?.data?.workgroupsData?.length ?
											(workgroupsList?.data?.workgroupsData?.map((item: any, index: number) => {
												return (
													<TableRow key={`${index}`}>
														<TableCell align="center" className="">
															{item?.wg_name}
														</TableCell>
														<TableCell align="center" className="text-end">
															{item?.total_quantity}
														</TableCell>
														<TableCell align="center" className="text-end">
															{item?.wg_fill_remain}
														</TableCell>
														<TableCell align="center" className="text-end">
															{item?.wg_pv2_remain}
														</TableCell>
														<TableCell align="center" className="text-end">
															{item?.wg_pack_remain}
														</TableCell>
														<TableCell align="center" className="">
															{item?.wg_status}
														</TableCell>
														<TableCell align="center" className="">
															{item?.wg_initiated_at &&
																moment(item?.wg_initiated_at?.slice(0, -1)).format("MM/DD/YYYY")}
														</TableCell>
														<TableCell align="center">
															<div className="action-btn">
																<button
																	type="button"
																	className="border-black-btn pd-l10 pd-r10"
																	onClick={() =>
																		navigate(
																			"/fill_process/work_group_selection/view_detail",
																			{
																				state: {
																					workGroupId: item.wg_id,
																				},
																			}
																		)
																	}
																>
																	<img src={ViewSvg2} alt="view" />
																	View
																</button>
															</div>
														</TableCell>
													</TableRow>
												);
											})) : (
                        <TableRow>
                          <TableCell colSpan={13} align="center">No records found</TableCell>
                        </TableRow>
                      )}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<nav
							aria-label="Page navigation example"
							className="cus-pagination mr-t10"
						>
							<ul className="pagination justify-content-center">
								<Pagination
									count={workgroupsList?.data?.total_pages}
									shape="rounded"
									boundaryCount={1}
									color="primary"
									page={page}
									onChange={handlePageChange}
									renderItem={(item) => (
										<PaginationItem className="pagination-item" {...item} />
									)}
								/>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WorkGroupSelection;
