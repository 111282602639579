import { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Pagination,
	PaginationItem,
} from "@mui/material";
import { AddImageSvg, ViewSvg } from "../../../assets/images";
import AddProductModal from "../../../components/addProductModal";
import AddProductImageModal from "../../../components/addProductImageModal";
import RxProductImageActions from "../../../redux/rx_product_image/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import qs from "qs";
import { setRxProductList } from "../../../redux/rx_product_image/reducer";
import {Clear} from '@mui/icons-material';

const {
	getProductList,
	clearDeleteProductResponse,
	clearUploadProductImageResponse,
	clearAddProductResponse,
  retrainModel,
  getLastRetrainedDetails,
  clearModelRetrainResponse
} = RxProductImageActions;

interface SelectedProduct {
	id: string;
	name: string;
}

const RxProductImage = () => {
	const productList = useSelector(
		(state: any) => state?.rxProductImage?.productList
	);

	const addProductResponse = useSelector(
		(state: any) => state?.rxProductImage?.addProductResponse
	);

	const deleteProductResponse = useSelector(
		(state: any) => state?.rxProductImage?.deleteProductResponse
	);

	const uploadProductImageRespone = useSelector(
		(state: any) => state?.rxProductImage?.uploadProductImageRespone
	);

  const lastRetrainedDetails = useSelector(
		(state: any) => state?.rxProductImage?.modelLastRetrained
	);

  const retrainModelResponse = useSelector(
		(state: any) => state?.rxProductImage?.modelTrainingResponse
	);



	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [columns] = useState<{ label: string }[]>([
		{ label: "Product ID" },
		{ label: "Product Name" },
		{ label: "No. of Images" },
		{ label: "Last Uploaded" },
		{ label: "Action" },
	]);

	const [searchText, setSearchText] = useState<string>("");
	const [page, setPage] = useState<number>(1);

	const [addProductModalFlag, setAddProductModalFlag] = useState<boolean>(
		false
	);
	const [addImageModalFlag, setAddImageModalFlag] = useState<boolean>(false);
	const [
		addProductImageProduct,
		setProductImageProduct,
	] = useState<SelectedProduct | null>(null);

	const [loading, setLoading] = useState<boolean>(false);

	const handleSearch = () => {
		setPage(1);
		getProductListHandler();
	};

  const clearSearchHandler = () => {
    setSearchText("");
    setLoading(true);
    setPage(1);
    const payload = {
			search: undefined,
			page: 1,
			limit: 15,
		};
		dispatch(getProductList(qs.stringify(payload)));
  }

	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	const getProductListHandler = () => {
		setLoading(true);
		const payload = {
			search: searchText.trim() !== "" ? searchText.trim() : undefined,
			page: page,
			limit: 15,
		};

		dispatch(getProductList(qs.stringify(payload)));
	};

	const productDetailHandler = (productId: string, imageCount: number) => {
		if (imageCount <= 0) {
			toast.info("Please add the images for the product to view.");
			return;
		}
		navigate(`/rx_product_image/config/${productId}`);
	};

  const handleRetrainModel = () => {
    setLoading(true);
    dispatch(retrainModel());
  }

	useEffect(() => {
		getProductListHandler();
    dispatch(getLastRetrainedDetails());
	}, [page]);

	useEffect(() => {
		if (productList) {
			setLoading(false);
			if (!productList.success) {
				toast.error(productList?.message);
			}
		}
	}, [productList]);

	useEffect(() => {
		if (addProductResponse) {
			if (addProductResponse?.success) {
				toast.success(addProductResponse?.data?.message);
				getProductListHandler();
				dispatch(clearAddProductResponse());
				setAddProductModalFlag(false);
			} else if (!addProductResponse?.success) {
				toast.error(addProductResponse?.message);
				dispatch(clearAddProductResponse());
				setAddProductModalFlag(false);
			}
		}
	}, [addProductResponse, dispatch]);

	useEffect(() => {
		if (deleteProductResponse) {
			setLoading(false);
			if (deleteProductResponse?.success) {
				toast.success(deleteProductResponse?.data?.message);
				getProductListHandler();
				setPage(1);
				dispatch(clearDeleteProductResponse());
			} else if (!deleteProductResponse?.success) {
				toast.error(deleteProductResponse?.message);
				dispatch(clearDeleteProductResponse());
			}
		}
	}, [deleteProductResponse, dispatch]);

	useEffect(() => {
		if (uploadProductImageRespone) {
			if (uploadProductImageRespone?.success) {
				toast.success(uploadProductImageRespone?.data?.message);
				dispatch(clearUploadProductImageResponse());
				setAddImageModalFlag(false);
				getProductListHandler();
			} else if (!uploadProductImageRespone?.success) {
				toast.error(uploadProductImageRespone?.message);
        setAddImageModalFlag(false);
				dispatch(clearUploadProductImageResponse());
			}
		}
	}, [uploadProductImageRespone, dispatch]);

  useEffect(() => {
		if (retrainModelResponse) {
			if (retrainModelResponse?.success) {
				toast.success(retrainModelResponse?.data?.message);
        setLoading(false);
			} else if (!retrainModelResponse?.success) {
				toast.error(retrainModelResponse?.message);
        setLoading(false)
			}
		}
	}, [retrainModelResponse]);

	useEffect(() => {
		return () => {
			dispatch(setRxProductList(undefined));
      dispatch(clearModelRetrainResponse());
		};
	}, [dispatch]);

	return (
		<section className="body-routing">
			<SimpleBackdropSpinner open={loading} />
			<div className="page-wrap">
				<div className="page-head-title d-flex align-items-center justify-content-between mr-b20">
					<h3>Rx Product Image</h3>
					<h5>Model Last Trained : {lastRetrainedDetails?.data?.updated_at ? moment(lastRetrainedDetails?.data?.updated_at).tz(moment.tz.guess()).format("MMMM DD, YYYY | hh:mm a z") : "NA"}</h5>
				</div>
			</div>
			<div className="filter-wrap mr-b10">
				<div className="row align-items-center">
					<div className="col-sm">
						<div className="filter-inputs">
							<input
								type="text"
								placeholder="Search by Product ID, Product Name"
								value={searchText}
								onChange={(event) => setSearchText(event.target.value)}
								onKeyPress={(event) => {
									if (event.key == "Enter") {
										handleSearch();
									}
								}}
							/>
              {searchText.trim().length ? <Clear style={{cursor: "pointer"}} onClick={clearSearchHandler}/> : null}

							<button
								type="button"
								className="search-btn"
								onClick={handleSearch}
							>
								<svg
									width="13"
									height="12"
									viewBox="0 0 13 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9.30929 7.8174L11.5206 9.83183L10.79 10.4975L8.57925 8.4826C7.75666 9.08346 6.7335 9.41027 5.67923 9.40891C3.11429 9.40891 1.03259 7.51208 1.03259 5.17492C1.03259 2.83776 3.11429 0.940933 5.67923 0.940933C8.24417 0.940933 10.3259 2.83776 10.3259 5.17492C10.3274 6.13556 9.96871 7.06786 9.30929 7.8174ZM8.2736 7.46833C8.92884 6.85435 9.29476 6.03133 9.29328 5.17492C9.29328 3.35525 7.67574 1.88182 5.67923 1.88182C3.68221 1.88182 2.06518 3.35525 2.06518 5.17492C2.06518 6.99412 3.68221 8.46802 5.67923 8.46802C6.61911 8.46937 7.52234 8.13594 8.19616 7.53889L8.2736 7.46833Z"
										fill="white"
									></path>
								</svg>
								Search
							</button>
						</div>
					</div>
					<div className="col-sm">
						<div className="creat-btn text-end">
							<button
								type="button"
								className="blue-btn ml-auto pd-l30 pd-r30 mr-l10"
                onClick={handleRetrainModel}
							>
								Retrain Model
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="filter-result">
				<div className="t-data-wrap">
					<div className="cus-table max-h-table">
						<TableContainer>
							<Table className="table table-bordered table-center">
								<TableHead>
									<TableRow>
										{columns.map((column) => {
											return (
												<TableCell align="center" key={`"${column.label}}"`}>
													{column.label}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>

								<TableBody>
									{productList?.data?.response?.productData?.length ? (
										productList?.data?.response?.productData?.map(
											(product: any) => {
												return (
													<TableRow key={`"${product.product_id}"`}>
														<TableCell align="center">
															{product.product_id}
														</TableCell>
														<TableCell align="center">
															{product.description}
														</TableCell>
														<TableCell className="text-end">
															{product.images_count}
														</TableCell>
														<TableCell align="center">
															{product.updated_at
																? moment(product.updated_at).tz(moment.tz.guess()).format(
																		"MM/DD/YYYY hh:mm A z"
																  )
																: "NA"}
														</TableCell>
														<TableCell align="center">
															<div className="blank-btn text-center">
																<button
																	type="button"
																	className="blank-btn"
																	onClick={() => {
																		setAddImageModalFlag(true);
																		setProductImageProduct({
																			id: product.product_id,
																			name: product.description,
																		});
																	}}
																>
																	<img src={AddImageSvg} alt="" title="" />
																</button>
																<button
																	type="button"
																	className="blank-btn"
																	onClick={() =>
																		productDetailHandler(
																			product.product_id,
																			Number(product?.images_count)
																		)
																	}
																>
																	<img src={ViewSvg} alt="" title="" />
																</button>
															</div>
														</TableCell>
													</TableRow>
												);
											}
										)
									) : (
										<TableRow>
											<TableCell align="center" colSpan={7}>
												No Product Found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<nav
						aria-label="Page navigation example"
						className="cus-pagination mr-t10"
					>
						<ul className="pagination justify-content-center">
							<Pagination
								count={productList?.data?.response?.total_pages}
								shape="rounded"
								boundaryCount={1}
								color="primary"
								page={page}
								onChange={handlePageChange}
								renderItem={(item) => (
									<PaginationItem className="pagination-item" {...item} />
								)}
							/>
						</ul>
					</nav>
				</div>
			</div>
			{addProductModalFlag ? (
				<AddProductModal
					open={addProductModalFlag}
					closeModal={() => setAddProductModalFlag(false)}
				/>
			) : null}

			{addImageModalFlag ? (
				<AddProductImageModal
					open={addImageModalFlag}
					closeModal={() => setAddImageModalFlag(false)}
					product={addProductImageProduct}
				/>
			) : null}
		</section>
	);
};

export default RxProductImage;
