import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    setLoginRespons: (state, action) => {
      return { ...state, loginResponse: action.payload };
    },
    setValidateWorkstationResponse: (state, action) => {
      return { ...state, validateWorkStationResponse: action.payload };
    },
    setWorkstationsWithIpResponse: (state, action) => {
      return { ...state, workstationsWithIpResponse: action.payload };
    },
    setLogoutResponse: (state, action) => {
      return { ...state, logoutResponse: action.payload };
    },
    clearValidateWorkstationResponse: (state) => {
      return { ...state, validateWorkStationResponse: undefined };
    },
    clearLoginResponse: (state) => {
      return { ...state, loginResponse: undefined };
    },
    clearLogoutResponse: (state) => {
      return { ...state, logoutResponse: undefined };
    },
  },
});

export const {
  setLoginRespons,
  setValidateWorkstationResponse,
  setWorkstationsWithIpResponse,
  setLogoutResponse,
  clearValidateWorkstationResponse,
  clearLoginResponse,
  clearLogoutResponse,
} = authSlice.actions;

export default authSlice.reducer;
