import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LogoFFF2 } from "../../assets/images";
import AuthActions from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { SimpleBackdropSpinner } from "../../components/loaders";
import DuplicateSessionAlertModal from "../../components/duplicateSessionAlertModal";
const { login, clearLoginResponse } = AuthActions;

const Login = ({}) => {
  const loginResponse: any = useSelector(
    (state: any) => state?.auth?.loginResponse
  );

  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [duplicateSessionAlertModalFlag, setDuplicateSessionAlertModalFlag] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    //Redirect to homepage if already logged in
    isLoggedIn() && navigate("/");
  }, []);

  const isLoggedIn = () => {
    return localStorage.getItem("token") ? true : false;
  };

  const handleOnClickLogin = () => {
    if (!userName || !userName.trim().length) {
      toast.error("Please enter username");
      return;
    }
    if (!userPassword || !userPassword.trim().length) {
      toast.error("Please enter password");
      return;
    }
    const payload = {
      username: userName,
      password: userPassword,
      isTokenGeneration: false,
    };
    dispatch(login(payload));
    setLoading(true);
  };

  useEffect(() => {
    if (loginResponse) {
      setLoading(false);
      if (loginResponse?.status === 409) {
        setDuplicateSessionAlertModalFlag(true);
      }
      if (loginResponse?.success) {
        navigate("/");
      } else if (!loginResponse?.success) {
        toast.error(loginResponse?.data || loginResponse?.data?.message);
      }
      clearLoginResponse();
    }
  }, [loginResponse, navigate]);

  const forcedLogin = () => {
    const payload = {
      username: userName,
      password: userPassword,
      isTokenGeneration: true,
    };
    dispatch(login(payload));
    setLoading(true);
  };

  return (
    <section className="login-wrap">
      <SimpleBackdropSpinner open={loading} />
      <DuplicateSessionAlertModal
        open={duplicateSessionAlertModalFlag}
        closeModal={() => setDuplicateSessionAlertModalFlag(false)}
        forcedLogin={forcedLogin}
      />
      <div className="login-cell">
        <div className="login-block">
          <div className="l-logo-block text-center">
            <img src={LogoFFF2} alt="nufactor" title="nufactor" />
          </div>
          <div className="auth-form mr-t30">
            <form action="">
              <div className="form-group login-in-group mr-b20">
                <label>User Name</label>
                <input
                  type="email"
                  className="form-control cus-form-control"
                  placeholder="Enter user name"
                  value={userName}
                  onChange={(event) => setUserName(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key == "Enter") {
                      handleOnClickLogin();
                    }
                  }}
                />
              </div>
              <div className="form-group login-in-group mr-b20">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control cus-form-control"
                  placeholder="Enter password"
                  value={userPassword}
                  onChange={(event) => setUserPassword(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key == "Enter") {
                      handleOnClickLogin();
                    }
                  }}
                />
              </div>
              {/* <div className="l-bottomlinks d-flex justify-content-between align-items-center">
                <div className="cus-check-box">
                  <label className="d-flex">
                    <input type="checkbox" name="" id="tc" />
                    <span className="mr-r10"></span>
                    Remember me
                  </label>
                </div>
                <div className="f-link text-end">
                  <Link to="/change_password">Request new password</Link>
                </div>
              </div> */}
              <button
                type="button"
                className="l-btn"
                onClick={handleOnClickLogin}
              >
                SIGN IN
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="l-foot-links mr-t20">
        <ul className="mx-auto">
          <li>
            <a href="#" style={{ cursor: "default", pointerEvents: "none" }}>
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href="#" style={{ cursor: "default", pointerEvents: "none" }}>
              Privacy Policy
            </a>
          </li>
          {/* <li>
            <a
              href="https://www.winjit.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Powered by Winjit
            </a>
          </li> */}
        </ul>
        <p>&copy; Copyright 2022. All rights reserved</p>
      </div>
    </section>
  );
};

// const mapStateToProps = (state: RootState) => ({});
// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(Login);
export default Login;
