import { Modal } from "@mui/material";
import { useState } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { useDispatch } from "react-redux";
import RxProductImageActions from "../redux/rx_product_image/actions";
import { Spinner } from "./loaders";
interface ProductImageModal {
	open: boolean;
	closeModal: () => void;
	product: { name: string; id: string } | null;
}

const fileTypeValidationHandler = (file: any) => {
	if (RegExp(/^.*\.(gif|GIF|doc|DOC|pdf|PDF|xls|xlsx|csv)$/).test(file.name)) {
		return {
			code: "wrong-file-type",
			message: `Select an image file`,
		};
	}

	return null;
};

const { uploadProductImage } = RxProductImageActions;

const AddProductImageModal: React.FC<ProductImageModal> = (props) => {
	const dispatch = useDispatch();

	const [productId, setProductId] = useState<string>(
		props.product ? props.product.id : ""
	);
	const [productName, setProductName] = useState<string>(
		props.product ? props.product.name : ""
	);
	const [loader, setLoader] = useState<boolean>(false);
	const [formError, setFormError] = useState<string>("");

	const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === "prod-name") {
			setProductName(value);
		}
		if (name === "prod-id") {
			setProductId(value);
		}
	};

	const { acceptedFiles, open, getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/jpeg": [],
			"image/png": [],
			"image/jpg": [],
			"image/JPEG": [],
			"image/PNG": [],
			"image/JPG": [],
		},
		noClick: true,
		noKeyboard: true,
		validator: fileTypeValidationHandler,
		multiple: true,
	});

	const uploadImagesHandler = () => {
		if (!acceptedFiles.length) {
			if (!acceptedFiles.length) {
				setFormError("Please select an image file");
			}
			return;
		}
		setFormError("");
		setLoader(true);
		const files = new FormData();
		acceptedFiles.map((file: FileWithPath) => {
			files.append("product_file", file);
			return file;
		});

		const tempData = {
			product_id: productId,
		};
		files.append("data", JSON.stringify(tempData));
		dispatch(uploadProductImage(files));
	};

	return (
		<Modal open={props.open}>
			<div
				className="modal cus-modal fade show"
				id="addproductimgModal"
				tabIndex={-1}
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal360 modal-dialog-centered modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								Add Product Image
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={props.closeModal}
							>
								<svg
									width="8"
									height="8"
									viewBox="0 0 8 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
										fill="white"
									/>
								</svg>
							</button>
						</div>
						<div className="modal-body eu-reference-body">
							<form action="" className="row">
								<div className="col-sm-6">
									<div className="form-group cus-group mr-b15">
										<label htmlFor="" className="inp-lable-title">
											Product ID
										</label>
										<input
											type="text"
											className="form-control cus-control"
											name="prod-id"
											id=""
											value={productId}
											onChange={textChange}
											disabled
											readOnly
										/>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-group cus-group mr-b15">
										<label htmlFor="" className="inp-lable-title">
											Product Name
										</label>
										<input
											type="text"
											className="form-control cus-control"
											name="prod-name"
											id=""
											value={productName}
											onChange={textChange}
											disabled
											readOnly
										/>
									</div>
								</div>
								<div className="col-sm-12">
									<div className="file-drag-box">
										<div className="form-group cus-group">
											<label htmlFor="" className="inp-lable-title">
												Product Image
											</label>
											<div className="drag-box" {...getRootProps()}>
												<input {...getInputProps()} />
												<div className="drag-svg mr-b10">
													<svg
														width="38"
														height="24"
														viewBox="0 0 38 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M30.6566 10.1297C30.6566 10.0312 30.673 9.93281 30.673 9.83438C30.673 4.50234 26.4238 0.1875 21.182 0.1875C17.4004 0.1875 14.152 2.43516 12.6262 5.68359C11.9617 5.34727 11.2152 5.15039 10.4277 5.15039C8.00781 5.15039 5.98984 6.94688 5.6043 9.29297C2.70039 10.2937 0.625 13.0746 0.625 16.3477C0.625 20.4656 3.91445 23.8125 7.9668 23.8125H16.375V17.25H12.4211L19 10.384L25.5789 17.2418H21.625V23.8043H30.673C34.3809 23.8043 37.375 20.7281 37.375 16.9629C37.375 13.1977 34.3645 10.1379 30.6566 10.1297Z"
															fill="#46AEFD"
														/>
													</svg>
												</div>
												<h5>
													Drag and drop here
													<span>or</span>
													<button
														type="button"
														className="trans-btn"
														onClick={open}
													>
														Browse
													</button>
												</h5>
											</div>
										</div>
									</div>
									{formError !== "" ? (
										<div className="form-error-msg">{formError}</div>
									) : null}
									<p className="files-count">
										No. of Files selected: {acceptedFiles.length}
									</p>
								</div>
							</form>
						</div>
						<div className="modal-footer border-0">
							{loader ? (
								<button
									type="button"
									className="blue-btn pd-l20 pd-r20"
									data-bs-dismiss="modal"
								>
									<Spinner />
								</button>
							) : (
								<button
									type="button"
									className="blue-btn pd-l20 pd-r20"
									data-bs-dismiss="modal"
									onClick={uploadImagesHandler}
								>
									<svg
										className="mr-r10"
										width="10"
										height="10"
										viewBox="0 0 10 10"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M4.36209 0H5.19542C5.2695 0 5.30653 0.037037 5.30653 0.111111V9.88889C5.30653 9.96296 5.2695 10 5.19542 10H4.36209C4.28801 10 4.25098 9.96296 4.25098 9.88889V0.111111C4.25098 0.037037 4.28801 0 4.36209 0Z"
											fill="white"
										/>
										<path
											d="M0.111111 4.47266H9.44444C9.51852 4.47266 9.55556 4.50969 9.55556 4.58377V5.4171C9.55556 5.49117 9.51852 5.52821 9.44444 5.52821H0.111111C0.037037 5.52821 0 5.49117 0 5.4171V4.58377C0 4.50969 0.037037 4.47266 0.111111 4.47266Z"
											fill="white"
										/>
									</svg>
									Add Product Image
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default AddProductImageModal;
