import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LogoFFF2 } from "../../assets/images";
// import AuthActions from "../../redux/auth/actions";
import { useSelector } from "react-redux";
import { SimpleBackdropSpinner } from "../../components/loaders";

// const { changePassword } = AuthActions;

const ChangePassword = () => {

	const changePasswordResponse = useSelector((state: any) => state?.auth?.changePasswordResponse);
  // const dispatch = useDispatch();
  
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  const handleOnClickChangePassword = () => {
    if (!userName || !userName.trim().length) {
    toast.error("Please enter username");
      return;
    }
    
    setLoading(true);
    // const payload = {
    //   username: userName,
    // };

    // dispatch(changePassword(payload));
  };

  useEffect(() => {
		if (changePasswordResponse) {
			setLoading(false);
			if (changePasswordResponse?.success) {
				toast.success(changePasswordResponse?.message);
				navigate("/login");
			} else if (!changePasswordResponse?.success) {
				toast.error(changePasswordResponse?.message);
			}
		}
	}, [changePasswordResponse, navigate]);

  return (
    <section className="login-wrap">
      <SimpleBackdropSpinner open={loading}/>
      <div className="login-cell">
        <div className="login-block">
          <div className="l-logo-block text-center">
            <img src={LogoFFF2} alt="nufactor" title="nufactor" />
          </div>
          <div className="auth-form mr-t30">
            <form action="">
              <h3 className="text-center">Request New Password</h3>
              <h5 className="text-center mr-t10 mr-b20">
                Enter your registered email address below to <br /> receive
                password reset instruction
              </h5>
              <div className="form-group login-in-group mr-b20">
                <label>User Name</label>
                <input
                  type="email"
                  className="form-control cus-form-control"
                  placeholder="Enter user name"
                  value={userName}
                  onChange={(event) => setUserName(event.target.value)}
                />
              </div>
              <p className="text-center">
                A new <span>password creation link</span> has been sent to your{" "}
                <br /> <span>registered email address</span>. Please verify and
                create new <br /> password.
              </p>
              <button
                type="button"
                className="l-btn"
                onClick={handleOnClickChangePassword}
              >
                Send
              </button>
            </form>
            <div className="link-container">
            <Link to="/login" className="link-style">
              Back to Login
            </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="l-foot-links mr-t20">
        <ul className="mx-auto">
          <li>
            <a href="#" style={{cursor: "default", pointerEvents: "none"}}>Terms & Conditions</a>
          </li>
          <li>
            <a href="#" style={{cursor: "default", pointerEvents: "none"}}>Privacy Policy</a>
          </li>
          {/* <li>
            <a href="https://www.winjit.com" target="_blank" rel="noopener noreferrer">Powered by Winjit</a>
          </li> */}
        </ul>
        <p>&copy; Copyright 2022. All rights reserved</p>
      </div>
    </section>
  );
};

export default ChangePassword;
