import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../../components/loaders";
import SuccessModal from "../../components/successModal";
import RphAssignmentActions from "../../redux/rph_assignment/actions";
import qs from "qs";

const { assignRph, clearAssignment } = RphAssignmentActions;

const RphAssignment = () => {
	const dispatch = useDispatch();

	const rphAssignmentResponse = useSelector(
		(state: any) => state?.rphAssignment?.rphAssignmentResponse
	);

	const [userDetailsState, setUserDetailsState] = useState<any>();

	useEffect(() => {
		let details: any = JSON.parse(localStorage.getItem("user") || "");
		setUserDetailsState(details);
	}, []);

	const [loading, setLoading] = useState<boolean>(false);
	const [selected, setSelected] = useState<boolean>(false);
	const [successModalFlag, setSuccessModalFlag] = useState<boolean>(false);

	const submitHandler = () => {
		setLoading(true);
		const userDetails = JSON.parse(localStorage.getItem("user") || "");
		const payload = {
			user_id: userDetails?.id || "",
		};
		dispatch(assignRph(qs.stringify(payload)));
	};

	useEffect(() => {
		if (rphAssignmentResponse) {
			setLoading(false);
			if (!rphAssignmentResponse?.success) {
				toast.error(rphAssignmentResponse?.message);
			} else if (rphAssignmentResponse?.success) {
				setSelected(false);
				setSuccessModalFlag(true);
				dispatch(clearAssignment());
			}
		}
	}, [rphAssignmentResponse, dispatch]);
	return (
		<section className="body-routing">
			<SimpleBackdropSpinner open={loading} />
			<div className="page-wrap">
				<div className="breadcrumb-block mode-row">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active" aria-current="page">
								RPh Assignment
							</li>
						</ol>
					</nav>
				</div>

				<div className="error-correction-wrap">
					<div className="blue-card-panel">
						<div className="b-card-body checkbox-grey">
							<div className="error-alert checkbox-alert">
								<div className="error-con-box checkbox-wrph">
									<input
										type="checkbox"
										checked={selected}
										onChange={() => setSelected(!selected)}
									/>
									<h5>{`Assign ${userDetailsState?.firstName} ${userDetailsState?.lastName} as RPh`}</h5>
								</div>
								{selected ? (
									<div className="checkbox-submit">
										<button
											data-bs-toggle="modal"
											data-bs-target="#sucmsgModal"
											onClick={submitHandler}
										>
											Submit
										</button>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
			<SuccessModal
				open={successModalFlag}
				closeModal={() => setSuccessModalFlag(false)}
				title="Assigned as RPh"
				message="The user is successfully assigned as RPh for all the future order of the day"
			/>
		</section>
	);
};

export default RphAssignment;
