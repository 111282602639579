import api from "../../api";

export const initErrorCorrection_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/init-error-correction?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const manuallyAssociate_api = async (payload: any) => {
  try {
    let response = await api.post(`/fill/error-correction-manually-associate`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};