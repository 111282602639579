import { Modal } from "@mui/material";
import Pv2Actions from "../redux/fill_process/pv2_station/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";

const { updatePv2Verification } = Pv2Actions;

interface Pv2FailureModalInterface {
  open: boolean;
  closeModal: () => void;
  failReasons: any;
  rxId: string;
}

const Pv2FailureModal: React.FC<Pv2FailureModalInterface> = ({
  open,
  closeModal,
  failReasons,
  rxId,
}) => {
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [formError, setFormError] = useState<string>("");

  const submitHandler = () => {
    if (selectedValue === "") {
      setFormError("Please select a reason");
      return;
    }
    const userDetails = JSON.parse(localStorage.getItem("user") || "");
    const payload = {
      rxId: rxId,
      is_failed: true,
      fail_reason_id: selectedValue,
      user_id: userDetails?.id || "",
    };
    dispatch(updatePv2Verification(payload));
    setSelectedValue("");
    setFormError("");
  };

  const onClose = () => {
    setSelectedValue("");
    setFormError("");
    closeModal();
  };

  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="pfrFormModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal360 modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                PV2 Failure Reason
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onClose()}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body eu-reference-body">
              <form action="" className="row">
                <div className="col-sm-12">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Please select the valid reason for Tote Failure
                    </label>
                    <select
                      name=""
                      className="form-control cus-control w-50"
                      id=""
                      onChange={(e) => {
                        setSelectedValue(e.target.value);
                        setFormError("");
                      }}
                    >
                      <option value="">Select</option>
                      {failReasons?.data?.response?.map((item: any) => {
                        return (
                          <option value={item.id}>{item.description}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </form>
              {formError !== "" ? (
                <div className="form-error-msg" style={{ marginTop: "-3%" }}>
                  {formError}
                </div>
              ) : null}
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="blue-btn pd-l30 pd-r30"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#snapAlertgModal"
                onClick={submitHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Pv2FailureModal;
