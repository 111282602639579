import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const userWorkStationRefSlice = createSlice({
	name: "userWorkStationRef",
	initialState: initState,
	reducers: {
		setWorkStationList: (state, action) => {
			return { ...state, workStationList: action.payload };
		},
		setUpdateWorkstationResponse: (state, action) => {
			return { ...state, updateWorkStationResponse: action.payload };
		},
		setDeleteWorkstationResponse: (state, action) => {
			return { ...state, deleteWorkStationResponse: action.payload };
		},
		clearUpdateResponse: (state) => {
			return { ...state, updateWorkStationResponse: undefined };
		},
		clearDeleteResponse: (state) => {
			return { ...state, deleteWorkStationResponse: undefined };
		},
	},
});

export const {
	setWorkStationList,
	setUpdateWorkstationResponse,
	setDeleteWorkstationResponse,
	clearUpdateResponse,
	clearDeleteResponse,
} = userWorkStationRefSlice.actions;

export default userWorkStationRefSlice.reducer;
