import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DeleteSvg, EditSvg } from "../../assets/images";
import EditUserWSRefModal from "../../components/editUserWSRefModal";
import DeleteModal from "../../components/deleteModal";
import UserWorkStationRefActions from "../../redux/user_work_station_ref/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../../components/loaders";
import qs from "qs";
import { setWorkStationList } from "../../redux/user_work_station_ref/reducer";
import {Clear} from '@mui/icons-material';

const {
  getUserWorkStationList,
  deleteUserWorkstation,
  clearDeleteWorkstationResponse,
  clearUpdateWorkstationResponse,
} = UserWorkStationRefActions;

const UserWorkStationRef = () => {
  const workStationRefList = useSelector(
    (state: any) => state?.userWorkStationRef?.workStationList
  );
  const deleteWorkStationResponse = useSelector(
    (state: any) => state?.userWorkStationRef?.deleteWorkStationResponse
  );

  const updateWorkStationResponse = useSelector(
    (state: any) => state?.userWorkStationRef?.updateWorkStationResponse
  );

  const dispatch = useDispatch();

  const [columns] = useState<{ label: string }[]>([
    { label: "Work Station ID" },
    { label: "Work Station Description" },
    { label: "Work Station Status" },
    { label: "Work Station Auto" },
    { label: "Work Station Assigned" },
    { label: "Work Station Timeout" },
    { label: "Work Station Idle Time" },
    { label: "Action" },
  ]);

  const [searchText, setSearchText] = useState<string>("");

  const [editModalFlag, setEditModalFlag] = useState<boolean>(false);
  const [deleteModalFlag, setDeleteModalFlag] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [editElement, setEditElement] = useState("");
  const [deleteElementId, setDeleteElementId] = useState<string>("");

  const handleSearch = () => {
    getWorkstationListHandler();
  };

  const clearSearchHandler = () => {
    setSearchText("");
    setLoading(true);
    const payload = {
			search: undefined
		};
		dispatch(getUserWorkStationList(qs.stringify(payload)));
  }

  const deleteHandler = () => {
    setLoading(true);
    dispatch(deleteUserWorkstation(deleteElementId));
    setDeleteModalFlag(false);
  };

  const getWorkstationListHandler = () => {
    setLoading(true);
    const payload = {
      search: searchText.trim() !== "" ? searchText.trim() : undefined,
    };
    dispatch(getUserWorkStationList(qs.stringify(payload)));
  };

  useEffect(() => {
    getWorkstationListHandler();
  }, []);

  useEffect(() => {
    if (workStationRefList) {
      setLoading(false);
      if (!workStationRefList?.success) {
        toast.error(workStationRefList?.message);
      }
    }
  }, [workStationRefList]);

  useEffect(() => {
    if (deleteWorkStationResponse) {
      setLoading(false);
      if (deleteWorkStationResponse?.success) {
        toast.success(deleteWorkStationResponse?.data?.message);
        dispatch(clearDeleteWorkstationResponse());
        getWorkstationListHandler();
      } else if (!deleteWorkStationResponse?.success) {
        toast.error(deleteWorkStationResponse?.message);
        dispatch(clearDeleteWorkstationResponse());
        getWorkstationListHandler();
      }
    }
  }, [deleteWorkStationResponse, dispatch]);

  useEffect(() => {
    if (updateWorkStationResponse) {
      setLoading(false);
      if (updateWorkStationResponse?.success) {
        toast.success(updateWorkStationResponse?.data?.message);
        dispatch(clearUpdateWorkstationResponse());
        setEditModalFlag(false);
        getWorkstationListHandler();
      } else if (!updateWorkStationResponse?.success) {
        toast.error(updateWorkStationResponse?.message);
        dispatch(clearUpdateWorkstationResponse());
        setEditModalFlag(false);
        getWorkstationListHandler();
      }
    }
  }, [updateWorkStationResponse, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setWorkStationList(undefined));
    };
  }, [dispatch]);

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={loading} />
      <div className="page-wrap">
        <div className="page-head-title d-flex align-items-center justify-content-between mr-b20">
          <h3>User Work Station Ref</h3>
        </div>
      </div>
      <div className="filter-wrap mr-b10">
        <div className="row align-items-center">
          <div className="col-sm">
            <div className="filter-inputs">
              <input
                type="text"
                placeholder="Search by Work Station ID, Work Station Description"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key == "Enter") {
                    handleSearch();
                  }
                }}
              />
              {searchText.trim().length ? <Clear style={{cursor: "pointer"}} onClick={clearSearchHandler}/> : null}

              <button
                type="button"
                className="search-btn"
                onClick={handleSearch}
              >
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.30929 7.8174L11.5206 9.83183L10.79 10.4975L8.57925 8.4826C7.75666 9.08346 6.7335 9.41027 5.67923 9.40891C3.11429 9.40891 1.03259 7.51208 1.03259 5.17492C1.03259 2.83776 3.11429 0.940933 5.67923 0.940933C8.24417 0.940933 10.3259 2.83776 10.3259 5.17492C10.3274 6.13556 9.96871 7.06786 9.30929 7.8174ZM8.2736 7.46833C8.92884 6.85435 9.29476 6.03133 9.29328 5.17492C9.29328 3.35525 7.67574 1.88182 5.67923 1.88182C3.68221 1.88182 2.06518 3.35525 2.06518 5.17492C2.06518 6.99412 3.68221 8.46802 5.67923 8.46802C6.61911 8.46937 7.52234 8.13594 8.19616 7.53889L8.2736 7.46833Z"
                    fill="white"
                  ></path>
                </svg>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-result">
        <div className="t-data-wrap">
          <div className="cus-table max-h-table">
            <TableContainer>
              <Table className="table table-bordered table-center">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell align="center" key={`"${column.label}}"`}>
                          {column.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {workStationRefList?.data?.response?.length ? (
                    workStationRefList?.data?.response?.map((ref: any) => {
                      return (
                        <TableRow key={`"${ref.workstation_id}"`}>
                          <TableCell align="center">
                            {ref.workstation_id}
                          </TableCell>
                          <TableCell align="center">
                            {ref.description}
                          </TableCell>
                          <TableCell className="text-end">
                            {ref.status}
                          </TableCell>
                          <TableCell align="center">
                            {ref.auto ? "1 (Default)" : "0"}
                          </TableCell>
                          <TableCell className="text-end">
                            {ref.assign ? "1" : "0"}
                          </TableCell>
                          <TableCell align="center">
                            {ref.timeout ? "Yes" : "No"}
                          </TableCell>
                          <TableCell className="text-end">
                            {`${ref.idle_time} sec`}
                          </TableCell>
                          <TableCell align="center">
                            <div className="blank-btn text-center">
                              <button
                                type="button"
                                className="blank-btn"
                                onClick={() => {
                                  setEditModalFlag(true);
                                  setEditElement(ref);
                                }}
                              >
                                <img src={EditSvg} alt="" title="" />
                              </button>
                              <button
                                type="button"
                                className="blank-btn"
                                onClick={() => {
                                  setDeleteModalFlag(true);
                                  setDeleteElementId(ref.workstation_id);
                                }}
                              >
                                <img src={DeleteSvg} alt="" title="" />
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {editModalFlag ? (
        <EditUserWSRefModal
          open={editModalFlag}
          closeModal={() => setEditModalFlag(false)}
          data={editElement}
        />
      ) : null}
      {deleteModalFlag ? (
        <DeleteModal
          open={deleteModalFlag}
          closeModal={() => setDeleteModalFlag(false)}
          title="Delete User Work Station Reference"
          message="Are you sure you want to delete this User from Work Station Reference?"
          confirmDelete={deleteHandler}
        />
      ) : null}
    </section>
  );
};

export default UserWorkStationRef;
