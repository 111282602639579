import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PrintFillListModal from "../../../components/printFillListModal";
import WorkGroupSelectionModal from "../../../components/workGroupSelectionModal";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../../../components/loaders";

const { getWorkGroupData, getWorkGroupMaterial, clearSetWorkgroupsData } =
  WorkGroupSelectionActions;

const ViewDetail = () => {
  const workgroupsData = useSelector(
    (state: any) => state?.workGroupSelection?.workgroupsData
  );
  const workgroupsMaterial = useSelector(
    (state: any) => state?.workGroupSelection?.workgroupsMaterial
  );
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [printFillListModalFlag, setPrintFillListModalFlag] =
    useState<boolean>(false);
  const [wGSModalFlag, setWGSModalFlag] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearSetWorkgroupsData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.workGroupId) {
      setLoading(true);
      dispatch(getWorkGroupData(location.state.workGroupId));
      dispatch(getWorkGroupMaterial(location.state.workGroupId));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (workgroupsData) {
      if (!workgroupsData?.success) {
        toast.error(workgroupsData?.message);
      }
    }
  }, [workgroupsData]);

  useEffect(() => {
    if (workgroupsMaterial) {
      setLoading(false);
      if (!workgroupsMaterial?.success) {
        toast.error(workgroupsMaterial?.message);
      }
    }
  }, [workgroupsMaterial]);

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={loading} />
      <div className="page-wrap">
        <div className="breadcrumb-block">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/fill_process/work_group_selection">
                  Fill Process
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/fill_process/work_group_selection">
                  Work Group Selection
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                View Detail
              </li>
            </ol>
          </nav>
        </div>
        <div className="wgs-view-wrap">
          <div className="static-info-card mr-b20">
            <div className="row">
              <div className="col-sm">
                <div className="static-info">
                  <h5>Work Group Name</h5>
                  <span>{workgroupsData?.data?.[0]?.name}</span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Total</h5>
                  <span>{workgroupsData?.data?.[0]?.quantity}</span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Remaining to Fill</h5>
                  <span>{workgroupsData?.data?.[0]?.fill_remaining}</span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Remaining to PV2</h5>
                  <span>{workgroupsData?.data?.[0]?.pv2_remaining}</span>
                </div>
              </div>
              <div className="col-sm">
                <div className="static-info">
                  <h5>Remaining to Pack</h5>
                  <span>{workgroupsData?.data?.[0]?.pack_remaining}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="blue-card-panel mat-data-card">
            <div className="b-card-body pd10">
              <div className="b-head-btn text-end mr-b10">
                <button
                  type="button"
                  className="blue-btn pd-l30 pd-r30"
                  onClick={() =>
                    navigate(
                      "/fill_process/work_group_selection/view_detail/view_rx",
                      {
                        state: {
                          workGroupId: workgroupsData?.data?.[0]?.workgroup_id,
                        },
                      }
                    )
                  }
                >
                  View RX Details
                </button>
              </div>
              <div className="row">
                <div className="col">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>Remaining Fill Materials</h5>
                    </div>
                    <div className="b-card-body table-responsive pd10">
                      <TableContainer>
                        <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" className="p-1">
                                Seq
                              </TableCell>
                              <TableCell align="center" className="p-1">
                                Fill Product No.
                              </TableCell>
                              <TableCell align="center" className="p-1">
                                Fill Product Description
                              </TableCell>
                              <TableCell align="center" className="p-1">
                                Quantity
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {workgroupsMaterial &&
                              workgroupsMaterial?.data?.workgroupFill?.map(
                                (item: any, index: number) => {
                                  return (
                                    <TableRow>
                                      <TableCell align="center">
                                        {index+1}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.productId}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.productDescription}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.qty}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={3} className="text-start">
                                Total
                              </TableCell>
                              <TableCell align="center">
                                {
                                  workgroupsMaterial?.data?.totalMaterialCount
                                    ?.fillMaterialTotal
                                }
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>Remaining Pack Materials</h5>
                    </div>
                    <div className="b-card-body table-responsive pd10">
                      <TableContainer>
                        <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" className="p-1">
                                Seq
                              </TableCell>
                              <TableCell align="center" className="p-1">
                                Fill Product No.
                              </TableCell>
                              <TableCell align="center" className="p-1">
                                Fill Product Description
                              </TableCell>
                              <TableCell align="center" className="p-1">
                                Quantity
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {workgroupsMaterial &&
                              workgroupsMaterial?.data?.workgroupPack?.map(
                                (item: any, index: number) => {
                                  return (
                                    <TableRow>
                                      <TableCell align="center">
                                        {index+1}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.packingItemId}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.productDescription}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.qty}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={3} className="text-start">
                                Total
                              </TableCell>
                              <TableCell align="center">
                                {
                                  workgroupsMaterial?.data?.totalMaterialCount
                                    ?.packMaterialTotal
                                }
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!location?.state?.isHistory && (
              <div className="print-btn mr-t30 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="border-blue-btn pd-l30 pd-r30"
                  data-bs-toggle="modal"
                  data-bs-target="#printfillModal"
                  onClick={() => setPrintFillListModalFlag(true)}
                >
                  Print Fill List
                </button>
                <button
                  type="button"
                  className="blue-btn pd-l20 pd-r20 mr-l10"
                  data-bs-toggle="modal"
                  data-bs-target="#worksModal"
                  onClick={() => setWGSModalFlag(true)}
                >
                  Assign to Fill Group
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <PrintFillListModal
        open={printFillListModalFlag}
        closeModal={() => setPrintFillListModalFlag(false)}
        workGroupId={location?.state?.workGroupId || ""}
      />
      <WorkGroupSelectionModal
        open={wGSModalFlag}
        closeModal={() => setWGSModalFlag(false)}
        workGroupId={location?.state?.workGroupId || ""}
      />
    </section>
  );
};

export default ViewDetail;
