//This file is for the css of the UI elemnts inside mui components

export default {
  modalHeader: {
    backgroundColor: "#080C27",
    height: "30px",
    color: "#FFFFFF",
    padding: "0px 11px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
  },
  modalContent: {
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: "0px 12px",
  },
  userProfileMetaData: {
    display: "flex",
    justifyContent: "center",
    fontSize: "7px",
    fontFamily: "Montserrat-Regular",
  },
  userProfileName: {
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
    fontFamily: "Montserrat-Bold",
  },
  userProfileOptions: {
    fontSize: "10px",
    color: "#606060",
    paddingLeft: "6px",
    fontFamily: "Montserrat-Regular",
  },
  fontBold: {
    fontFamily: "Montserrat-Bold",
  },
  resetButton: {
    width: "132px",
    backgroundColor: "#080C27",
    color: "#FFFFFF",
    height: "24px",
    borderRadius: "2px",
    borderWidth: "0px",
    fontFamily: "Montserrat-Regular",
  },
  resetButtonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  inputBox: {
    border: "0.564531px solid rgba(0, 0, 0, 0.16)",
    height: "20px",
    padding: "0px 5px",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  width160: {
    width: "160px",
  },
  redAsterisk: {
    color: "red",
  },
  centerHorizontally: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Montserrat-Bold",
  },
};
