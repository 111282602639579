import { Modal } from "@mui/material";
import { useState } from "react";
import UserWorkStationRefActions from "../redux/user_work_station_ref/actions";
import { useDispatch } from "react-redux";
import { Spinner } from "./loaders";

interface EditUserWFRefModalInterface {
  open: boolean;
  closeModal: () => void;
  data: any;
}

const { updateUserWorkstation } = UserWorkStationRefActions;

const EditUserWSRefModal: React.FC<EditUserWFRefModalInterface> = (props) => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState<boolean>(false);

  const [wsId, setWsId] = useState<string>(props.data.workstation_id);
  const [wsDesc, setWsDesc] = useState<string>(props.data.description);
  const [wsStatus, setWsStatus] = useState<number>(props.data.status ? 1 : 0);
  const [wsAuto, setWsAuto] = useState<number>(props.data.auto ? 1 : 0);
  const [wsAssigned, setWsAssigned] = useState<number>(
    props.data.assign ? 1 : 0
  );
  const [wsTimeout, setWsTimeout] = useState<number>(
    props.data.timeout ? 1 : 0
  );
  const [wsIdleTime, setWsIdleTime] = useState<number>(props.data.idle_time);

  const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "ws-id") {
      setWsId(value);
    }
    if (name === "ws-desc") {
      setWsDesc(value);
    }
    if (name === "ws-status") {
      setWsStatus(Number(value));
    }
    if (name === "ws-auto") {
      setWsAuto(Number(value));
    }
    if (name === "ws-assigned") {
      setWsAssigned(Number(value));
    }
    if (name === "ws-timeout") {
      setWsTimeout(Number(value));
    }
    if (name === "ws-idletime") {
      setWsIdleTime(Number(value));
    }
  };

  const updateHandler = () => {
    setLoader(true);
    const data = {
      workstation_id: wsId,
      description: wsDesc,
      status: Number(Boolean(wsStatus)),
      auto: Boolean(wsAuto),
      assign: Boolean(wsAssigned),
      timeout: Boolean(wsTimeout),
      idle_time: wsIdleTime,
    };
    dispatch(updateUserWorkstation(data));
  };

  return (
    <Modal open={props.open}>
      <div
        className="modal cus-modal fade show"
        id="editUserModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal360 modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit User Work Station Reference
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={props.closeModal}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body eu-reference-body">
              <form action="" className="row">
                <div className="col-sm-6">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Work Station ID
                    </label>
                    <input
                      type="text"
                      className="form-control cus-control"
                      name="ws-id"
                      id=""
                      value={wsId}
                      onChange={textChange}
                      disabled
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Work Station Desc
                    </label>
                    <input
                      type="text"
                      className="form-control cus-control"
                      name="ws-desc"
                      id=""
                      value={wsDesc}
                      onChange={textChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Work Station Status
                    </label>
                    <select
                      className="form-control cus-control"
                      name="ws-status"
                      id=""
                      value={wsStatus}
                      onChange={(e) => setWsStatus(Number(e.target.value))}
                    >
                      <option value={1}>1</option>
                      <option value={0}>0</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Work Station Auto
                    </label>
                    <select
                      className="form-control cus-control"
                      name="ws-auto"
                      id=""
                      value={wsAuto}
                      onChange={(e) => setWsAuto(Number(e.target.value))}
                    >
                      <option value={1}>1 (Default)</option>
                      <option value={0}>0</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Work Station Assigned
                    </label>
                    <select
                      className="form-control cus-control"
                      name="ws-assigned"
                      id=""
                      value={wsAssigned}
                      onChange={(e) => setWsAssigned(Number(e.target.value))}
                    >
                      <option value={1}>1</option>
                      <option value={0}>0</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Work Station Timeout
                    </label>
                    <select
                      className="form-control cus-control"
                      name="ws-timeout"
                      id=""
                      value={wsTimeout}
                      onChange={(e) => setWsTimeout(Number(e.target.value))}
                    >
                      <option value={1}>1</option>
                      <option value={0}>0</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group cus-group mr-b15">
                    <label htmlFor="" className="inp-lable-title">
                      Work Station Idle Time
                    </label>
                    <select
                      className="form-control cus-control"
                      name="ws-idletime"
                      id=""
                      value={wsIdleTime}
                      onChange={(e) => setWsIdleTime(Number(e.target.value))}
                    >
                      <option value={30}>30 sec</option>
                      <option value={600}>10 min</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0">
              {loader ? (
                <button
                  type="button"
                  className="blue-btn pd-l50 pd-r50"
                  data-bs-dismiss="modal"
                >
                  <Spinner />
                </button>
              ) : (
                <button
                  type="button"
                  className="blue-btn pd-l50 pd-r50"
                  data-bs-dismiss="modal"
                  onClick={updateHandler}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.49917 0C0.676746 0 0 0.676753 0 1.49922V8.50082C0 9.32327 0.676735 9.99998 1.49917 9.99998H8.50179C9.32423 9.99998 9.99999 9.32327 9.99999 8.50082V2.49923C10.001 2.36676 9.94936 2.23922 9.85642 2.14473L7.85525 0.143543C7.79294 0.0826928 7.71584 0.0394532 7.63159 0.017532L1.49917 0ZM1.49917 1.00013H2.00117V3.49936C2.00227 3.77398 2.22463 3.99637 2.49926 3.99747H7.50072C7.77535 3.99637 7.99771 3.77398 7.99882 3.49936V1.70429L8.99989 2.70535V8.50082C8.99989 8.7865 8.78747 8.99991 8.50179 8.99991H1.49917C1.21349 8.99991 1.00107 8.7865 1.00107 8.50082V1.49922C1.00107 1.2135 1.21348 1.00013 1.49917 1.00013ZM3.00127 1.00013H6.99872V3.00126H3.00127V1.00013ZM2.49926 4.99852C2.22311 4.99962 2.00012 5.22439 2.00117 5.50055V7.50069C2.00226 7.77533 2.22463 7.9977 2.49926 7.9988H7.50072C7.77536 7.9977 7.99772 7.77533 7.99882 7.50069V5.50055C7.99987 5.22439 7.77688 4.99962 7.50072 4.99852H2.49926ZM3.00127 5.99865H6.99872V6.99872H3.00127V5.99865Z"
                      fill="white"
                    />
                  </svg>{" "}
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserWSRefModal;
