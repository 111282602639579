import { FILL_PROCESS } from "../../constants/action";
import {
  clearPackoutMaterialData,
  clearPackoutRxs,
  clearUpdatePackoutStatus,
  clearShippingLabelResponse
} from "./reducer";

const packoutStationActions = {
  getPackoutMaterialData: (payload: string) => ({
    type: FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_MATERIAL_DATA,
    payload,
  }),
  printShippingLabel: (payload: string) => ({
    type: FILL_PROCESS.PACKOUT_STATION.PRINT_SHIPPING_LABEL,
    payload,
  }),
  getPackoutRxs: (payload: string) => ({
    type: FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_RXS,
    payload,
  }),
  getPackoutRxsDetails: (payload: string) => ({
    type: FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_RXS_DETAILS,
    payload,
  }),
  updatePackoutStatus: (payload: any) => ({
    type: FILL_PROCESS.PACKOUT_STATION.UPDATE_PACKOUT_STATUS,
    payload,
  }),
  getPackoutWorkgroupData: (payload: any) => ({
    type: FILL_PROCESS.PACKOUT_STATION.GET_PACKOUT_WORKGROUP_DATA,
    payload
  }),
  clearPackoutMaterialData: () => clearPackoutMaterialData(),
  clearPackoutRxs: () => clearPackoutRxs(),
  clearUpdatePackoutStatus: () => clearUpdatePackoutStatus(),
  clearShippingLabelResponse: () => clearShippingLabelResponse(),
};

export default packoutStationActions;
