import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import TopBar from "../components/topbar";
import SideBar from "../components/sidebar";
import Login from "../pages/auth/login";
import ChangePasword from "../pages/auth/changePassword";
import ResetPassword from "../pages/auth/resetPassword";
import RxProductImage from "../pages/rx_product_image/image_and_modal_config/rx_product_image";
import UserWorkStationRef from "../pages/user_workstation_ref/user_workStation_ref";
import ProductImageDetail from "../pages/rx_product_image/image_and_modal_config/product_image_detail";
import WorkGroupSelection from "../pages/fill_process/work_group_selection/work_group_selection";
import WorkGroupHistory from "../pages/fill_process/work_group_selection/work_group_history";
import ViewDetail from "../pages/fill_process/work_group_selection/view_detail";
import ViewRxViewDetails from "../pages/fill_process/work_group_selection/view_rx_view_details";
import ViewRxDetails from "../pages/fill_process/work_group_selection/view_rx_details";
import Pv2Station from "../pages/fill_process/pv2_station/pv2_station";
import FillStation from "../pages/fill_process/fill_station/fill_station";
import ErrorCorrectionStation from "../pages/fill_process/error_correction/error_correction";
import PackoutStation from "../pages/fill_process/packout_station/packout_station";
import PackoutStationViewRxDetails from "../pages/fill_process/packout_station/packout_station_view_rx_details";
import PackoutStationViewRxDetailsViewDetails from "../pages/fill_process/packout_station/packout_station_view_rx_details_view_details";
import RphAssignment from "../pages/rph_assignment/rph_assignment";
import InitiateTest from "../pages/rx_product_image/initiate_test/initiate_test";
import TestResult from "../pages/rx_product_image/test_result/test_result";
import { useEffect, useState } from "react";

const isLoggedIn = () => {
  return localStorage.getItem("token") ? true : false;
};

const MainHomeComponents = () => {
  return (
    <>
      <TopBar />
      <SideBar />
      <Outlet />
    </>
  );
};

const PrivateRoutes = () => {
  const location = useLocation();

  return isLoggedIn() ? (
    <MainHomeComponents />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
const HomeRedirect = () => {
  const location = useLocation();
  let details: any = JSON.parse(localStorage.getItem("user") || "");
  // connect();
  // return details?.role === "user" ? (
  // 	<Navigate
  // 		to="/fill_process/fill_station"
  // 		replace
  // 		state={{ from: location }}
  // 	/>
  // ) : (
  // 	<Navigate
  // 		to="/fill_process/work_group_selection"
  // 		replace
  // 		state={{ from: location }}
  // 	/>
  // );
  return (
    <Navigate
      to="/fill_process/work_group_selection"
      replace
      state={{ from: location }}
    />
  );
};

const Router = () => {
  const [userDetails, setUserDetails] = useState<any>("");

  useEffect(() => {
    if (localStorage.getItem("user")) {
      let details: any = JSON.parse(localStorage.getItem("user") || "");
      setUserDetails(details?.role);
    }
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        theme="colored"
        hideProgressBar={true}
        className="no-printme"
        toastStyle={{
          // backgroundColor: "#BFD2DD",
          // color: "black",
          fontSize: "13px",
        }}
      />
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route index element={<HomeRedirect />} />
          {userDetails === "user" ? (
            <>
              <Route
                path="/fill_process/work_group_selection"
                element={<WorkGroupSelection />}
              />
              <Route
                path="/fill_process/work_group_selection/work_group_history"
                element={<WorkGroupHistory />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail"
                element={<ViewDetail />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail/view_rx"
                element={<ViewRxDetails />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail/view_rx/view_rx_details"
                element={<ViewRxViewDetails />}
              />
              <Route
                path="/fill_process/fill_station"
                element={<FillStation />}
              />
              <Route
                path="/fill_process/error_correction"
                element={<ErrorCorrectionStation />}
              />
              <Route
                path="/fill_process/packout_station"
                element={<PackoutStation />}
              />
              <Route
                path="/fill_process/packout_station/view_rx_details/:id"
                element={<PackoutStationViewRxDetails />}
              />
              <Route
                path="/fill_process/packout_station/view_rx_details/view_details/:id"
                element={<PackoutStationViewRxDetailsViewDetails />}
              />
            </>
          ) : userDetails === "supervisor" ? (
            <>
              <Route
                path="/fill_process/work_group_selection"
                element={<WorkGroupSelection />}
              />
              <Route
                path="/fill_process/work_group_selection/work_group_history"
                element={<WorkGroupHistory />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail"
                element={<ViewDetail />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail/view_rx"
                element={<ViewRxDetails />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail/view_rx/view_rx_details"
                element={<ViewRxViewDetails />}
              />
              <Route
                path="/fill_process/pv2_station"
                element={<Pv2Station />}
              />

              <Route path="/rph_assignment" element={<RphAssignment />} />
            </>
          ) : (
            <>
              <Route
                path="/fill_process/work_group_selection"
                element={<WorkGroupSelection />}
              />
              <Route
                path="/fill_process/work_group_selection/work_group_history"
                element={<WorkGroupHistory />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail"
                element={<ViewDetail />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail/view_rx"
                element={<ViewRxDetails />}
              />
              <Route
                path="/fill_process/work_group_selection/view_detail/view_rx/view_rx_details"
                element={<ViewRxViewDetails />}
              />
              <Route
                path="/fill_process/fill_station"
                element={<FillStation />}
              />
              <Route
                path="/fill_process/pv2_station"
                element={<Pv2Station />}
              />
              <Route
                path="/fill_process/error_correction"
                element={<ErrorCorrectionStation />}
              />
              <Route
                path="/fill_process/packout_station"
                element={<PackoutStation />}
              />
              <Route
                path="/fill_process/packout_station/view_rx_details/:id"
                element={<PackoutStationViewRxDetails />}
              />
              <Route
                path="/fill_process/packout_station/view_rx_details/view_details/:id"
                element={<PackoutStationViewRxDetailsViewDetails />}
              />
              <Route
                path="/rx_product_image/config"
                element={<RxProductImage />}
              />
              <Route
                path="/rx_product_image/config/:product_id"
                element={<ProductImageDetail />}
              />
              <Route path="/rx_product_image/test" element={<InitiateTest />} />
              <Route
                path="/rx_product_image/test_result"
                element={<TestResult />}
              />
              <Route
                path="/user_work_station_ref"
                element={<UserWorkStationRef />}
              />
              <Route path="/rph_assignment" element={<RphAssignment />} />
            </>
          )}
        </Route>
        <Route
          path="/login"
          element={isLoggedIn() ? <Navigate replace to="/" /> : <Login />}
        />
        <Route path="/change_password" element={<ChangePasword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
