import { all, takeLatest, put } from "redux-saga/effects";
import {
  gateway_login,
  fill_api_login,
  get_ipdata_api,
  get_workstation_with_ip_api,
  logout_api,
} from "../../services";
import { AUTH } from "../constants/action";
import {
  SagaInterface,
  YieldInterface,
  YieldLoginInterface,
} from "../constants/interface";
import {
  setLoginRespons,
  setValidateWorkstationResponse,
  setWorkstationsWithIpResponse,
  setLogoutResponse,
} from "./reducer";
// import { publicIpv4 } from 'public-ip';
// import { internalIpV4} from 'internal-ip';

function* login_saga1(params: SagaInterface) {
  try {
    let { payload } = params;

    let gatewayLoginResponse: YieldLoginInterface = yield gateway_login(
      payload
    );
    // console.log("🚀 ~ file: saga.ts ~ line 12 ~ function*login_saga ~ loginResponse", loginResponse);

    localStorage.setItem("token", gatewayLoginResponse.token);
    localStorage.setItem("refresh_token", gatewayLoginResponse.refreshToken);

    // const ipAddress: string = yield publicIpv4();
    // const internalIpAddress: string = yield internalIpV4();
    // console.log("🚀 ~ file: saga.ts ~ line 19 ~ function*login_saga ~ ipAddress", ipAddress);

    // let fillApiLoginResponse: YieldInterface = yield fill_api_login(payload, gatewayLoginResponse.token);
    // const payloadIP = {
    //   ip_address: internalIpAddress ? internalIpAddress : ipAddress,
    //   user_id: JSON.parse(localStorage.getItem("user") || "")?.id,
    // }
    // const ipData: YieldInterface = yield get_ipdata_api(payloadIP);
    // console.log("🚀 ~ file: saga.ts ~ line 19 ~ function*login_saga ~ ipData", ipData);
    yield put(
      setLoginRespons({
        success: true,
        ...gatewayLoginResponse,
      })
    );
  } catch (error: any) {
    localStorage.clear();
    yield put(
      setLoginRespons({
        success: false,
        ...error.response,
      })
    );
  }
}

function* login_saga(params: SagaInterface) {
  try {
    let { payload } = params;

    let gatewayLoginResponse: YieldLoginInterface = yield gateway_login(
      payload
    );
    localStorage.setItem("token", gatewayLoginResponse.token);
    localStorage.setItem("refresh_token", gatewayLoginResponse.refreshToken);

    let fillApiLoginResponse: YieldInterface = yield fill_api_login(payload);

    yield put(
      setLoginRespons({
        success: true,
        ...fillApiLoginResponse,
      })
    );
  } catch (error: any) {
    localStorage.clear();
    yield put(
      setLoginRespons({
        success: false,
        ...error.response,
      })
    );
  }
}

function* validateWorkstation_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    console.log(
      "🚀 ~ file: saga.ts ~ line 47 ~ function*validateWorkstation_saga ~ params",
      params
    );

    let validateWorkstationResponse: YieldInterface = yield get_ipdata_api(
      payload
    );

    yield put(
      setValidateWorkstationResponse({
        success: true,
        ...validateWorkstationResponse,
      })
    );
  } catch (error: any) {
    console.log(error);

    yield put(
      setValidateWorkstationResponse({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* workstationsWithIp_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let workstationsWithIpResponse: YieldInterface =
      yield get_workstation_with_ip_api(payload);
    yield put(
      setWorkstationsWithIpResponse({
        success: true,
        ...workstationsWithIpResponse,
      })
    );
  } catch (error: any) {
    yield put(
      setWorkstationsWithIpResponse({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* logout_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let logoutResponse: YieldInterface = yield logout_api(payload);
    yield put(
      setLogoutResponse({
        success: true,
        ...logoutResponse,
      })
    );
  } catch (error: any) {
    yield put(
      setLogoutResponse({
        success: false,
        ...error.response.data,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([takeLatest(AUTH.LOGIN, login_saga)]);
  yield all([takeLatest(AUTH.VALIDATE_WORKSTATION, validateWorkstation_saga)]);
  yield all([
    takeLatest(AUTH.GET_WORKSTATIONS_WITH_IP, workstationsWithIp_saga),
  ]);
  yield all([takeLatest(AUTH.LOGOUT, logout_saga)]);
}
