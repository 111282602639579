import api from "../api";

export const getWorkStationList_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/workstation?${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updateWorkStation_api = async (payload: any) => {
  try {
    let response = await api.patch(`/fill/workstation`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteWorkStation_api = async (payload: string) => {
  try {
    let response = await api.delete(`/fill/workstation/${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}