import { Modal } from "@mui/material";

interface SuccessModalInterFace {
  open: boolean;
  closeModal: ()=>void;
  title: string;
  message: string;
}

const SuccessModal: React.FC<SuccessModalInterFace> = (props) => {
  return(
    <Modal open={props.open}>
      <div className="modal cus-modal fade show" id="sucmsgModal" tabIndex={-1} aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal552 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                                fill="white" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body suc-msg-body text-center">
                    <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24.0003 46.8337C11.1133 46.8337 0.666992 36.3873 0.666992 23.5003C0.666992 10.6133 11.1133 0.166992 24.0003 0.166992C36.8873 0.166992 47.3337 10.6133 47.3337 23.5003C47.3337 36.3873 36.8873 46.8337 24.0003 46.8337ZM21.674 32.8337L38.1707 16.3347L34.8713 13.0353L21.674 26.235L15.073 19.634L11.7737 22.9333L21.674 32.8337Z"
                            fill="#46AEFD" />
                    </svg>
                    <h3>
                        Success
                    </h3>
                    <h5>
                        {props.message}
                    </h5>
                </div>

            </div>
        </div>
    </div>
    </Modal>
  )
}

export default SuccessModal;