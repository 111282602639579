import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { LogoFFF } from "../../assets/images";

interface RootState {}
const ResetPassword = ({}) => {
  const navigate = useNavigate();
  const [userPassword, setUserPassword] = useState("");

  const handleOnClickReset = () => {
    // if (!userPassword || !userPassword.trim().length) {
    //   toast.error("Please enter password");
    //   return;
    // }
    const payload = {
      password: userPassword,
    };
    //call API here

    //move below code in API response
    navigate("/login");
  };

  return (
    <section className="login-wrap">
      <div className="login-cell">
        <div className="login-block">
          <div className="l-logo-block text-center">
            <img src={LogoFFF} alt="nufactor" title="nufactor" />
          </div>
          <div className="auth-form mr-t30">
            <form action="">
              <div className="form-group login-in-group mr-b20">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control cus-form-control"
                  placeholder="Enter password"
                  value={userPassword}
                  onChange={(event) => setUserPassword(event.target.value)}
                />
              </div>
              <button
                type="button"
                className="l-btn"
                onClick={handleOnClickReset}
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="l-foot-links mr-t20">
        <ul className="mx-auto">
          <li>
            <a href="#">Terms & Conditions</a>
          </li>
          <li>
            <a href="#">Privacy Policy</a>
          </li>
          {/* <li>
            <a href="#">Powered by Winjit</a>
          </li> */}
        </ul>
        <p>&copy; Copyright 2022. All rights reserved</p>
      </div>
    </section>
  );
};

// const mapStateToProps = (state: RootState) => ({});
// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(Login);
export default ResetPassword;
