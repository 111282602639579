import { Modal } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import RxProductImageActions from "../redux/rx_product_image/actions";
import { Spinner } from "./loaders";

const { initiateImageTest } = RxProductImageActions;
interface InitiateTestModalInterface {
  open: boolean;
  closeModal: () => void;
  lpn: string;
  testNames: any[];
}

const InitiateTestModal: React.FC<InitiateTestModalInterface> = (props) => {
  const dispatch = useDispatch();

  const [option, setOption] = useState<string>("");
  const [spinnerFlag, setSpinnerFlag] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!option || !option.trim().length) {
      toast.warn("Please enter Test Name");
      return;
    }
    if (option === "" || props.lpn === "") {
      toast.error("Something went wrong! please try again");
      return;
    }
    const userDetails = JSON.parse(localStorage.getItem("user")||"");
    const payload = {
      test_name: option,
      lpn_number: props.lpn,
      user_id: userDetails?.id || ""
    };
    dispatch(initiateImageTest(payload));
    setSpinnerFlag(true);
  };

  return (
    <Modal open={props.open}>
      <div
        className="modal cus-modal fade show"
        id="lpnAlertCloseModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal552 modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content lpn-text-cont">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Test Name
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={props.closeModal}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body text-center">
              <div className="test-b">
                <label htmlFor="Test Name w-100">Test Name</label>
                <input
                  type="text"
                  list="data"
                  onChange={(e) => setOption(e.target.value)}
                  value={option}
                  placeholder="Please enter Test Name"
                />
                <datalist id="data">
                  {props.testNames?.map((item: any, index: number) => {
                    return <option key={index} value={item.test_image_name} />;
                  })}
                </datalist>
              </div>
              <div className="b-head-btn test-asv text-end">
                {spinnerFlag ? (
                  <button
                    type="button"
                    className="blue-btn pd-l50 pd-r50"
                    data-bs-dismiss="modal"
                  >
                    <Spinner />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="blue-btn pd-l30 pd-r30"
                    data-bs-toggle="modal"
                    data-bs-target="#sucmsgModal"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Test AVSV
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InitiateTestModal;
