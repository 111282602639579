import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const packoutStationSlice = createSlice({
  name: "packoutStation",
  initialState: initState,
  reducers: {
    setPackoutMaterialData: (state, action) => {
      return { ...state, packoutMaterialData: action.payload };
    },
    setShippingLabelData: (state, action) => {
      return { ...state, printShippingLabelResponse: action.payload };
    },
    setPackoutRxsData: (state, action) => {
      return { ...state, packoutRxs: action.payload };
    },
    clearPackoutRxs: (state) => {
      return { ...state, packoutRxs: undefined };
    },
    setPackoutRxsDetails: (state, action) => {
      return { ...state, packoutRxsDetails: action.payload };
    },
    setPackoutWorkgroupData: (state, action) => {
      return { ...state, rxWorkgroupsData: action.payload.data.response };
    },
    clearPackoutRxsDetails: (state) => {
      return { ...state, packoutRxsDetails: undefined };
    },
    clearPackoutMaterialData: (state) => {
      return { ...state, packoutMaterialData: undefined };
    },
    setUpdatePackoutStatus: (state, action) => {
      return { ...state, updatePackoutStatusResponse: action.payload };
    },
    clearUpdatePackoutStatus: (state) => {
      return { ...state, updatePackoutStatusResponse: undefined };
    },
    clearShippingLabelResponse: (state) => {
      return { ...state, printShippingLabelResponse: undefined };
    },
  },
});

export const {
  setPackoutMaterialData,
  setShippingLabelData,
  setPackoutRxsData,
  clearPackoutRxs,
  setPackoutRxsDetails,
  setPackoutWorkgroupData,
  clearPackoutRxsDetails,
  clearPackoutMaterialData,
  setUpdatePackoutStatus,
  clearUpdatePackoutStatus,
  clearShippingLabelResponse
} = packoutStationSlice.actions;

export default packoutStationSlice.reducer;
