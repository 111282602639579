import api from "../../api";

export const getFillStationDetailsById_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/user-workstation?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOtherDetails_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/remainingMaterialOtherDetails?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOtherDetails_api = async (payload: any) => {
  try {
    let response = await api.patch(`/fill/remainingMaterialOtherDetails`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const printRxLabels_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/print-rx-labels?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const initFillStation_api = async (payload: any) => {
  try {
    let response = await api.post(`/fill/init-fill-process`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addOtherDetails_api = async (payload: any) => {
  try {
    let response = await api.post(`/fill/rx-scanned-product`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRxDetailsById_api = async (payload: any) => {
  try {
    let response = await api.get(`/fill/print-rx-labels?${payload}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};