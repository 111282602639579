import { Modal } from "@mui/material";

interface DuplicateSessionAlertModalInterFace {
  open: boolean;
  closeModal: () => void;
  forcedLogin: () => void;
}

const DuplicateSessionAlertModal: React.FC<
  DuplicateSessionAlertModalInterFace
> = ({ open, closeModal, forcedLogin }) => {
  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="printfillModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal552 modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Alert!
              </h5>
            </div>
            <div className="modal-body printf-m-body">
              <h6>
                You are already logged in with some other system. Please logout
                from the existing session and try again.
              </h6>
              <div className="print-btn mr-t30 d-flex align-items-center justify-content-end">
              <button
                  type="button"
                  className="border-blue-btn pd-l30 pd-r30"
                  onClick={() => forcedLogin()}
                >
                  Force login
                </button>
                <button
                  type="button"
                  className="blue-btn pd-l20 pd-r20 mr-l10"
                  onClick={() => closeModal()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateSessionAlertModal;
