import { all, takeLatest, put } from "redux-saga/effects";
import { FILL_PROCESS } from "../../constants/action";
import { SagaInterface, YieldInterface } from "../../constants/interface";
import {
  setFillStationStatus,
  setWorkgroupsList,
  setWorkgroupsHistory,
  setRxDetailsViewDetails,
  setRxDetails,
  setAssignFillStationToWorkgroup,
  setWorkgroupsData,
  setWorkgroupsMaterial,
  setPrintWorkgroupsMaterial,
  setRemainingWorkGroups,
  setReprintRxLabels,
} from "./reducer";
import {
  getFillStationStatus_api,
  getWorkgroupsList_api,
  getWorkgroupsHistory_api,
  rxDetailsViewDetails_api,
  rxDetails_api,
  assignFillStationToWorkgroup_api,
  getWorkgroupData_api,
  getWorkgroupMaterial_api,
  printWorkgroupMaterial_api,
  getRemainingWorkgroups_api,
  reprintRxLabels_api,
} from "../../../services";

function* getFillStationStatus_saga() {
  try {
    let fillStationStatus: YieldInterface = yield getFillStationStatus_api();
    yield put(
      setFillStationStatus({
        success: true,
        ...fillStationStatus,
      })
    );
  } catch (error: any) {
    yield put(
      setFillStationStatus({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getWorkgroupsList_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let workgroupsList: YieldInterface = yield getWorkgroupsList_api(payload);
    yield put(
      setWorkgroupsList({
        success: true,
        ...workgroupsList,
      })
    );
  } catch (error: any) {
    yield put(
      setWorkgroupsList({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getWorkgroupsHistory_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let workgroupsList: YieldInterface = yield getWorkgroupsHistory_api(
      payload
    );
    yield put(
      setWorkgroupsHistory({
        success: true,
        ...workgroupsList,
      })
    );
  } catch (error: any) {
    yield put(
      setWorkgroupsHistory({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* rxDetailsViewDetails_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let rxDetailsViewDetails: YieldInterface = yield rxDetailsViewDetails_api(
      payload
    );
    yield put(
      setRxDetailsViewDetails({
        success: true,
        ...rxDetailsViewDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setRxDetailsViewDetails({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* rxDetails_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let rxDetails: YieldInterface = yield rxDetails_api(payload);
    yield put(
      setRxDetails({
        success: true,
        ...rxDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setRxDetails({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* assignFillStationToWorkgroup_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let assignFillStationToWorkgroup: YieldInterface =
      yield assignFillStationToWorkgroup_api(payload);
    yield put(
      setAssignFillStationToWorkgroup({
        success: true,
        ...assignFillStationToWorkgroup,
      })
    );
  } catch (error: any) {
    yield put(
      setAssignFillStationToWorkgroup({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getWorkgroupsData_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let workgroupsData: YieldInterface = yield getWorkgroupData_api(payload);
    yield put(
      setWorkgroupsData({
        success: true,
        ...workgroupsData,
      })
    );
  } catch (error: any) {
    yield put(
      setWorkgroupsData({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getWorkgroupsMaterial_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let workgroupsMaterial: YieldInterface = yield getWorkgroupMaterial_api(
      payload
    );
    yield put(
      setWorkgroupsMaterial({
        success: true,
        ...workgroupsMaterial,
      })
    );
  } catch (error: any) {
    yield put(
      setWorkgroupsMaterial({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getRemainingWorkgroups_saga(params: SagaInterface) {
  try {
    let response: YieldInterface = yield getRemainingWorkgroups_api();
    yield put(
      setRemainingWorkGroups({
        success: true,
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      setRemainingWorkGroups({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* printWorkgroupsMaterial_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let printWorkgroupsMaterialResponse: YieldInterface =
      yield printWorkgroupMaterial_api(payload);
    yield put(
      setPrintWorkgroupsMaterial({
        success: true,
        ...printWorkgroupsMaterialResponse,
      })
    );
  } catch (error: any) {
    yield put(
      setPrintWorkgroupsMaterial({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* reprintRxLabels_saga(params: SagaInterface) {
  try {
    const { payload } = params;
    let updatedDetails: YieldInterface = yield reprintRxLabels_api(payload);
    yield put(
      setReprintRxLabels({
        success: true,
        ...updatedDetails,
      })
    );
  } catch (error: any) {
    yield put(
      setReprintRxLabels({
        success: false,
        ...error.response.data,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_FILL_STATION_STATUS,
      getFillStationStatus_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_LIST,
      getWorkgroupsList_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_HISTORY,
      getWorkgroupsHistory_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_RX_DETAILS_VIEW_DETAILS,
      rxDetailsViewDetails_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_RX_DETAILS,
      rxDetails_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.ASSIGN_FILL_STATION_TO_WORK_GROUP,
      assignFillStationToWorkgroup_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_DATA,
      getWorkgroupsData_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_WORK_GROUP_MATERIAL,
      getWorkgroupsMaterial_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.PRINT_WORK_GROUP_MATERIAL,
      printWorkgroupsMaterial_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.GET_REMAINING_WORK_GROUPS,
      getRemainingWorkgroups_saga
    ),
  ]);
  yield all([
    takeLatest(
      FILL_PROCESS.WORK_GROUP_SELECTION.REPRINT_RX_LABELS,
      reprintRxLabels_saga
    ),
  ]);
}
