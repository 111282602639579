import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ViewSvg2 } from "../../../assets/images";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import { toast } from "react-toastify";
import qs from "qs";
import PrintPdfFillStation from "../../../components/printPdfFillStation1";

const {
  getRxDetails,
  getRxDetailsViewDetails,
  clearSetRxDetailsViewDetails,
  reprintRxLabels,
  clearSetReprintRxLabels,
} = WorkGroupSelectionActions;

const ViewRxDetails = () => {
  const rxDetails = useSelector(
    (state: any) => state?.workGroupSelection?.rxDetails
  );
  const rxDetailsViewDetails = useSelector(
    (state: any) => state?.workGroupSelection?.rxDetailsViewDetails
  );
  const reprintRxLabelsResponse = useSelector(
    (state: any) => state?.workGroupSelection?.reprintRxLabelsResponse
  );
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [rxNumber, setRxNumber] = useState<string>("");

  const [columnTitle] = useState<{ label: string }[]>([
    { label: "Seq" },
    { label: "Ship To Name" },
    { label: "Service Type" },
    { label: "St" },
    { label: "Fill by Date" },
    { label: "Fill Product Description" },
    { label: "Last Name" },
    { label: "DOB" },
    { label: "Group ID" },
    { label: "Packout ID" },
    { label: "Tracking ID" },
    { label: "Status" },
    { label: "Details" },
  ]);

  useEffect(() => {
    dispatch(clearSetRxDetailsViewDetails());
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.workGroupId) {
      setLoading(true);
      dispatch(getRxDetails(location.state.workGroupId));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (rxDetails) {
      setLoading(false);
      if (!rxDetails.success) {
        toast.error(rxDetails?.message);
      }
    }
  }, [rxDetails]);

  useEffect(() => {
    if (rxDetailsViewDetails) {
      setLoading(false);
      if (!rxDetailsViewDetails?.success) {
        toast.error(rxDetailsViewDetails?.message);
        dispatch(clearSetRxDetailsViewDetails());
      } else if (rxDetailsViewDetails?.success) {
        dispatch(clearSetRxDetailsViewDetails());
        navigate(
          "/fill_process/work_group_selection/view_detail/view_rx/view_rx_details",
          {
            state: {
              rxNumber,
            },
          }
        );
      }
    }
  }, [rxDetailsViewDetails, dispatch, navigate, rxNumber]);

  const handleOnClickView = (rxNo: string) => {
    if (rxNo && rxNo.length) {
      setRxNumber(rxNo);
      dispatch(getRxDetailsViewDetails(rxNo));
      setLoading(true);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearSetRxDetailsViewDetails());
      dispatch(clearSetReprintRxLabels());
    };
  }, [dispatch]);

  useEffect(() => {
    if (reprintRxLabelsResponse) {
      setLoading(false);
      if (!reprintRxLabelsResponse?.success) {
        toast.error(reprintRxLabelsResponse?.message);
      } else if (reprintRxLabelsResponse?.success) {
      }
    }
  }, [reprintRxLabelsResponse]);

  const handleOnClickReprint = (rxNo: string) => {
    if (rxNo && rxNo.length) {
      const userDetails = JSON.parse(localStorage.getItem("user") || "");
      const payload = {
        rx_number: rxNo,
        user_id: userDetails?.id || "",
        workGroupId: location.state.workGroupId,
      };
      dispatch(reprintRxLabels(qs.stringify(payload)));
      setLoading(true);
    }
  };

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={loading} />
      <div className="page-wrap">
        <div className="breadcrumb-block">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/fill_process/work_group_selection">
                  Fill Process
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/fill_process/work_group_selection">
                  Work Group Selection
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="" onClick={() => navigate(-1)}>
                  View Detail
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                View RX Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="detailswgs-wrap">
          <div className="blue-card-panel mat-data-card">
            <div className="b-card-body pd10">
              <div className="blue-card-panel">
                <div className="b-card-head">
                  <h5>RX Details in the Work Group</h5>
                </div>
                <div className="b-card-body pd10">
                  <div className="table-responsive">
                    <TableContainer>
                      <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                        <TableHead>
                          <TableRow>
                            {columnTitle.map((column) => {
                              return (
                                <TableCell
                                  align="center"
                                  key={`"${column.label}"`}
                                >
                                  {column.label}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rxDetails && rxDetails?.data?.length ? (
                            rxDetails?.data?.map(
                              (value: any, index: number) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {value.seq}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.ship_to_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.service_type}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.ship_state}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.fill_by_date &&
                                        moment(
                                          value.fill_by_date?.slice(0, -1)
                                        ).format("MM/DD/YYYY")}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.fill_product_description}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.last_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.dob &&
                                        moment(value.dob?.slice(0, -1)).format(
                                          "MM/DD/YYYY"
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.group_id}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.packout_id}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.tracking_number}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.status}
                                    </TableCell>
                                    <TableCell align="center">
                                      <div className="action-btn">
                                        <button
                                          type="button"
                                          className="border-black-btn pd-l10 pd-r10"
                                          onClick={() =>
                                            handleOnClickView(value.rx_number)
                                          }
                                        >
                                          <img src={ViewSvg2} alt="view" />
                                          View
                                        </button>
                                        {/* {value.rx_printed &&  */}
                                        <button
                                          type="button"
                                          className="border-black-btn pd-l10 pd-r10 mr-l10"
                                          onClick={() =>
                                            handleOnClickReprint(
                                              value.rx_number_id
                                            )
                                          }
                                        >
                                          Reprint
                                        </button>
                                        {/* } */}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )
                          ) : (
                            <TableRow>
                              <TableCell colSpan={13} align="center">
                                No records found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PrintPdfFillStation
          printRxLabelsResponse={reprintRxLabelsResponse}
          labelType="both"
          printNow={false}
          setLabelPrintFlag={() => {
            return;
          }}
        />
      </div>
    </section>
  );
};

export default ViewRxDetails;
