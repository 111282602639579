import { all, takeLatest, put } from "redux-saga/effects";
import { FILL_PROCESS } from "../../constants/action";
import { SagaInterface, YieldInterface } from "../../constants/interface";
import { setFailReasons, setInitialPv2Verification, setOtherMaterialsDetails, setPv2StationStatus, setUpdateMaterialDetailsResponse, setUpdatePv2VerificationResponse } from "./reducer";
import { getFailReasons_api, getFillStationDetailsById_api, getPv2StationStatus_api, getRemainingMaterialDetails_api, initPv2_api, updateMaterialDetails_api, updatePv2Verification_api } from "../../../services";

function* getFailReason_saga(params: SagaInterface) {
	try {
		let failReasons: YieldInterface = yield getFailReasons_api();

		yield put(
			setFailReasons({
				success: true,
				...failReasons,
			})
		);
	} catch (error: any) {
		yield put(
			setFailReasons({
				success: false,
				...error.response.data,
			})
		);
	}
}

function* getPv2StationStatus_saga(params: SagaInterface) {
  try {
    let { payload } = params;
    let fillStationDetailsById: YieldInterface =
      yield getFillStationDetailsById_api(payload);

    yield put(
      setPv2StationStatus({
        success: true,
        ...fillStationDetailsById,
      })
    );
  } catch (error: any) {
    yield put(
      setPv2StationStatus({
        success: false,
        ...error.response.data,
      })
    );
  }
}

function* getRemainingMaterials_saga(params: SagaInterface) {
	try {
    const {payload} = params
		let remainingMaterials: YieldInterface = yield getRemainingMaterialDetails_api(payload);

		yield put(
			setOtherMaterialsDetails({
				success: true,
				...remainingMaterials,
			})
		);
	} catch (error: any) {
		yield put(
			setFailReasons({
				success: false,
				...error.response.data,
			})
		);
	}
}

function* setInitialPv2Verification_saga(params: SagaInterface) {
	try {
		const { payload } = params;
		let initialData: YieldInterface = yield initPv2_api(payload);

		yield put(
			setInitialPv2Verification({
				success: true,
				...initialData,
			})
		);
	} catch (error: any) {
		yield put(
			setInitialPv2Verification({
				success: false,
				...error.response.data,
			})
		);
	}
}

function* updatePv2Verification_saga(params: SagaInterface) {
	try {
		const { payload } = params;
		let response: YieldInterface = yield updatePv2Verification_api(payload);

		yield put(
			setUpdatePv2VerificationResponse({
				success: true,
				...response,
			})
		);
	} catch (error: any) {
		yield put(
			setUpdatePv2VerificationResponse({
				success: false,
				...error.response.data,
			})
		);
	}
}

function* updateMaterialDetails_saga(params: SagaInterface) {
	try {
		const { payload } = params;
		let response: YieldInterface = yield updateMaterialDetails_api(payload);

		yield put(
			setUpdateMaterialDetailsResponse({
				success: true,
				...response,
			})
		);
	} catch (error: any) {
		yield put(
			setUpdateMaterialDetailsResponse({
				success: false,
				...error.response.data,
			})
		);
	}
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      FILL_PROCESS.PV2_STATION.GET_PV2_STAION_STATUS,
      getPv2StationStatus_saga
    ),
  ]);
	yield all([
		takeLatest(FILL_PROCESS.PV2_STATION.GET_FAIL_REASONS, getFailReason_saga),
	]);
  yield all([
		takeLatest(FILL_PROCESS.PV2_STATION.GET_REMAINING_MATERIAL_DETAILS, getRemainingMaterials_saga),
	]);
  yield all([
		takeLatest(FILL_PROCESS.PV2_STATION.INITIATE_PV2_VERIFICATION, setInitialPv2Verification_saga),
	]);
  yield all([
		takeLatest(FILL_PROCESS.PV2_STATION.UPDATE_PV2_VERIFICATION, updatePv2Verification_saga),
	]);
  yield all([
		takeLatest(FILL_PROCESS.PV2_STATION.UPDATE_REMAINING_MATERIAL_DETAILS, updateMaterialDetails_saga),
	]);
}
