import api from "../api";

export const getProductList_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/product?${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const addProduct_api = async (payload: any) => {
  try {
    let response = await api.post(`/fill/product`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getProductDetail_api = async (payload: string) => {
  try {
    let response = await api.get(`/fill/product/${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteProduct_api = async (payload: string) => {
  try {
    let response = await api.delete(`/fill/product/${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteProductImage_api = async (payload: string) => {
  try {
    let response = await api.delete(`/fill/product_image/${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const postProductImage_api = async (payload: any) => {
  try {
    const config= {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let response = await api.post(`/fill/product_image`,payload, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const initiateTest_api = async (payload: any) => {
  try {
    let response = await api.post(`/fill/init-test`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getTestNames_api = async () => {
  try {
    let response = await api.get(`/fill/test-image-name-list`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getTestImageList_api = async () => {
  try {
    let response = await api.get(`/fill/test-image-list`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const retrainModel_api = async () => {
  try {
    let response = await api.get(`/fill/retrain-avsv-model`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const modelLastTrained_api = async () => {
  try {
    let response = await api.get(`/fill/last-model-training-details`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}