import GS1DigitalLinkToolkit from "../lib/GS1DigitalLinkToolkit";

export const PHONE_YCANTH = '800-315-0155';
export const PHONE_DEFAULT = '800-880-2293';
const ycanthIds = ['YCANTH', 'YCANTH NC', 'YCANTH NC (F)'];

export const isProductYcanth = (id: string) => {
  if (!id) return false;

  if (ycanthIds.includes(id.toUpperCase())) {
    return true;
  }

  return false;
}

export const getProductBarcodeData = (data: any, serialized: boolean) => {

  const gs1dlt = new GS1DigitalLinkToolkit();
  console.log("barcode data", data);

  let addGtin = null;
  let addNDC = null;
  let addLot = null;
  let addExpiration = null;
  let addSerialNumber = null;

  try {
    const parsed = gs1dlt.extractFromGS1elementStrings(data);
    console.log(JSON.stringify(parsed, null, 2));
    for (const [key, value] of Object.entries(parsed)) {
      switch(key) {
        // 01 = GTIN
        case '01':
          console.log(`GTIN = ${value}`);
          addGtin = value;
          addNDC = parseNdcFromGtin(addGtin)
          break;
        // 10 = LOT
        case '10':
          console.log(`LOT = ${value}`);
          addLot = value;
          break;
        // 17 = Expiry
        case '17':
          console.log(`EXPIRY = ${value}`);
          let year = new Date().getFullYear().toString().slice(0, 2) + value.slice(0, 2);
          let month = value.slice(2, 4);
          let day = value.slice(4, 6);
          addExpiration = year + "-" + month + "-" + day;
          break;
        // 21 = Serial Number
        case '21':
          console.log(`Serial Number = ${value}`);
          addSerialNumber = value;
          break;
        default:
          console.log(`Unkown Tag Field -> ${key} : ${value}`);
      }
    }
  } catch (e){
    console.log(`Error parsing BarCode [${data}]`, e);
  }

  // no serial number, but not serialized product, return GTIN as serial number
  if(addSerialNumber === null && !serialized) {
    addSerialNumber = addGtin;
  }

  return {
    addSerialNumber,
    addExpiration,
    addLot,
    addNDC
  };
};

// parse details taken from section 2.1.4 from https://documents.gs1us.org/adobe/assets/deliver/urn:aaid:aem:5b606108-5439-439f-a20a-a0081aeace07/Frequently-Asked-Questions-by-the-Pharmaceutical-Industry-in-Preparing-for-the-US-DSCSA.pdf?Command=Core_Download&EntryId=916&language=en-US&PortalId=0&TabId=134
const parseNdcFromGtin = (gtin: string) => {
  const start = gtin.indexOf('3') + 1
  return gtin.slice(start, -1)
}