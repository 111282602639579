import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { LogoFFF } from "../assets/images";

const SideBar = () => {
  const [selectedMainItem, setSelectedMainItem] = useState("rx_product_image");
  const [userDetails, setUserDetails] = useState<any>();

  const [fillProcessSubmenu, setFillProcessSubmenu] = useState<any>();

  const disableLinkHandler = (event: any, dest: string) => {
    const assignedStation = localStorage.getItem("workstation");
    const message = "Please select station from the dropdown above";
    if (dest === "PACK") {
      if (
        assignedStation?.includes("PACK") ||
        assignedStation?.includes("Pack") ||
        assignedStation?.includes("pack")
      ) {
      } else {
        event.preventDefault();
        toast.info(message);
      }
    } else if (dest === "FILL") {
      if (
        assignedStation?.includes("FILL") ||
        assignedStation?.includes("Fill") ||
        assignedStation?.includes("fill")
      ) {
      } else {
        event.preventDefault();
        toast.info(message);
      }
    } else if (dest === "ERROR") {
      if (
        assignedStation?.includes("ERROR") ||
        assignedStation?.includes("Error") ||
        assignedStation?.includes("error")
      ) {
      } else {
        event.preventDefault();
        toast.info(message);
      }
    } else if (dest === "PV2") {
      if (
        assignedStation?.includes("PV2") ||
        assignedStation?.includes("Pv2") ||
        assignedStation?.includes("pv2")
      ) {
      } else {
        event.preventDefault();
        toast.info(message);
      }
    }
  };

  const subMenuList = (role: string) => {
    const list = [];
    if (role === "administrator") {
      list.push(
        <li key={"1"}>
          <NavLink
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/work_group_selection"
          >
            <span>1</span>
            <p>Work Group Selection</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"2"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "FILL")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/fill_station"
          >
            <span>2</span>
            <p>Fill Station</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"3"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "PV2")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/pv2_station"
          >
            <span>3</span>
            <p>PV2 Station</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"4"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "PACK")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/packout_station"
          >
            <span>4</span>
            <p>Packout Station</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"5"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "ERROR")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/error_correction"
          >
            <span>5</span>
            <p>Error Correction</p>
          </NavLink>
        </li>
      );
    } else if (role === "supervisor") {
      list.push(
        <li key={"6"}>
          <NavLink
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/work_group_selection"
          >
            <span>1</span>
            <p>Work Group Selection</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"7"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "PV2")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/pv2_station"
          >
            <span>2</span>
            <p>PV2 Station</p>
          </NavLink>
        </li>
      );
    } else if (role === "user") {
      list.push(
        <li key={"8"}>
          <NavLink
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/work_group_selection"
          >
            <span>1</span>
            <p>Work Group Selection</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"9"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "FILL")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/fill_station"
          >
            <span>1</span>
            <p>Fill Station</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"10"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "PACK")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/packout_station"
          >
            <span>2</span>
            <p>Packout Station</p>
          </NavLink>
        </li>
      );
      list.push(
        <li key={"11"}>
          <NavLink
            onClick={(e) => disableLinkHandler(e, "ERROR")}
            className={(navData) =>
              navData.isActive ? "active-sideoption active-sidesuboption" : ""
            }
            to="/fill_process/error_correction"
          >
            <span>3</span>
            <p>Error Correction</p>
          </NavLink>
        </li>
      );
    }
    setFillProcessSubmenu(list);
  };

  useEffect(() => {
    let details: any = JSON.parse(localStorage.getItem("user") || "");
    setUserDetails(details);
    subMenuList(details?.role);
  }, []);

  useEffect(() => {
    onUrlChange();

    //check if the route is changed
    let lastUrl = window.location.href;
    new MutationObserver(() => {
      const url = window.location.href;
      if (url !== lastUrl) {
        lastUrl = url;
        onUrlChange();
      }
    }).observe(document, { subtree: true, childList: true });
  });

  const onUrlChange = () => {
    const currentRoute = window.location.pathname.split("/")[1];
    setSelectedMainItem(currentRoute);
  };

  return (
    <aside className="side-bar-wrap">
      <button type="button" className="xs-m-close-btn">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
            fill="#0D0D0D"
          />
        </svg>
      </button>
      <div className="side-menu-panel">
        <div className="logo-block">
          <img src={LogoFFF} alt="sealcon" title="sealcon" />
        </div>
        <div className="inn-side-nave">
          <div className="collaps-nav-step">
            <ul className="new-nav-list">
              <li
                className={
                  selectedMainItem === "fill_process" ? "active-link" : ""
                }
              >
                <NavLink to="/fill_process/work_group_selection">
                  <div className="nav-icon">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 4.43887L13.8883 4.32266C13.4109 3.82637 12.718 3.08125 12.1064 2.42402C11.6154 1.8959 11.165 1.41172 10.8891 1.12344L10.7709 1H2V15H14V4.43887ZM12.6692 4.19961H11V2.40938C11.1636 2.58486 11.339 2.77334 11.5207 2.96875C11.8957 3.37197 12.3004 3.80703 12.6692 4.19961ZM2.8 14.2V1.8H10.2V4.99961H13.2V14.2H2.8Z"
                        fill="#080C27"
                      />
                      <path
                        d="M4.1998 6.725C4.4207 6.725 4.5998 6.5459 4.5998 6.325V5.375H4.98257L5.6543 6.52656C5.72871 6.65391 5.8625 6.725 6.0002 6.725C6.06855 6.725 6.13789 6.70742 6.20137 6.67051C6.39219 6.55918 6.45664 6.31426 6.34531 6.12344L5.81267 5.21035C6.16335 5.00371 6.3998 4.62319 6.3998 4.1875C6.3998 3.53262 5.86719 3 5.2123 3H4.1998C3.97891 3 3.7998 3.1791 3.7998 3.4V4.975V6.325C3.7998 6.5459 3.97891 6.725 4.1998 6.725ZM5.5998 4.1875C5.5998 4.40117 5.42598 4.575 5.2123 4.575H4.5998V3.8H5.2123C5.42598 3.8 5.5998 3.97383 5.5998 4.1875Z"
                        fill="#080C27"
                      />
                      <path
                        d="M6.4668 5.62077L7.07183 6.53561L6.4668 7.45046C6.34492 7.63483 6.39551 7.88288 6.57969 8.00475C6.64766 8.04967 6.72422 8.07116 6.8 8.07116C6.92969 8.07116 7.05703 8.00807 7.13398 7.89186L7.55137 7.26071L7.96875 7.89186C8.0457 8.00807 8.17285 8.07116 8.30273 8.07116C8.37852 8.07116 8.45508 8.04967 8.52305 8.00475C8.70723 7.88288 8.75781 7.63483 8.63594 7.45046L8.03091 6.53561L8.63594 5.62077C8.75781 5.43639 8.70723 5.18835 8.52305 5.06647C8.33867 4.9446 8.09063 4.99499 7.96875 5.17936L7.55137 5.81051L7.13398 5.17936C7.01211 4.99538 6.76426 4.9446 6.57969 5.06647C6.39551 5.18835 6.34492 5.43639 6.4668 5.62077ZM11.7004 9.90006H4.30039C4.07949 9.90006 3.90039 10.0792 3.90039 10.3001C3.90039 10.521 4.07949 10.7001 4.30039 10.7001H11.7004C11.9213 10.7001 12.1004 10.521 12.1004 10.3001C12.1004 10.0792 11.9213 9.90006 11.7004 9.90006ZM11.7004 12.0294H4.30039C4.07949 12.0294 3.90039 12.2085 3.90039 12.4294C3.90039 12.6503 4.07949 12.8294 4.30039 12.8294H11.7004C11.9213 12.8294 12.1004 12.6503 12.1004 12.4294C12.1004 12.2085 11.9213 12.0294 11.7004 12.0294Z"
                        fill="#080C27"
                      />
                    </svg>
                  </div>
                  Fill Process
                </NavLink>
                <div className="sub-menu">
                  <ul
                    onClick={() => {
                      console.log("navigate");
                    }}
                  >
                    {fillProcessSubmenu}
                  </ul>
                </div>
              </li>
              {userDetails?.role === "administrator" ? (
                <li
                  className={
                    selectedMainItem === "rx_product_image" ? "active-link" : ""
                  }
                >
                  <NavLink to="/rx_product_image/config">
                    <div className="nav-icon">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 4.43887L13.8883 4.32266C13.4109 3.82637 12.718 3.08125 12.1064 2.42402C11.6154 1.8959 11.165 1.41172 10.8891 1.12344L10.7709 1H2V15H14V4.43887ZM12.6692 4.19961H11V2.40938C11.1636 2.58486 11.339 2.77334 11.5207 2.96875C11.8957 3.37197 12.3004 3.80703 12.6692 4.19961ZM2.8 14.2V1.8H10.2V4.99961H13.2V14.2H2.8Z"
                          fill="#080C27"
                        />
                        <path
                          d="M4.1998 6.725C4.4207 6.725 4.5998 6.5459 4.5998 6.325V5.375H4.98257L5.6543 6.52656C5.72871 6.65391 5.8625 6.725 6.0002 6.725C6.06855 6.725 6.13789 6.70742 6.20137 6.67051C6.39219 6.55918 6.45664 6.31426 6.34531 6.12344L5.81267 5.21035C6.16335 5.00371 6.3998 4.62319 6.3998 4.1875C6.3998 3.53262 5.86719 3 5.2123 3H4.1998C3.97891 3 3.7998 3.1791 3.7998 3.4V4.975V6.325C3.7998 6.5459 3.97891 6.725 4.1998 6.725ZM5.5998 4.1875C5.5998 4.40117 5.42598 4.575 5.2123 4.575H4.5998V3.8H5.2123C5.42598 3.8 5.5998 3.97383 5.5998 4.1875Z"
                          fill="#080C27"
                        />
                        <path
                          d="M6.4668 5.62077L7.07183 6.53561L6.4668 7.45046C6.34492 7.63483 6.39551 7.88288 6.57969 8.00475C6.64766 8.04967 6.72422 8.07116 6.8 8.07116C6.92969 8.07116 7.05703 8.00807 7.13398 7.89186L7.55137 7.26071L7.96875 7.89186C8.0457 8.00807 8.17285 8.07116 8.30273 8.07116C8.37852 8.07116 8.45508 8.04967 8.52305 8.00475C8.70723 7.88288 8.75781 7.63483 8.63594 7.45046L8.03091 6.53561L8.63594 5.62077C8.75781 5.43639 8.70723 5.18835 8.52305 5.06647C8.33867 4.9446 8.09063 4.99499 7.96875 5.17936L7.55137 5.81051L7.13398 5.17936C7.01211 4.99538 6.76426 4.9446 6.57969 5.06647C6.39551 5.18835 6.34492 5.43639 6.4668 5.62077ZM11.7004 9.90006H4.30039C4.07949 9.90006 3.90039 10.0792 3.90039 10.3001C3.90039 10.521 4.07949 10.7001 4.30039 10.7001H11.7004C11.9213 10.7001 12.1004 10.521 12.1004 10.3001C12.1004 10.0792 11.9213 9.90006 11.7004 9.90006ZM11.7004 12.0294H4.30039C4.07949 12.0294 3.90039 12.2085 3.90039 12.4294C3.90039 12.6503 4.07949 12.8294 4.30039 12.8294H11.7004C11.9213 12.8294 12.1004 12.6503 12.1004 12.4294C12.1004 12.2085 11.9213 12.0294 11.7004 12.0294Z"
                          fill="#080C27"
                        />
                      </svg>
                    </div>
                    Rx Product Image
                  </NavLink>
                  <div className="sub-menu">
                    <ul>
                      <li>
                        <NavLink
                          className={(navData) =>
                            navData.isActive
                              ? "active-sideoption active-sidesuboption"
                              : ""
                          }
                          to="/rx_product_image/config"
                        >
                          <span>1</span>
                          <p>Image and Model Config</p>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={(navData) =>
                            navData.isActive
                              ? "active-sideoption active-sidesuboption"
                              : ""
                          }
                          to="/rx_product_image/test"
                        >
                          <span>2</span>
                          <p>Initiate Test</p>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={(navData) =>
                            navData.isActive
                              ? "active-sideoption active-sidesuboption"
                              : ""
                          }
                          to="/rx_product_image/test_result"
                        >
                          <span>3</span>
                          <p>Test Results</p>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null}
              {userDetails?.role === "administrator" ? (
                <li
                  className={
                    selectedMainItem === "user_work_station_ref"
                      ? "active-link"
                      : ""
                  }
                >
                  <NavLink to="/user_work_station_ref">
                    <div className="nav-icon">
                      <svg
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 3.72544L12.879 3.59954C12.3618 3.0619 11.6111 2.25469 10.9486 1.54269C10.4167 0.970557 9.92879 0.446029 9.62982 0.133724L9.50181 0H0V15.1667H13V3.72544ZM11.5583 3.46624H9.75V1.52682C9.92729 1.71694 10.1172 1.92112 10.3141 2.13281C10.7203 2.56964 11.1588 3.04095 11.5583 3.46624ZM0.866667 14.3V0.866667H8.88333V4.33291H12.1333V14.3H0.866667Z"
                          fill="#080C27"
                        />
                        <path
                          d="M6.125 5.125H4.04167C3.7654 5.125 3.50045 5.2567 3.3051 5.49112C3.10975 5.72554 3 6.04348 3 6.375V7"
                          stroke="#080C27"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.25 5.125H6.33333C6.6096 5.125 6.87455 5.2567 7.0699 5.49112C7.26525 5.72554 7.375 6.04348 7.375 6.375V7"
                          stroke="#080C27"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 4C5.55228 4 6 3.55228 6 3C6 2.44772 5.55228 2 5 2C4.44772 2 4 2.44772 4 3C4 3.55228 4.44772 4 5 4Z"
                          stroke="#080C27"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g clipPath="url(#clip0_218_30976)">
                          <path
                            d="M4.56641 4.62077L5.17144 5.53561L4.56641 6.45046C4.44453 6.63483 4.49512 6.88288 4.6793 7.00475C4.74727 7.04967 4.82383 7.07116 4.89961 7.07116C5.0293 7.07116 5.15664 7.00807 5.23359 6.89186L5.65098 6.26071L6.06836 6.89186C6.14531 7.00807 6.27246 7.07116 6.40234 7.07116C6.47813 7.07116 6.55469 7.04967 6.62266 7.00475C6.80684 6.88288 6.85742 6.63483 6.73555 6.45046L6.13052 5.53561L6.73555 4.62077C6.85742 4.43639 6.80684 4.18835 6.62266 4.06647C6.43828 3.9446 6.19023 3.99499 6.06836 4.17936L5.65098 4.81051L5.23359 4.17936C5.11172 3.99538 4.86387 3.9446 4.6793 4.06647C4.49512 4.18835 4.44453 4.43639 4.56641 4.62077ZM9.8 8.90006H2.4C2.1791 8.90006 2 9.07917 2 9.30006C2 9.52096 2.1791 9.70006 2.4 9.70006H9.8C10.0209 9.70006 10.2 9.52096 10.2 9.30006C10.2 9.07917 10.0209 8.90006 9.8 8.90006ZM9.8 11.0294H2.4C2.1791 11.0294 2 11.2085 2 11.4294C2 11.6503 2.1791 11.8294 2.4 11.8294H9.8C10.0209 11.8294 10.2 11.6503 10.2 11.4294C10.2 11.2085 10.0209 11.0294 9.8 11.0294Z"
                            fill="#080C27"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_218_30976">
                            <rect
                              width="9"
                              height="4"
                              fill="white"
                              transform="translate(2 8)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    User Work Station Ref
                  </NavLink>
                </li>
              ) : null}
              {["administrator", "supervisor"].includes(userDetails?.role) ? (
                <li
                  className={
                    selectedMainItem === "rph_assignment" ? "active-link" : ""
                  }
                >
                  <NavLink to="/rph_assignment">
                    <div className="nav-icon">
                      <svg
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 3.72544L12.879 3.59954C12.3618 3.0619 11.6111 2.25469 10.9486 1.54269C10.4167 0.970557 9.92879 0.446029 9.62982 0.133724L9.50181 0H0V15.1667H13V3.72544ZM11.5583 3.46624H9.75V1.52682C9.92729 1.71694 10.1172 1.92112 10.3141 2.13281C10.7203 2.56964 11.1588 3.04095 11.5583 3.46624ZM0.866667 14.3V0.866667H8.88333V4.33291H12.1333V14.3H0.866667Z"
                          fill="#080C27"
                        />
                        <path
                          d="M6.125 5.125H4.04167C3.7654 5.125 3.50045 5.2567 3.3051 5.49112C3.10975 5.72554 3 6.04348 3 6.375V7"
                          stroke="#080C27"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.25 5.125H6.33333C6.6096 5.125 6.87455 5.2567 7.0699 5.49112C7.26525 5.72554 7.375 6.04348 7.375 6.375V7"
                          stroke="#080C27"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 4C5.55228 4 6 3.55228 6 3C6 2.44772 5.55228 2 5 2C4.44772 2 4 2.44772 4 3C4 3.55228 4.44772 4 5 4Z"
                          stroke="#080C27"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g clipPath="url(#clip0_218_30976)">
                          <path
                            d="M4.56641 4.62077L5.17144 5.53561L4.56641 6.45046C4.44453 6.63483 4.49512 6.88288 4.6793 7.00475C4.74727 7.04967 4.82383 7.07116 4.89961 7.07116C5.0293 7.07116 5.15664 7.00807 5.23359 6.89186L5.65098 6.26071L6.06836 6.89186C6.14531 7.00807 6.27246 7.07116 6.40234 7.07116C6.47813 7.07116 6.55469 7.04967 6.62266 7.00475C6.80684 6.88288 6.85742 6.63483 6.73555 6.45046L6.13052 5.53561L6.73555 4.62077C6.85742 4.43639 6.80684 4.18835 6.62266 4.06647C6.43828 3.9446 6.19023 3.99499 6.06836 4.17936L5.65098 4.81051L5.23359 4.17936C5.11172 3.99538 4.86387 3.9446 4.6793 4.06647C4.49512 4.18835 4.44453 4.43639 4.56641 4.62077ZM9.8 8.90006H2.4C2.1791 8.90006 2 9.07917 2 9.30006C2 9.52096 2.1791 9.70006 2.4 9.70006H9.8C10.0209 9.70006 10.2 9.52096 10.2 9.30006C10.2 9.07917 10.0209 8.90006 9.8 8.90006ZM9.8 11.0294H2.4C2.1791 11.0294 2 11.2085 2 11.4294C2 11.6503 2.1791 11.8294 2.4 11.8294H9.8C10.0209 11.8294 10.2 11.6503 10.2 11.4294C10.2 11.2085 10.0209 11.0294 9.8 11.0294Z"
                            fill="#080C27"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_218_30976">
                            <rect
                              width="9"
                              height="4"
                              fill="white"
                              transform="translate(2 8)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    RPh Assignment
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
