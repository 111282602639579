import axios from "axios";
import config from "../config";
import { refreshToken } from "./auth/firebase";

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  let tokenVar = "Authorization";
  if (!config) {
    config = {};
  }
  if (!config.headers) {
    config.headers = {};
  }
  config.headers[tokenVar] = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(
  function (response) {
    // localStorage.setItem("token", response?.data?.auth_token);
    return response;
  },
  async function (error) {
    const originalConfig = error.config;

    if (error.response?.status === 401) {
      // localStorage.clear();
      // window.location.reload();
        originalConfig._retry = true;
        try {
          const rs = await refreshToken(localStorage.getItem("refresh_token") || "");
          localStorage.setItem("token", rs.data.access_token);
          localStorage.setItem("refresh_token", rs.data.refresh_token)
          return api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
    }
    return Promise.reject(error);
  }
);

// Common api code like interceptors will come here
export default api;
