import { createSlice } from "@reduxjs/toolkit";

const initState = {};

export const fillStationSlice = createSlice({
	name: "fillStation",
	initialState: initState,
	reducers: {
		setFillStationDetailsById: (state, action) => {
			return { ...state, fillStationDetailsById: action.payload };
		},
		setFillStationData: (state, action) => {
			return { ...state, fillStationData: action.payload };
		},
		setOtherDetails: (state, action) => {
			return { ...state, otherDetails: action.payload };
		},
		setUpdateOtherDetailsFill: (state, action) => {
			return { ...state, updatedDetailsFill: action.payload };
		},
		setPrintRxLabels: (state, action) => {
			return { ...state, printRxLabelsResponse: action.payload };
		},
		setAddOtherDetailsFill: (state, action) => {
			return { ...state, addedDetailsFill: action.payload };
		},
		clearSetPrintRxLabels: (state) => {
			return { ...state, printRxLabelsResponse: undefined };
		},
		clearUpdatedDetails: (state) => {
			return { ...state, updatedDetailsFill: undefined };
		},
		clearAddedDetails: (state) => {
			return { ...state, addedDetailsFill: undefined };
		},
		clearFillStationData: (state) => {
			return { ...state, fillStationData: undefined };
		},
		setRxDetailsById: (state, action) => {
			return { ...state, rxDetailsById: action.payload };
		},
		clearRxDetailsById: (state) => {
			return { ...state, rxDetailsById: undefined };
		},
		clearFillStationDetailsById: (state) => {
			return { ...state, fillStationDetailsById: undefined };
		},
	},
});

export const {
	setFillStationDetailsById,
	setOtherDetails,
	setUpdateOtherDetailsFill,
	setPrintRxLabels,
	setAddOtherDetailsFill,
	clearSetPrintRxLabels,
	clearUpdatedDetails,
	clearAddedDetails,
	setFillStationData,
	clearFillStationData,
	setRxDetailsById,
	clearRxDetailsById,
	clearFillStationDetailsById
} = fillStationSlice.actions;

export default fillStationSlice.reducer;
