import { USER_WORK_STATION_REF } from "../constants/action";
import { clearDeleteResponse, clearUpdateResponse } from "./reducer";

const userWorkStationRefActions = {
	getUserWorkStationList: (payload: string) => ({
		type: USER_WORK_STATION_REF.GET_USER_WORK_STATION_LIST,
		payload,
	}),

  updateUserWorkstation: (payload: any) => ({
    type: USER_WORK_STATION_REF.UPDATE_USER_WORK_STATION_DETAIL,
    payload,
  }),

  deleteUserWorkstation: (payload: string) => ({
    type: USER_WORK_STATION_REF.DELETE_USER_WORK_STATION,
    payload,
  }),

  clearUpdateWorkstationResponse: () => clearUpdateResponse(),
  clearDeleteWorkstationResponse: () => clearDeleteResponse(),
};

export default userWorkStationRefActions;
